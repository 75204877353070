import { ColorValues } from "../constants/constants";

interface Props {
  lineColor?: ColorValues;
  fieldColor?: ColorValues;
  attackZone?: ColorValues;
  aroundColor?: ColorValues;
  tennisColor?: ColorValues;
  tennisNet?: boolean;
  volleyballNet?: boolean;
  goals?: boolean;
}

const FootballHandballVolleyballTennis = ({
  lineColor = ColorValues.RAL9016,
  aroundColor = ColorValues.RAL6002,
  fieldColor = ColorValues.RAL5015,
  attackZone = ColorValues.RAL1018,
  tennisColor = ColorValues.RAL6002,
  tennisNet = true,
  volleyballNet = true,
  goals = true,
}: Props) => {
  return (
    <svg
      id="football-handball-volleyball-tennis"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 395.97 185.5"
      style={{
        width: "100%", // Make the SVG responsive
        height: "auto", // Let the height adjust proportionally
      }}
    >
      <defs>
        <style>
          {`
                .cls-fhvt1{fill:#6b6b6b;}
                .cls-fhvt2{fill:#7f7f7f;}
                .cls-fhvt3{fill:${aroundColor};}
                .cls-fhvt4{fill:${fieldColor};}
                .cls-fhvt5{fill:${attackZone};}
                .cls-fhvt6{fill:${lineColor};}
                .cls-fhvt11,.cls-fhvt12,.cls-fhvt7{fill:none;}
                .cls-fhvt11,.cls-fhvt12,.cls-fhvt13,.cls-fhvt14{stroke:#fff;stroke-miterlimit:10;}
                .cls-fhvt7{stroke:${lineColor};stroke-miterlimit:10;}
                .cls-fhvt8{fill:#0f0f0f;}
                .cls-fhvt9{fill:#353535;}
                .cls-fhvt10{fill:#e2e2e2;}
                .cls-fhvt11{stroke-width:0.75px;}
                .cls-fhvt12,.cls-fhvt13,.cls-fhvt14{stroke-width:0.25px;}
                .cls-fhvt13{fill:#d86161;}
                .cls-fhvt14{fill:#170900;}
                .cls-fhvt15{fill:${tennisColor};}
                .cls-fhvt16{fill:#ffffff;}
          `}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g
            id="Fudbal_Rukomet_Odbojka_Tenis"
            data-name="Fudbal, Rukomet, Odbojka &amp; Tenis"
          >
            <g id="Sivo">
              <polygon
                className="cls-fhvt1"
                points="104.67 180.24 104.67 185.5 395.97 38.68 395.97 33.41 104.67 180.24"
              />
              <polygon
                className="cls-fhvt2"
                points="0 74.19 0 79.45 104.67 185.5 104.67 180.24 0 74.19"
              />
            </g>
            <polygon
              id="Obod"
              className="cls-fhvt3"
              points="0 74.19 104.67 180.24 395.97 33.41 266.32 0 0 74.19"
            />
            <polygon
              id="Podloga"
              className="cls-fhvt4"
              points="31.9 72.14 126.51 156.06 369.66 36.67 260.16 7.28 31.9 72.14"
            />
            <polygon
              id="Teniska_podloga"
              data-name="Teniska podloga"
              className="cls-fhvt15"
              points="109.88 61.44 174.76 105.84 322.41 43.87 242.01 20.5 109.88 61.44"
            />
            <polygon
              id="Smec"
              className="cls-fhvt5"
              points="186.4 50.31 221.77 67.25 251.41 55.09 215.34 40.2 186.4 50.31"
            />
            <g id="Linija">
              <polygon
                className="cls-fhvt6"
                points="283.3 79.41 170.89 33.05 171.45 32.09 283.86 78.45 283.3 79.41"
              />
              <path
                className="cls-fhvt6"
                d="M126.3,165.1,30.88,80,260.19,14.78l111,29.82ZM32.61,80.29l94.2,82.79L368.16,44.85l-108-29.08Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M155.78,148.56q1.68-1.47,3.18-3l1,.35c-1,1-2.09,2.05-3.22,3Zm5.11-5.07c.91-1,1.78-2.12,2.57-3.2l1.08.28c-.81,1.1-1.69,2.19-2.62,3.24Zm4.09-5.38c.71-1.1,1.36-2.22,1.94-3.35l1.13.2c-.59,1.14-1.26,2.28-2,3.4Zm3-5.62A35.14,35.14,0,0,0,169.3,129l1.16.12a33.81,33.81,0,0,1-1.32,3.5Zm1.93-5.77a33.28,33.28,0,0,0,.62-3.52l1.17,0a30.85,30.85,0,0,1-.63,3.56Zm.81-5.87a31.81,31.81,0,0,0-.07-3.52l1.18,0a30.35,30.35,0,0,1,.06,3.57Zm-.34-5.86a32.33,32.33,0,0,0-.74-3.49l1.16-.13a31.42,31.42,0,0,1,.75,3.54Zm-1.47-5.8a35.74,35.74,0,0,0-1.43-3.41l1.14-.2a36.68,36.68,0,0,1,1.44,3.46Zm-2.6-5.64a37.61,37.61,0,0,0-2.08-3.29l1.08-.28c.77,1.09,1.48,2.21,2.12,3.33Zm-3.68-5.42c-.84-1-1.75-2.1-2.71-3.11l1-.36c1,1,1.89,2.09,2.74,3.16Zm-8-8,1-.42c1.16,1,2.28,1.94,3.34,2.94L158,93C156.91,92,155.8,91.05,154.66,90.11Zm-6.21-4.5.87-.49c1.34.87,2.64,1.77,3.88,2.69l-.92.45C151.06,87.35,149.77,86.46,148.45,85.61Zm-7-4,.76-.54c1.51.76,3,1.56,4.39,2.39l-.82.51C144.34,83.12,142.88,82.33,141.4,81.58Zm-7.82-3.51.66-.59c1.65.65,3.27,1.35,4.84,2.07l-.73.56C136.81,79.4,135.21,78.71,133.58,78.07Zm-8.45-2.93.54-.63c1.76.53,3.52,1.11,5.21,1.71l-.61.61C128.6,76.23,126.87,75.67,125.13,75.14Zm-9-2.31.42-.67c1.86.41,3.71.86,5.51,1.34l-.49.64C119.82,73.67,118,73.22,116.15,72.83Zm-9.37-1.68.29-.68c1.92.27,3.85.58,5.74.94l-.36.67C110.59,71.73,108.68,71.42,106.78,71.15Zm-9.64-1,.15-.7c2,.14,3.95.32,5.89.53l-.23.7C101,70.45,99.08,70.27,97.14,70.14Zm-9.79-.34,0-.7c2,0,4,0,6,.12l-.1.7C91.28,69.84,89.31,69.8,87.35,69.8Zm-9.79.35-.13-.7c2-.14,4-.24,6-.29l0,.7C81.48,69.92,79.5,70,77.56,70.15Zm-9.67,1-.26-.69c1.93-.27,3.9-.51,5.86-.71l.18.7C71.74,70.67,69.79,70.9,67.89,71.18Zm-4.12-.08.31.68c-1.89.32-3.78.69-5.63,1.09"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M248.29,18.93a18.9,18.9,0,0,0-1.28,1.7c-.33-.13-.49-.2-.81-.35a18.5,18.5,0,0,1,1.33-1.7C247.83,18.73,248,18.8,248.29,18.93Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M246.39,21.7a6.79,6.79,0,0,0-.58,1.54c-.36-.11-.53-.18-.88-.31a7.75,7.75,0,0,1,.62-1.57C245.88,21.51,246.05,21.57,246.39,21.7Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M245.66,24.23a5,5,0,0,0,.15,1.44l-.93-.24a5.26,5.26,0,0,1-.12-1.48Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M246.14,26.6A7.24,7.24,0,0,0,247,28c-.38-.05-.57-.09-1-.16a7.54,7.54,0,0,1-.84-1.42C245.57,26.49,245.76,26.53,246.14,26.6Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M247.79,28.89a17.09,17.09,0,0,0,1.51,1.35l-1-.07a16.2,16.2,0,0,1-1.52-1.41Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M252.6,32.55h-.93c-.76-.48-1.47-1-2.11-1.44l.95,0C251.15,31.61,251.85,32.08,252.6,32.55Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M256.79,35l-.88.09c-.93-.53-1.82-1.05-2.65-1.55l.91,0C255,34,255.87,34.49,256.79,35Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M261.83,37.67l-.82.16c-1.11-.56-2.17-1.13-3.17-1.67l.87-.12C259.69,36.57,260.74,37.12,261.83,37.67Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M267.77,40.5l-.75.25c-1.3-.59-2.55-1.18-3.72-1.76l.79-.2C265.26,39.35,266.49,39.93,267.77,40.5Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M274.85,43.29l-.68.32c-1.64-.54-3.07-1.12-4.45-1.69l.71-.28C271.79,42.2,273.23,42.76,274.85,43.29Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M283.53,45.83l-.54.37c-1.87-.49-3.7-1-5.43-1.52l.63-.34C279.89,44.85,281.69,45.35,283.53,45.83Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M293.21,48.05l-.37.41c-2-.41-4.07-.86-6-1.32l.47-.38C289.21,47.21,291.21,47.64,293.21,48.05Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M303.56,49.86c-.08.19-.11.28-.18.47-2.14-.33-4.29-.69-6.39-1.07l.3-.44C299.34,49.2,301.45,49.55,303.56,49.86Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M314.17,51.18c0,.2,0,.3,0,.5-2.15-.21-4.34-.46-6.5-.74l.1-.48C309.91,50.73,312.06,51,314.17,51.18Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M323.33,51.89l.19.52c-1.81-.1-3.56-.22-5.2-.36l-.1-.52C319.83,51.67,321.56,51.8,323.33,51.89Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M332.71,52.08l.37.51c-2,0-3.95,0-5.85,0L327,52C328.83,52.08,330.77,52.1,332.71,52.08Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M342.62,51.6l.58.5c-2,.18-4.08.31-6.1.39l-.45-.51C338.62,51.9,340.64,51.77,342.62,51.6Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M352.36,50.26l.77.49c-1.92.37-3.91.68-5.9.93l-.66-.51C348.52,50.93,350.47,50.62,352.36,50.26Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M356.12,49.43l.85.48c.9-.22,1.81-.46,2.69-.72l-.9-.47C357.9,49,357,49.21,356.12,49.43Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M40.34,88c26-8.12,55.4-10.16,79.66,4,9.33,5.43,21.86,14.65,20.07,27-1,6.67-7.35,13-12.12,17.36-5.44,5-12.17,8.34-18.56,12a.59.59,0,0,0,.6,1c7-4,14.24-7.62,20-13.34,4.22-4.18,9.49-9.61,11-15.58,2.89-11.23-7.27-20.43-15.35-26.17-25-17.78-57.56-16.16-85.67-7.38-.73.23-.42,1.37.31,1.15Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt7"
                d="M270.44,18c-2.63.82-6.8,2.7-7.15,5.14-.38,2.6.28,4.37,3.92,6.39,2.64,1.47,13.28,5.52,18.06,6.83,6.4,1.76,19.36,5.49,28.29,6.37,10.45,1,18.82,1,24.64.18a83.27,83.27,0,0,0,14.22-2.84"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M128.51,90.13c2,1.38,4,2.7,6,4.07.63.44,1.23-.59.6-1-2-1.37-4-2.69-6-4.06-.63-.44-1.22.59-.6,1Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-fhvt6"
                d="M278.05,37.68a56.76,56.76,0,0,0,5.73,1.79.3.3,0,0,0,.16-.57,56.76,56.76,0,0,1-5.73-1.79c-.36-.15-.51.42-.16.57Z"
                transform="translate(0 -8.01)"
              />
              <ellipse
                className="cls-fhvt6"
                cx="217.94"
                cy="52"
                rx="2"
                ry="1.07"
              />
              <polygon
                className="cls-fhvt6"
                points="315.18 41.52 168.15 101.36 166.97 100.57 313.4 41.05 315.18 41.52"
              />
              <polygon
                className="cls-fhvt6"
                points="250.88 41.06 180.56 65.57 179.09 64.83 249.29 40.64 250.88 41.06"
              />
              <polygon
                className="cls-fhvt6"
                points="140.59 80.06 138.64 81.05 137.64 80.38 139.6 79.38 140.59 80.06"
              />
              <polygon
                className="cls-fhvt6"
                points="278.11 31.02 276.36 31.98 274.88 31.56 276.67 30.6 278.11 31.02"
              />
              <polygon
                className="cls-fhvt6"
                points="248.33 22.23 114.76 64.82 113.93 64.19 247.04 21.92 248.33 22.23"
              />
              <path
                className="cls-fhvt6"
                d="M174.63,114.63l-65.89-45.3L242,28l82.09,23.81Zm-63.84-45,64.12,43.5L320.74,52,241.87,29Z"
                transform="translate(0 -8.01)"
              />
              <polygon
                className="cls-fhvt6"
                points="211.9 82.76 153.47 52.11 154.81 51.63 213.38 82.22 211.9 82.76"
              />
              <polygon
                className="cls-fhvt6"
                points="284.51 53.47 219.96 30.99 221.08 30.59 285.83 52.93 284.51 53.47"
              />
              <path
                className="cls-fhvt6"
                d="M188.15,89.68,152.39,69.75,240.56,39l39.19,13.08Zm-33.6-19.77,33.57,18.57,88.62-36.26L240.53,39.93Z"
                transform="translate(0 -8.01)"
              />
              <polygon
                className="cls-fhvt6"
                points="221.26 67.46 185.56 50.59 186.93 50.14 222.43 66.96 221.26 67.46"
              />
              <polygon
                className="cls-fhvt6"
                points="250.96 55.34 214.69 40.44 216.05 39.99 252.14 54.84 250.96 55.34"
              />
            </g>
            {tennisNet && (
              <g id="Teniska_mreza" data-name="Teniska mreza">
                <path
                  className="cls-fhvt8"
                  d="M185.33,46.32c0,.14-.27.26-.61.26s-.6-.12-.6-.26.27-.26.6-.26S185.33,46.18,185.33,46.32Z"
                  transform="translate(0 -8.01)"
                />
                <rect
                  className="cls-fhvt8"
                  x="184.12"
                  y="28.3"
                  width="1.21"
                  height="9.99"
                />
                <path
                  className="cls-fhvt9"
                  d="M185.33,36.31c0,.15-.27.26-.61.26s-.6-.11-.6-.26.27-.26.6-.26S185.33,36.17,185.33,36.31Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M261.21,68.06h-.09L185,36.67c-.09,0-.12-.12-.07-.18a.23.23,0,0,1,.26-.05L261.3,67.82c.09,0,.12.12.07.18A.21.21,0,0,1,261.21,68.06Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M261.16,68.79a.15.15,0,0,1-.09,0L185,37.39c-.09,0-.12-.12-.07-.18a.23.23,0,0,1,.26-.05l76.07,31.39c.09,0,.12.11.06.18A.2.2,0,0,1,261.16,68.79Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M261.21,69.58h-.09L185,38.16a.11.11,0,0,1-.07-.18.27.27,0,0,1,.26,0L261.3,69.34c.09,0,.12.12.07.18A.23.23,0,0,1,261.21,69.58Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M261.21,70.3h-.09L185,38.88a.11.11,0,0,1-.07-.18.24.24,0,0,1,.26,0l76.11,31.4c.09,0,.12.12.07.18A.23.23,0,0,1,261.21,70.3Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M260.79,70.91l-.09,0L185,39.66c-.09,0-.12-.12-.07-.18a.23.23,0,0,1,.26,0l75.69,31.23c.09,0,.12.12.07.18A.22.22,0,0,1,260.79,70.91Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M260.79,73.89h-.09L185,42.65c-.09,0-.12-.12-.07-.18a.23.23,0,0,1,.26,0l75.69,31.23c.09,0,.12.12.07.18A.21.21,0,0,1,260.79,73.89Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M261,74.7l-.09,0L185,43.37c-.09,0-.12-.12-.07-.18a.23.23,0,0,1,.26,0l75.89,31.31c.09,0,.12.12.07.18A.22.22,0,0,1,261,74.7Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M261,75.47a.13.13,0,0,1-.09,0L185,44.14c-.09,0-.12-.12-.07-.18a.27.27,0,0,1,.26-.05l75.89,31.32a.11.11,0,0,1,.07.18A.21.21,0,0,1,261,75.47Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M261,76.19a.13.13,0,0,1-.09,0L185,44.86c-.09,0-.12-.12-.07-.18a.27.27,0,0,1,.26,0L261.08,76a.11.11,0,0,1,.07.18A.21.21,0,0,1,261,76.19Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M261.21,77.06l-.09,0L185,45.63a.11.11,0,0,1-.07-.18.24.24,0,0,1,.26,0l76.11,31.4c.09,0,.12.12.07.18A.22.22,0,0,1,261.21,77.06Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M261.21,71.8l-.09,0L185,40.38c-.09,0-.12-.12-.07-.18a.23.23,0,0,1,.26-.05l76.11,31.4c.09,0,.12.12.07.18A.2.2,0,0,1,261.21,71.8Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M261.21,72.57h-.09L185,41.15c-.09,0-.12-.12-.07-.18a.27.27,0,0,1,.26,0L261.3,72.33c.09,0,.12.12.07.18A.21.21,0,0,1,261.21,72.57Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M261,73.2a.13.13,0,0,1-.09,0L185,41.87c-.09,0-.12-.12-.07-.18a.27.27,0,0,1,.26,0L261.08,73a.11.11,0,0,1,.07.18A.21.21,0,0,1,261,73.2Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt8"
                  d="M261.65,77.71c0,.15-.27.26-.6.26s-.61-.11-.61-.26.27-.25.61-.25S261.65,77.57,261.65,77.71Z"
                  transform="translate(0 -8.01)"
                />
                <rect
                  className="cls-fhvt8"
                  x="260.44"
                  y="59.69"
                  width="1.21"
                  height="9.99"
                />
                <path
                  className="cls-fhvt9"
                  d="M261.65,67.71c0,.14-.27.25-.6.25s-.61-.11-.61-.25.27-.26.61-.26S261.65,67.56,261.65,67.71Z"
                  transform="translate(0 -8.01)"
                />
                <polygon
                  className="cls-fhvt16"
                  points="215.52 50.19 216.3 50.51 216.3 41.26 215.52 40.96 215.52 50.19"
                />
                <path
                  className="cls-fhvt10"
                  d="M186.47,46.16c-.1,0-.18-.06-.18-.13V37.12c0-.07.08-.13.18-.13s.19.06.19.13V46C186.66,46.1,186.58,46.16,186.47,46.16Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M188.1,46.86c-.1,0-.19-.06-.19-.13V37.82c0-.07.09-.13.19-.13s.18.06.18.13v8.91C188.28,46.8,188.2,46.86,188.1,46.86Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M189.72,47.56c-.1,0-.18-.06-.18-.13V38.52c0-.07.08-.13.18-.13s.19.06.19.13v8.91C189.91,47.5,189.83,47.56,189.72,47.56Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M191.35,48.13c-.1,0-.19-.06-.19-.13V39.27c0-.07.09-.13.19-.13s.18.06.18.13V48C191.53,48.07,191.45,48.13,191.35,48.13Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M193,48.83c-.1,0-.18-.06-.18-.13V39.79c0-.07.08-.13.18-.13s.19.06.19.13V48.7C193.16,48.77,193.08,48.83,193,48.83Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M194.6,49.47c-.1,0-.19-.06-.19-.13V40.47c0-.07.09-.13.19-.13s.18.06.18.13v8.87C194.78,49.41,194.7,49.47,194.6,49.47Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M196.22,50.17c-.1,0-.18-.06-.18-.13v-8.9c0-.07.08-.13.18-.13s.19.06.19.13V50C196.41,50.11,196.33,50.17,196.22,50.17Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M197.85,50.91c-.1,0-.19-.06-.19-.13v-8.9c0-.08.09-.13.19-.13s.19,0,.19.13v8.9C198,50.85,198,50.91,197.85,50.91Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M199.47,51.58c-.1,0-.18-.06-.18-.13V42.53c0-.07.08-.13.18-.13s.19.06.19.13v8.92C199.66,51.52,199.58,51.58,199.47,51.58Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M201.1,52.25c-.1,0-.19-.06-.19-.13V43.27c0-.07.09-.13.19-.13s.19.06.19.13v8.85C201.29,52.19,201.2,52.25,201.1,52.25Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M202.72,52.92c-.1,0-.18-.05-.18-.13V44c0-.07.08-.13.18-.13s.19.06.19.13v8.82C202.91,52.87,202.83,52.92,202.72,52.92Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M204.35,53.58c-.1,0-.19-.06-.19-.13v-9c0-.07.09-.13.19-.13s.19.06.19.13v9C204.54,53.52,204.45,53.58,204.35,53.58Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M206,54.28c-.1,0-.18-.06-.18-.13V45.24c0-.07.08-.13.18-.13s.19.06.19.13v8.91C206.16,54.22,206.08,54.28,206,54.28Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M207.6,54.92c-.1,0-.19-.06-.19-.13V45.92c0-.07.09-.13.19-.13s.19.06.19.13v8.87C207.79,54.86,207.7,54.92,207.6,54.92Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M209.22,55.52a.16.16,0,0,1-.18-.14V46.59c0-.07.08-.13.18-.13s.19.06.19.13v8.79C209.41,55.46,209.33,55.52,209.22,55.52Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M210.85,56.28c-.1,0-.19-.06-.19-.13V47.33c0-.07.09-.13.19-.13s.19.06.19.13v8.82C211,56.22,211,56.28,210.85,56.28Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M212.48,56.94c-.11,0-.19-.06-.19-.14V48c0-.07.08-.13.19-.13s.18.06.18.13V56.8A.16.16,0,0,1,212.48,56.94Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M214,57.45c-.11,0-.19-.05-.19-.13V48.5c0-.07.08-.13.19-.13s.18.06.18.13v8.82C214.16,57.4,214.08,57.45,214,57.45Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M219.33,59.66c-.1,0-.19-.06-.19-.13V50.66c0-.07.09-.13.19-.13s.18.06.18.13v8.87C219.51,59.6,219.43,59.66,219.33,59.66Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M217.77,59.1c-.1,0-.18-.06-.18-.13V50.1c0-.07.08-.13.18-.13s.19.06.19.13V59C218,59,217.88,59.1,217.77,59.1Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M221,60.36c-.1,0-.18-.06-.18-.13v-8.9c0-.07.08-.13.18-.13s.19.06.19.13v8.9C221.14,60.3,221.06,60.36,221,60.36Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M222.58,61.06c-.1,0-.19-.06-.19-.13V52c0-.07.09-.13.19-.13s.18.06.18.13v8.91C222.76,61,222.68,61.06,222.58,61.06Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M224.2,61.63c-.1,0-.18-.06-.18-.13V52.77c0-.08.08-.13.18-.13s.19,0,.19.13V61.5C224.39,61.57,224.31,61.63,224.2,61.63Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M225.83,62.33c-.1,0-.19-.06-.19-.13V53.34c0-.07.09-.13.19-.13s.19.06.19.13V62.2C226,62.27,225.93,62.33,225.83,62.33Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M227.45,63c-.1,0-.18-.06-.18-.13V54c0-.07.08-.13.18-.13s.19.06.19.13v8.83C227.64,62.91,227.56,63,227.45,63Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M229.08,63.67c-.1,0-.19-.06-.19-.13V54.68c0-.07.09-.13.19-.13s.19.06.19.13v8.86C229.27,63.61,229.18,63.67,229.08,63.67Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M230.7,64.41c-.1,0-.18-.06-.18-.13v-8.9a.16.16,0,0,1,.18-.14c.11,0,.19.06.19.14v8.9C230.89,64.35,230.81,64.41,230.7,64.41Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M232.33,65.08c-.1,0-.19-.06-.19-.13V56c0-.07.09-.13.19-.13s.19.06.19.13V65C232.52,65,232.43,65.08,232.33,65.08Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M234,65.75c-.1,0-.18-.06-.18-.13V56.77c0-.07.08-.13.18-.13s.19.06.19.13v8.85C234.14,65.69,234.06,65.75,234,65.75Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M235.58,66.42c-.1,0-.19-.06-.19-.13V57.47c0-.07.09-.13.19-.13s.19.06.19.13v8.82C235.77,66.36,235.68,66.42,235.58,66.42Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M237.2,67.08c-.1,0-.18-.06-.18-.13V58c0-.07.08-.13.18-.13s.19.06.19.13V67C237.39,67,237.31,67.08,237.2,67.08Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M238.83,67.78c-.1,0-.19-.06-.19-.13V58.74c0-.07.09-.13.19-.13s.19.06.19.13v8.91C239,67.72,238.93,67.78,238.83,67.78Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M240.46,68.42c-.11,0-.19-.06-.19-.13V59.42c0-.07.08-.13.19-.13s.18.06.18.13v8.87C240.64,68.36,240.56,68.42,240.46,68.42Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M242.08,69c-.1,0-.19-.05-.19-.13V60.09c0-.07.09-.13.19-.13s.19.06.19.13v8.79C242.27,69,242.18,69,242.08,69Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M243.71,69.78c-.11,0-.19-.06-.19-.14V60.83c0-.07.08-.13.19-.13s.18.06.18.13v8.81A.16.16,0,0,1,243.71,69.78Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M245.33,70.44c-.1,0-.19-.06-.19-.14V61.48c0-.07.09-.13.19-.13s.19.06.19.13V70.3C245.52,70.38,245.43,70.44,245.33,70.44Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M247,71.07c-.11,0-.19-.06-.19-.13V62.12c0-.08.08-.14.19-.14a.16.16,0,0,1,.18.14v8.82C247.14,71,247.06,71.07,247,71.07Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M248.58,71.81c-.1,0-.19-.06-.19-.13V62.86c0-.07.09-.13.19-.13s.19.06.19.13v8.82C248.77,71.75,248.68,71.81,248.58,71.81Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M250.21,72.47c-.11,0-.19-.06-.19-.13V63.52c0-.07.08-.13.19-.13s.18.06.18.13v8.82C250.39,72.41,250.31,72.47,250.21,72.47Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M251.83,73.1c-.1,0-.19,0-.19-.13V64.15c0-.07.09-.13.19-.13s.19.06.19.13V73C252,73.05,251.93,73.1,251.83,73.1Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M253.46,73.75c-.11,0-.19-.06-.19-.14V64.79c0-.07.08-.13.19-.13s.18.06.18.13v8.82A.16.16,0,0,1,253.46,73.75Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M255.08,74.49c-.1,0-.18-.06-.18-.13V65.54c0-.07.08-.13.18-.13s.19.06.19.13v8.82C255.27,74.43,255.18,74.49,255.08,74.49Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M256.71,75.15c-.11,0-.19-.06-.19-.13V66.19c0-.07.08-.13.19-.13s.18.06.18.13V75C256.89,75.09,256.81,75.15,256.71,75.15Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M258.33,75.78c-.1,0-.18-.06-.18-.13V66.83c0-.07.08-.13.18-.13s.19.06.19.13v8.82C258.52,75.72,258.43,75.78,258.33,75.78Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M260,76.46c-.11,0-.19,0-.19-.13V67.51c0-.07.08-.13.19-.13s.18.06.18.13v8.82C260.14,76.41,260.06,76.46,260,76.46Z"
                  transform="translate(0 -8.01)"
                />
              </g>
            )}
            {volleyballNet && (
              <g id="Odbojkaska_mreza" data-name="Odbojkaska mreza">
                <path
                  className="cls-fhvt8"
                  d="M195.71,50.77c0,.12-.23.21-.51.21s-.52-.09-.52-.21.23-.22.52-.22S195.71,50.65,195.71,50.77Z"
                  transform="translate(0 -8.01)"
                />
                <rect
                  className="cls-fhvt8"
                  x="194.68"
                  y="24.51"
                  width="1.03"
                  height="18.25"
                />
                <path
                  className="cls-fhvt9"
                  d="M195.71,32.52c0,.12-.23.22-.51.22s-.52-.1-.52-.22.23-.22.52-.22S195.71,32.4,195.71,32.52Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M248.31,54.75l-.08,0L195.43,33.2c-.07,0-.1-.1-.05-.15a.2.2,0,0,1,.21,0l52.8,21.53c.07,0,.1.1.06.15A.18.18,0,0,1,248.31,54.75Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M243.65,53.47l-.08,0-44.14-18c-.08,0-.1-.1-.06-.15a.19.19,0,0,1,.22,0l44.14,18c.07,0,.1.1.05.15A.16.16,0,0,1,243.65,53.47Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M243.65,54.12a.11.11,0,0,1-.08,0l-44.14-18c-.08,0-.1-.1-.06-.15a.19.19,0,0,1,.22,0l44.14,18c.07,0,.1.1.05.15A.16.16,0,0,1,243.65,54.12Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M243.65,54.73l-.08,0-44.14-18c-.08,0-.1-.09-.06-.15a.21.21,0,0,1,.22,0l44.14,18c.07,0,.1.1.05.15A.16.16,0,0,1,243.65,54.73Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M243.65,55.38l-.08,0-44.14-18c-.08,0-.1-.09-.06-.15a.21.21,0,0,1,.22,0l44.14,18c.07,0,.1.1.05.15A.16.16,0,0,1,243.65,55.38Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M248,59.65l-.08,0L195.43,38.22c-.07,0-.1-.09-.05-.15a.2.2,0,0,1,.21,0L248,59.44c.08,0,.1.1.06.15A.17.17,0,0,1,248,59.65Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M243.65,56h-.08L199.43,38c-.08,0-.1-.1-.06-.15a.19.19,0,0,1,.22,0l44.14,18c.07,0,.1.09.05.15A.19.19,0,0,1,243.65,56Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M243.65,56.63h-.08l-44.14-18c-.08,0-.1-.1-.06-.15a.21.21,0,0,1,.22,0l44.14,18c.07,0,.1.09.05.15A.19.19,0,0,1,243.65,56.63Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt10"
                  d="M243.65,57.24l-.08,0-44.14-18c-.08,0-.1-.1-.06-.16a.21.21,0,0,1,.22,0l44.14,18c.07,0,.1.1.05.15A.16.16,0,0,1,243.65,57.24Z"
                  transform="translate(0 -8.01)"
                />
                <path
                  className="cls-fhvt8"
                  d="M248.83,72.28c0,.12-.24.22-.52.22s-.52-.1-.52-.22.24-.22.52-.22S248.83,72.16,248.83,72.28Z"
                  transform="translate(0 -8.01)"
                />
                <rect
                  className="cls-fhvt8"
                  x="247.79"
                  y="46.1"
                  width="1.03"
                  height="18.17"
                />
                <path
                  className="cls-fhvt9"
                  d="M248.83,54.11c0,.12-.24.22-.52.22s-.52-.1-.52-.22.24-.22.52-.22S248.83,54,248.83,54.11Z"
                  transform="translate(0 -8.01)"
                />
                <polygon
                  className="cls-fhvt10"
                  points="201.11 32.35 200.79 32.27 200.79 27.25 201.11 27.33 201.11 32.35"
                />
                <polygon
                  className="cls-fhvt10"
                  points="202.63 32.99 202.31 32.91 202.31 27.88 202.63 27.96 202.63 32.99"
                />
                <polygon
                  className="cls-fhvt10"
                  points="204.07 33.57 203.75 33.49 203.75 28.46 204.07 28.55 204.07 33.57"
                />
                <polygon
                  className="cls-fhvt10"
                  points="205.59 34.2 205.28 34.12 205.28 29.1 205.59 29.18 205.59 34.2"
                />
                <polygon
                  className="cls-fhvt10"
                  points="207.13 34.83 206.82 34.74 206.82 29.72 207.13 29.8 207.13 34.83"
                />
                <polygon
                  className="cls-fhvt10"
                  points="208.65 35.46 208.34 35.38 208.34 30.35 208.65 30.44 208.65 35.46"
                />
                <polygon
                  className="cls-fhvt10"
                  points="210.09 36.04 209.78 35.96 209.78 30.93 210.09 31.02 210.09 36.04"
                />
                <polygon
                  className="cls-fhvt10"
                  points="211.62 36.67 211.3 36.59 211.3 31.57 211.62 31.65 211.62 36.67"
                />
                <polygon
                  className="cls-fhvt10"
                  points="213.13 37.31 212.82 37.23 212.82 32.2 213.13 32.28 213.13 37.31"
                />
                <polygon
                  className="cls-fhvt10"
                  points="214.58 37.89 214.26 37.81 214.26 32.78 214.58 32.86 214.58 37.89"
                />
                <polygon
                  className="cls-fhvt10"
                  points="216.1 38.52 215.78 38.44 215.78 33.42 216.1 33.5 216.1 38.52"
                />
                <polygon
                  className="cls-fhvt10"
                  points="217.64 39.15 217.32 39.06 217.32 34.04 217.64 34.12 217.64 39.15"
                />
                <polygon
                  className="cls-fhvt10"
                  points="219.16 39.78 218.84 39.7 218.84 34.67 219.16 34.76 219.16 39.78"
                />
                <polygon
                  className="cls-fhvt10"
                  points="220.6 40.36 220.28 40.28 220.28 35.25 220.6 35.34 220.6 40.36"
                />
                <polygon
                  className="cls-fhvt10"
                  points="222.12 40.99 221.81 40.91 221.81 35.89 222.12 35.97 222.12 40.99"
                />
                <polygon
                  className="cls-fhvt10"
                  points="223.56 41.57 223.25 41.49 223.25 36.47 223.56 36.55 223.56 41.57"
                />
                <polygon
                  className="cls-fhvt10"
                  points="225.08 42.21 224.77 42.13 224.77 37.1 225.08 37.18 225.08 42.21"
                />
                <polygon
                  className="cls-fhvt10"
                  points="226.62 42.83 226.31 42.75 226.31 37.72 226.62 37.8 226.62 42.83"
                />
                <polygon
                  className="cls-fhvt10"
                  points="228.14 43.46 227.83 43.38 227.83 38.36 228.14 38.44 228.14 43.46"
                />
                <polygon
                  className="cls-fhvt10"
                  points="229.59 44.04 229.27 43.96 229.27 38.94 229.59 39.02 229.59 44.04"
                />
                <polygon
                  className="cls-fhvt10"
                  points="231.11 44.68 230.79 44.6 230.79 39.57 231.11 39.65 231.11 44.68"
                />
                <polygon
                  className="cls-fhvt10"
                  points="232.63 45.24 232.31 45.16 232.31 40.14 232.63 40.22 232.63 45.24"
                />
                <polygon
                  className="cls-fhvt10"
                  points="234.17 45.87 233.85 45.78 233.85 40.76 234.17 40.84 234.17 45.87"
                />
                <polygon
                  className="cls-fhvt10"
                  points="235.84 46.5 235.53 46.42 235.53 41.39 235.84 41.48 235.84 46.5"
                />
                <polygon
                  className="cls-fhvt10"
                  points="237.37 47.23 237.05 47.15 237.05 42.13 237.37 42.21 237.37 47.23"
                />
                <polygon
                  className="cls-fhvt10"
                  points="239.04 47.86 238.73 47.78 238.73 42.75 239.04 42.84 239.04 47.86"
                />
                <polygon
                  className="cls-fhvt10"
                  points="240.64 48.5 240.33 48.42 240.33 43.4 240.64 43.48 240.64 48.5"
                />
                <polygon
                  className="cls-fhvt10"
                  points="242.16 49.14 241.85 49.05 241.85 44.03 242.16 44.11 242.16 49.14"
                />
                <rect
                  className="cls-fhvt10"
                  x="243.49"
                  y="44.72"
                  width="0.32"
                  height="5.02"
                />
                <rect
                  className="cls-fhvt10"
                  x="199.35"
                  y="26.72"
                  width="0.32"
                  height="5.02"
                />
              </g>
            )}
            {goals && (
              <g id="Golovi">
                <polygon
                  className="cls-fhvt11"
                  points="47.22 104.88 60.68 117.85 76.73 111.89 72.81 92.74 58.25 81.27 48.06 84.76 47.22 104.88"
                />
                <line
                  className="cls-fhvt12"
                  x1="71.32"
                  y1="93.28"
                  x2="56.87"
                  y2="81.75"
                />
                <line
                  className="cls-fhvt12"
                  x1="69.85"
                  y1="93.91"
                  x2="55.34"
                  y2="82.27"
                />
                <line
                  className="cls-fhvt12"
                  x1="68.34"
                  y1="94.36"
                  x2="53.96"
                  y2="82.74"
                />
                <line
                  className="cls-fhvt12"
                  x1="66.73"
                  y1="94.95"
                  x2="52.5"
                  y2="83.16"
                />
                <line
                  className="cls-fhvt12"
                  x1="65.22"
                  y1="95.5"
                  x2="51.13"
                  y2="83.71"
                />
                <line
                  className="cls-fhvt12"
                  x1="63.5"
                  y1="96.12"
                  x2="49.66"
                  y2="84.21"
                />
                <line
                  className="cls-fhvt12"
                  x1="59.61"
                  y1="82.34"
                  x2="49.38"
                  y2="85.92"
                />
                <line
                  className="cls-fhvt12"
                  x1="61.07"
                  y1="83.58"
                  x2="50.88"
                  y2="87.06"
                />
                <line
                  className="cls-fhvt12"
                  x1="62.66"
                  y1="84.75"
                  x2="52.15"
                  y2="88.35"
                />
                <line
                  className="cls-fhvt12"
                  x1="64.15"
                  y1="85.93"
                  x2="53.53"
                  y2="89.56"
                />
                <line
                  className="cls-fhvt12"
                  x1="65.68"
                  y1="87.13"
                  x2="54.91"
                  y2="90.91"
                />
                <line
                  className="cls-fhvt12"
                  x1="67.19"
                  y1="88.32"
                  x2="56.46"
                  y2="92.13"
                />
                <line
                  className="cls-fhvt12"
                  x1="68.59"
                  y1="89.42"
                  x2="57.8"
                  y2="93.31"
                />
                <line
                  className="cls-fhvt12"
                  x1="70.02"
                  y1="90.55"
                  x2="59.19"
                  y2="94.53"
                />
                <line
                  className="cls-fhvt12"
                  x1="71.51"
                  y1="91.73"
                  x2="60.62"
                  y2="95.78"
                />
                <polyline
                  className="cls-fhvt11"
                  points="48.06 84.76 61.73 96.76 72.81 92.74"
                />
                <line
                  className="cls-fhvt11"
                  x1="60.68"
                  y1="117.85"
                  x2="61.73"
                  y2="96.76"
                />
                <polyline
                  className="cls-fhvt11"
                  points="58.25 81.27 62.75 99.56 47.22 104.88"
                />
                <line
                  className="cls-fhvt13"
                  x1="47.35"
                  y1="102.74"
                  x2="60.8"
                  y2="115.71"
                />
                <line
                  className="cls-fhvt13"
                  x1="47.4"
                  y1="100.65"
                  x2="60.9"
                  y2="113.67"
                />
                <line
                  className="cls-fhvt13"
                  x1="47.55"
                  y1="98.65"
                  x2="61"
                  y2="111.45"
                />
                <line
                  className="cls-fhvt13"
                  x1="47.65"
                  y1="96.61"
                  x2="61.11"
                  y2="109.37"
                />
                <line
                  className="cls-fhvt13"
                  x1="47.66"
                  y1="94.47"
                  x2="61.11"
                  y2="106.81"
                />
                <line
                  className="cls-fhvt13"
                  x1="47.75"
                  y1="92.18"
                  x2="61.33"
                  y2="104.82"
                />
                <line
                  className="cls-fhvt13"
                  x1="47.83"
                  y1="90.25"
                  x2="61.42"
                  y2="102.39"
                />
                <line
                  className="cls-fhvt13"
                  x1="48.06"
                  y1="88.32"
                  x2="61.56"
                  y2="100.41"
                />
                <line
                  className="cls-fhvt13"
                  x1="48.06"
                  y1="86.45"
                  x2="61.73"
                  y2="98.65"
                />
                <line
                  className="cls-fhvt14"
                  x1="73.21"
                  y1="94.47"
                  x2="61.63"
                  y2="98.77"
                />
                <line
                  className="cls-fhvt14"
                  x1="73.59"
                  y1="96.39"
                  x2="61.63"
                  y2="100.83"
                />
                <line
                  className="cls-fhvt14"
                  x1="73.97"
                  y1="98.3"
                  x2="61.42"
                  y2="102.96"
                />
                <line
                  className="cls-fhvt14"
                  x1="74.36"
                  y1="100.21"
                  x2="61.42"
                  y2="105.02"
                />
                <line
                  className="cls-fhvt14"
                  x1="74.76"
                  y1="102.21"
                  x2="61.21"
                  y2="107.24"
                />
                <line
                  className="cls-fhvt14"
                  x1="75.14"
                  y1="104.13"
                  x2="61.11"
                  y2="109.34"
                />
                <line
                  className="cls-fhvt14"
                  x1="75.52"
                  y1="106.04"
                  x2="61.01"
                  y2="111.43"
                />
                <line
                  className="cls-fhvt14"
                  x1="75.91"
                  y1="107.96"
                  x2="60.9"
                  y2="113.53"
                />
                <line
                  className="cls-fhvt14"
                  x1="76.29"
                  y1="109.87"
                  x2="60.8"
                  y2="115.62"
                />
                <line
                  className="cls-fhvt12"
                  x1="48.37"
                  y1="105.99"
                  x2="49.38"
                  y2="85.92"
                />
                <line
                  className="cls-fhvt12"
                  x1="55.26"
                  y1="112.63"
                  x2="56.3"
                  y2="91.99"
                />
                <line
                  className="cls-fhvt12"
                  x1="53.88"
                  y1="111.3"
                  x2="54.91"
                  y2="90.77"
                />
                <line
                  className="cls-fhvt12"
                  x1="49.75"
                  y1="107.31"
                  x2="50.76"
                  y2="87.13"
                />
                <line
                  className="cls-fhvt12"
                  x1="51.13"
                  y1="108.64"
                  x2="52.15"
                  y2="88.35"
                />
                <line
                  className="cls-fhvt12"
                  x1="52.5"
                  y1="109.97"
                  x2="53.53"
                  y2="89.56"
                />
                <line
                  className="cls-fhvt12"
                  x1="56.64"
                  y1="113.95"
                  x2="57.69"
                  y2="93.08"
                />
                <line
                  className="cls-fhvt12"
                  x1="58.02"
                  y1="115.28"
                  x2="59.07"
                  y2="94.36"
                />
                <line
                  className="cls-fhvt12"
                  x1="59.39"
                  y1="116.61"
                  x2="60.45"
                  y2="95.63"
                />
                <line
                  className="cls-fhvt14"
                  x1="62.45"
                  y1="117.19"
                  x2="63.08"
                  y2="96.27"
                />
                <line
                  className="cls-fhvt14"
                  x1="64.29"
                  y1="116.51"
                  x2="64.44"
                  y2="95.78"
                />
                <line
                  className="cls-fhvt14"
                  x1="66.15"
                  y1="115.82"
                  x2="65.77"
                  y2="95.3"
                />
                <line
                  className="cls-fhvt14"
                  x1="67.95"
                  y1="115.15"
                  x2="66.98"
                  y2="94.84"
                />
                <line
                  className="cls-fhvt14"
                  x1="69.72"
                  y1="114.49"
                  x2="68.2"
                  y2="94.44"
                />
                <line
                  className="cls-fhvt14"
                  x1="71.42"
                  y1="113.86"
                  x2="69.3"
                  y2="94.02"
                />
                <line
                  className="cls-fhvt14"
                  x1="73.36"
                  y1="113.14"
                  x2="70.43"
                  y2="93.61"
                />
                <line
                  className="cls-fhvt14"
                  x1="75.14"
                  y1="112.48"
                  x2="71.58"
                  y2="93.19"
                />
                <polygon
                  className="cls-fhvt11"
                  points="299.51 17.41 301.39 5.17 305.04 4.29 322.1 8.29 321.69 20.23 316.33 22.05 316.88 18.63 304.98 15.7 299.51 17.41"
                />
                <line
                  className="cls-fhvt12"
                  x1="316.93"
                  y1="17.16"
                  x2="305.04"
                  y2="14.24"
                />
                <line
                  className="cls-fhvt12"
                  x1="317.43"
                  y1="15.84"
                  x2="305.04"
                  y2="12.79"
                />
                <line
                  className="cls-fhvt12"
                  x1="317.67"
                  y1="14.43"
                  x2="305.04"
                  y2="11.32"
                />
                <line
                  className="cls-fhvt12"
                  x1="317.85"
                  y1="13.01"
                  x2="305.04"
                  y2="9.86"
                />
                <line
                  className="cls-fhvt12"
                  x1="318.17"
                  y1="11.62"
                  x2="305.04"
                  y2="8.39"
                />
                <line
                  className="cls-fhvt12"
                  x1="318.29"
                  y1="10.34"
                  x2="305.11"
                  y2="7.09"
                />
                <polyline
                  className="cls-fhvt11"
                  points="316.33 22.05 318.57 9.35 322.1 8.29"
                />
                <line
                  className="cls-fhvt11"
                  x1="301.39"
                  y1="5.17"
                  x2="318.57"
                  y2="9.35"
                />
                <line
                  className="cls-fhvt11"
                  x1="304.98"
                  y1="15.7"
                  x2="305.04"
                  y2="4.29"
                />
                <line
                  className="cls-fhvt12"
                  x1="299.75"
                  y1="15.84"
                  x2="304.98"
                  y2="14.3"
                />
                <line
                  className="cls-fhvt12"
                  x1="300"
                  y1="14.25"
                  x2="304.98"
                  y2="12.78"
                />
                <line
                  className="cls-fhvt12"
                  x1="300.24"
                  y1="12.68"
                  x2="305.04"
                  y2="11.27"
                />
                <line
                  className="cls-fhvt12"
                  x1="300.48"
                  y1="11.1"
                  x2="304.98"
                  y2="9.78"
                />
                <line
                  className="cls-fhvt12"
                  x1="300.72"
                  y1="9.56"
                  x2="304.98"
                  y2="8.31"
                />
                <line
                  className="cls-fhvt12"
                  x1="300.95"
                  y1="8.06"
                  x2="304.87"
                  y2="6.91"
                />
                <line
                  className="cls-fhvt12"
                  x1="301.17"
                  y1="6.65"
                  x2="304.87"
                  y2="5.56"
                />
                <line
                  className="cls-fhvt12"
                  x1="316.66"
                  y1="20.49"
                  x2="321.89"
                  y2="18.73"
                />
                <line
                  className="cls-fhvt12"
                  x1="316.91"
                  y1="18.78"
                  x2="321.89"
                  y2="17.21"
                />
                <line
                  className="cls-fhvt12"
                  x1="317.19"
                  y1="17.21"
                  x2="321.95"
                  y2="15.7"
                />
                <line
                  className="cls-fhvt12"
                  x1="317.46"
                  y1="15.63"
                  x2="321.89"
                  y2="14.2"
                />
                <line
                  className="cls-fhvt12"
                  x1="317.75"
                  y1="13.99"
                  x2="321.95"
                  y2="12.72"
                />
                <line
                  className="cls-fhvt12"
                  x1="318.03"
                  y1="12.44"
                  x2="322"
                  y2="11.27"
                />
                <line
                  className="cls-fhvt12"
                  x1="318.29"
                  y1="10.92"
                  x2="322.05"
                  y2="9.81"
                />
                <line
                  className="cls-fhvt12"
                  x1="303.19"
                  y1="4.73"
                  x2="320.38"
                  y2="8.82"
                />
                <line
                  className="cls-fhvt12"
                  x1="321.21"
                  y1="8.55"
                  x2="320.5"
                  y2="20.49"
                />
                <line
                  className="cls-fhvt12"
                  x1="320.27"
                  y1="8.94"
                  x2="319.28"
                  y2="21.01"
                />
                <line
                  className="cls-fhvt12"
                  x1="317.85"
                  y1="21.44"
                  x2="319.4"
                  y2="9.28"
                />
                <line
                  className="cls-fhvt12"
                  x1="304.18"
                  y1="4.5"
                  x2="303.72"
                  y2="16.09"
                />
                <line
                  className="cls-fhvt12"
                  x1="303.19"
                  y1="4.73"
                  x2="302.39"
                  y2="16.61"
                />
                <line
                  className="cls-fhvt12"
                  x1="300.96"
                  y1="17.05"
                  x2="302.37"
                  y2="5.02"
                />
                <line
                  className="cls-fhvt12"
                  x1="306.25"
                  y1="16.01"
                  x2="306.42"
                  y2="6.39"
                />
                <line
                  className="cls-fhvt12"
                  x1="307.56"
                  y1="16.45"
                  x2="307.77"
                  y2="6.78"
                />
                <line
                  className="cls-fhvt12"
                  x1="308.89"
                  y1="16.8"
                  x2="309.12"
                  y2="7.09"
                />
                <line
                  className="cls-fhvt12"
                  x1="310.21"
                  y1="17.27"
                  x2="310.5"
                  y2="7.38"
                />
                <line
                  className="cls-fhvt12"
                  x1="311.6"
                  y1="17.33"
                  x2="311.96"
                  y2="7.74"
                />
                <line
                  className="cls-fhvt12"
                  x1="313.08"
                  y1="17.69"
                  x2="313.45"
                  y2="8.1"
                />
                <line
                  className="cls-fhvt12"
                  x1="314.4"
                  y1="18.02"
                  x2="314.92"
                  y2="8.46"
                />
                <line
                  className="cls-fhvt12"
                  x1="315.86"
                  y1="18.38"
                  x2="316.48"
                  y2="8.84"
                />
                <line
                  className="cls-fhvt12"
                  x1="316.85"
                  y1="18.6"
                  x2="317.85"
                  y2="9.17"
                />
                <line
                  className="cls-fhvt12"
                  x1="306.33"
                  y1="4.59"
                  x2="303.13"
                  y2="5.59"
                />
                <line
                  className="cls-fhvt12"
                  x1="308.13"
                  y1="5.01"
                  x2="304.98"
                  y2="6.12"
                />
                <line
                  className="cls-fhvt12"
                  x1="309.98"
                  y1="5.45"
                  x2="307.17"
                  y2="6.57"
                />
                <line
                  className="cls-fhvt12"
                  x1="311.78"
                  y1="5.87"
                  x2="309.19"
                  y2="7.06"
                />
                <line
                  className="cls-fhvt12"
                  x1="313.45"
                  y1="6.26"
                  x2="311.07"
                  y2="7.52"
                />
                <line
                  className="cls-fhvt12"
                  x1="315.07"
                  y1="6.64"
                  x2="312.75"
                  y2="7.93"
                />
                <line
                  className="cls-fhvt12"
                  x1="316.63"
                  y1="7"
                  x2="314.53"
                  y2="8.36"
                />
                <line
                  className="cls-fhvt12"
                  x1="318.46"
                  y1="7.48"
                  x2="316.08"
                  y2="8.72"
                />
                <line
                  className="cls-fhvt12"
                  x1="320.5"
                  y1="7.91"
                  x2="317.35"
                  y2="9.07"
                />
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FootballHandballVolleyballTennis;
