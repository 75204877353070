import { CourtType, ColorValues, ModelCourt } from "../constants/constants";
import { SelectedCourtValues } from "../types/types";

export const defaultValues: SelectedCourtValues[] = [
  {
    name: CourtType.BasketballWhole,
    width: "",
    depth: "",
    outside: true,
    colors: {
      aroundColor: ColorValues.RAL5015,
      fieldColor: ColorValues.RAL3020,
      keyColor: ColorValues.RAL5015,
      lineColor: ColorValues.RAL9016,
    },
    extras: {
      baskets: true,
    },
    model: ModelCourt.boma,
  },
  {
    name: CourtType.BasketballHalf,
    width: "",
    depth: "",
    outside: true,
    colors: {
      aroundColor: ColorValues.RAL9005,
      fieldColor: ColorValues.RAL7040,
      keyColor: ColorValues.RAL7016,
      lineColor: ColorValues.RAL9016,
    },
    extras: {
      baskets: true,
    },
    model: ModelCourt.boma,
  },
  {
    name: CourtType.Volleyball,
    width: "",
    depth: "",
    outside: true,
    colors: {
      aroundColor: ColorValues.RAL5015,
      fieldColor: ColorValues.RAL2008,
      attackZone: ColorValues.RAL7016,
      lineColor: ColorValues.RAL9016,
    },
    extras: {
      volleyballNet: true,
    },
    model: ModelCourt.boma,
  },
  {
    name: CourtType.Tennis,
    width: "",
    depth: "",
    outside: true,
    colors: {
      aroundColor: ColorValues.RAL6005,
      fieldColor: ColorValues.RAL6002,
      lineColor: ColorValues.RAL9016,
    },
    extras: {
      tennisNet: true,
    },
    model: ModelCourt.boma,
  },
  {
    name: CourtType.FootballAndHandball,
    width: "",
    depth: "",
    outside: true,
    colors: {
      aroundColor: ColorValues.RAL3020,
      fieldColor: ColorValues.RAL5015,
      lineColor: ColorValues.RAL9016,
    },
    extras: {
      goals: true,
    },
    model: ModelCourt.boma,
  },
  {
    name: CourtType.BasketballAndVolleyball,
    width: "",
    depth: "",
    outside: true,
    colors: {
      aroundColor: ColorValues.RAL2008,
      fieldColor: ColorValues.RAL5015,
      attackZone: ColorValues.RAL2008,
      keyColor: ColorValues.RAL2008,
      lineColor: ColorValues.RAL9016,
    },
    extras: {
      baskets: true,
      volleyballNet: true,
    },
    model: ModelCourt.boma,
  },
  {
    name: CourtType.FootballBasketballVolleyball,
    width: "",
    depth: "",
    outside: true,
    colors: {
      aroundColor: ColorValues.RAL1018,
      fieldColor: ColorValues.RAL5015,
      attackZone: ColorValues.RAL2008,
      keyColor: ColorValues.RAL1018,
      lineColor: ColorValues.RAL9016,
    },
    extras: {
      baskets: true,
      volleyballNet: true,
      goals: true,
    },
    model: ModelCourt.boma,
  },
  {
    name: CourtType.BasketballAndTennis,
    width: "",
    depth: "",
    outside: true,
    colors: {
      aroundColor: ColorValues.RAL6002,
      fieldColor: ColorValues.RAL1018,
      tennisColor: ColorValues.RAL6018,
      keyColor: ColorValues.RAL6002,
      lineColor: ColorValues.RAL9016,
    },
    extras: {
      baskets: true,
      tennisNet: true,
    },
    model: ModelCourt.boma,
  },
  {
    name: CourtType.FootballHandballVolleyballTennis,
    width: "",
    depth: "",
    outside: true,
    colors: {
      aroundColor: ColorValues.RAL6002,
      fieldColor: ColorValues.RAL5015,
      attackZone: ColorValues.RAL1018,
      tennisColor: ColorValues.RAL6002,
      lineColor: ColorValues.RAL9016,
    },
    extras: {
      tennisNet: true,
      volleyballNet: true,
      goals: true,
    },
    model: ModelCourt.boma,
  },
];
