import { ColorValues } from "../constants/constants";

interface Props {
  lineColor?: ColorValues;
  fieldColor?: ColorValues;
  keyColor?: ColorValues;
  aroundColor?: ColorValues;
  baskets?: boolean;
}

const BasketballWhole = ({
  lineColor = ColorValues.RAL9016,
  aroundColor = ColorValues.RAL5015,
  fieldColor = ColorValues.RAL3020,
  keyColor = ColorValues.RAL5015,
  baskets = true,
}: Props) => {
  return (
    <svg
      id="basketballWhole"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 360.15 175.53"
      style={{
        width: "100%", // Make the SVG responsive
        height: "auto", // Let the height adjust proportionally
      }}
    >
      <defs>
        <style>
          {`
              .cls-whole-1{fill:#6b6b6b;}
              .cls-whole-2{fill:#7f7f7f;}
              .cls-whole-3{ fill: ${aroundColor};}
              .cls-whole-4{fill:${fieldColor};}
              .cls-whole-5 { fill: ${lineColor}; }
              .cls-whole-6{fill:#0f0f0f;}
              .cls-whole-7{fill:#8e8e8e;}
              .cls-whole-8{fill:#b2b2b2;}
              .cls-whole-9{fill:#dbdbdb;}
              .cls-whole-10{fill:#e2e2e2;}
              .cls-whole-11{fill:#d6403c;}
              .cls-whole-12 { fill: ${keyColor}; }
              .cls-whole-13{fill:#ffffff;}
          `}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Kosarka_ceo_teren_" data-name="Kosarka (ceo teren)">
            <g id="Sivo">
              <polygon
                className="cls-whole-1"
                points="21.32 169.28 21.32 175.53 360.15 75.41 360.15 69.15 21.32 169.28"
              />
              <polygon
                className="cls-whole-2"
                points="0 57.45 0 63.71 21.32 175.53 21.32 169.28 0 57.45"
              />
            </g>
            <polygon
              id="Obod"
              className="cls-whole-3"
              points="0 57.45 21.32 169.28 360.15 69.15 240 16.92 0 57.45"
            />
            <polygon
              id="Podloga"
              className="cls-whole-4"
              points="22.38 60.3 49.01 143.85 330.45 66.25 235.88 22.74 22.38 60.3"
            />
            <g id="Reket">
              <polygon
                className="cls-whole-12"
                points="36.24 103.23 30.07 84 96.95 70.58 109.63 86.79 36.24 103.23"
              />
              <polygon
                className="cls-whole-12"
                points="243.44 56.51 223.15 44.95 265.35 36.2 287.71 46.59 243.44 56.51"
              />
            </g>
            <g id="Linija">
              <path
                className="cls-whole-5"
                d="M48.64,144.68,22,60.13,236.08,22.28l95.66,44.08ZM23.07,60.51,49.56,143,329.35,66.24l-93.43-43Z"
              />
              <path
                className="cls-whole-5"
                d="M174.17,71.48c-10.47.27-19.08-3.07-19.2-7.43s8.32-8.14,18.79-8.41S192.84,58.7,193,63.07,184.64,71.21,174.17,71.48Zm-.39-15C164,56.7,156.06,60.1,156.16,64s8.17,6.9,18,6.65S191.86,67,191.76,63.1,183.59,56.19,173.78,56.45Z"
              />
              <polygon
                className="cls-whole-5"
                points="216.22 97.74 142.62 39.3 143.92 38.96 217.35 97.27 216.22 97.74"
              />
              <path
                className="cls-whole-5"
                d="M110.08,87.1l-.76-.71c9.16-2,16.48-8.31,12.83-12.13s-15-6-24.78-3.29l-.5-.75C108.26,67,119.24,69.7,123,73.9S120.61,84.87,110.08,87.1Z"
              />
              <polygon
                className="cls-whole-5"
                points="110.44 87.03 109.46 87.23 96.65 70.97 97.23 70.15 110.44 87.03"
              />
              <polygon
                className="cls-whole-5"
                points="97.81 70.88 30.59 84.4 30.22 83.62 97.23 70.15 97.81 70.88"
              />
              <polygon
                className="cls-whole-5"
                points="110.44 87.01 36.68 103.6 36 102.88 109.76 86.3 110.44 87.01"
              />
              <path
                className="cls-whole-5"
                d="M46.39,135.13,46,133.84c33.94-6.29,101.07-33.82,75.12-60.72-18-18.71-73.15-14-96.84-8.22l-.52-.75c24.23-5.89,80.49-10.7,98.33,8.73C146.38,99.35,91.53,125.18,46.39,135.13Z"
              />
              <path
                className="cls-whole-5"
                d="M222.79,44.72l.76.52c-6.55,1.53-10.34,4.84-6.36,7.62s18.39,5.65,25.64,3.47l.61.55c-8.3,2.77-22.63-.69-26.8-3.75S215.08,46.28,222.79,44.72Z"
              />
              <polygon
                className="cls-whole-5"
                points="222.39 44.96 223.1 44.8 243.93 56.55 243.23 56.88 222.39 44.96"
              />
              <polygon
                className="cls-whole-5"
                points="242.54 56.41 286.67 46.37 287.19 46.95 243.22 56.95 242.54 56.41"
              />
              <polygon
                className="cls-whole-5"
                points="222.53 44.77 264.62 36.14 265.37 36.66 223.28 45.3 222.53 44.77"
              />
              <path
                className="cls-whole-5"
                d="M240.57,25.23l.85.38c-12.55,1.83-50.72,9.65-21.93,28.66C239.91,67.75,297,71.39,320,61.61l.81.51c-22.06,10.24-81,6.75-102.14-7.41C190.49,35.88,226,27.13,240.57,25.23Z"
              />
            </g>
            {baskets && (
              <g id="Kosevi">
                <path
                  className="cls-whole-6"
                  d="M277.18,38.31h1.33V9.23s-5.59-2.4-6.57-2.74V8c1.5.58,3.42,1.46,5.24,2.25Z"
                />
                <path
                  className="cls-whole-6"
                  d="M278.51,38.38c0,.22-.3.4-.66.4s-.67-.18-.67-.4.3-.4.67-.4S278.51,38.16,278.51,38.38Z"
                />
                <polygon
                  className="cls-whole-7"
                  points="267.26 10.65 277.63 16.63 277.63 6.04 267.26 0.8 267.26 10.65"
                />
                <polygon
                  className="cls-whole-8"
                  points="266.62 10.95 276.99 16.93 276.99 6.34 266.62 0.37 266.62 10.95"
                />
                <polygon
                  className="cls-whole-7"
                  points="276.99 16.93 277.63 16.63 277.63 5.97 276.99 6.34 276.99 16.93"
                />
                <polygon
                  className="cls-whole-9"
                  points="266.62 0.37 267.26 0 277.63 5.97 276.99 6.34 266.62 0.37"
                />
                <path
                  className="cls-whole-13"
                  d="M274.5,13l-5.39-3.1V4.33l5.39,3.11Zm-5.19-3.22,5,2.87v-5l-5-2.87Z"
                />
                <path
                  className="cls-whole-13"
                  d="M276.53,15.89l-9.37-5.4V1.41l9.37,5.4Zm-9.17-5.52,9,5.16V6.93l-9-5.16Z"
                />
                <rect
                  className="cls-whole-10"
                  x="269.28"
                  y="10.47"
                  width="0.34"
                  height="3.66"
                />
                <rect
                  className="cls-whole-10"
                  x="267.66"
                  y="10.8"
                  width="0.34"
                  height="3.71"
                  transform="translate(0.98 38.87) rotate(-8.32)"
                />
                <rect
                  className="cls-whole-10"
                  x="269.21"
                  y="12.61"
                  width="3.72"
                  height="0.34"
                  transform="translate(219.22 279.15) rotate(-81.68)"
                />
                <path
                  className="cls-whole-10"
                  d="M269.45,17.54c-1.48,0-2.63-.75-2.63-1.7s1.15-1.71,2.63-1.71,2.63.75,2.63,1.71S270.92,17.54,269.45,17.54Zm0-3.07c-1.29,0-2.29.6-2.29,1.37s1,1.36,2.29,1.36,2.29-.6,2.29-1.36S270.73,14.47,269.45,14.47Z"
                />
                <path
                  className="cls-whole-10"
                  d="M269.44,16.38c-1.61,0-2.87-.81-2.87-1.86s1.26-1.86,2.87-1.86,2.87.82,2.87,1.86S271.05,16.38,269.44,16.38Zm0-3.34c-1.4,0-2.49.65-2.49,1.48S268,16,269.44,16s2.5-.65,2.5-1.49S270.84,13,269.44,13Z"
                />
                <path
                  className="cls-whole-10"
                  d="M269.44,15.27c-1.7,0-3-.86-3-2s1.34-2,3-2,3,.86,3,2S271.15,15.27,269.44,15.27Zm0-3.54c-1.48,0-2.64.69-2.64,1.57s1.16,1.57,2.64,1.57,2.64-.69,2.64-1.57S270.92,11.73,269.44,11.73Z"
                />
                <rect
                  className="cls-whole-10"
                  x="266.51"
                  y="12.51"
                  width="0.34"
                  height="3.35"
                  transform="translate(1.88 48.86) rotate(-10.51)"
                />
                <path
                  className="cls-whole-10"
                  d="M272.08,15.87l-.34-.06c.25-1.33.62-3.34.63-3.47h.35C272.72,12.44,272.37,14.32,272.08,15.87Z"
                />
                <polygon
                  className="cls-whole-10"
                  points="267.95 17.1 267.72 13.98 268.06 13.96 268.29 17.08 267.95 17.1"
                />
                <rect
                  className="cls-whole-10"
                  x="269.39"
                  y="15.27"
                  width="3.13"
                  height="0.34"
                  transform="translate(235.92 284.56) rotate(-85.84)"
                />
                <rect
                  className="cls-whole-10"
                  x="269.28"
                  y="14.3"
                  width="0.34"
                  height="3.07"
                />
                <path
                  className="cls-whole-11"
                  d="M269.45,14.41c-1.85,0-3.3-.9-3.3-2.06s1.45-2.06,3.3-2.06,3.3.91,3.3,2.06S271.3,14.41,269.45,14.41Zm0-3.71c-1.57,0-2.9.75-2.9,1.65s1.33,1.66,2.9,1.66,2.9-.76,2.9-1.66S271,10.7,269.45,10.7Z"
                />
                <rect
                  className="cls-whole-10"
                  x="33.98"
                  y="47.39"
                  width="0.45"
                  height="4.76"
                />
                <rect
                  className="cls-whole-10"
                  x="31.88"
                  y="47.83"
                  width="0.45"
                  height="4.83"
                  transform="translate(-6.93 5.17) rotate(-8.32)"
                />
                <rect
                  className="cls-whole-10"
                  x="33.9"
                  y="50.17"
                  width="4.83"
                  height="0.45"
                  transform="translate(-18.81 79.04) rotate(-81.68)"
                />
                <path
                  className="cls-whole-10"
                  d="M34.2,56.59c-1.91,0-3.42-1-3.42-2.22s1.51-2.22,3.42-2.22,3.42,1,3.42,2.22S36.12,56.59,34.2,56.59Zm0-4c-1.66,0-3,.77-3,1.77s1.31,1.77,3,1.77,3-.78,3-1.77S35.87,52.6,34.2,52.6Z"
                />
                <path
                  className="cls-whole-10"
                  d="M34.19,55.08c-2.09,0-3.72-1.06-3.72-2.42s1.63-2.42,3.72-2.42,3.73,1.06,3.73,2.42S36.28,55.08,34.19,55.08Zm0-4.35c-1.81,0-3.24.85-3.24,1.93s1.43,1.93,3.24,1.93,3.24-.85,3.24-1.93S36,50.73,34.19,50.73Z"
                />
                <path
                  className="cls-whole-10"
                  d="M34.19,53.63c-2.21,0-3.94-1.12-3.94-2.56s1.73-2.56,3.94-2.56,4,1.13,4,2.56S36.41,53.63,34.19,53.63Zm0-4.6c-1.92,0-3.43.89-3.43,2s1.51,2,3.43,2,3.44-.9,3.44-2S36.12,49,34.19,49Z"
                />
                <rect
                  className="cls-whole-10"
                  x="30.39"
                  y="50.05"
                  width="0.45"
                  height="4.35"
                  transform="translate(-9.01 6.46) rotate(-10.51)"
                />
                <path
                  className="cls-whole-10"
                  d="M37.62,54.41l-.44-.08c.32-1.72.81-4.33.82-4.5h.45C38.45,50,38,52.39,37.62,54.41Z"
                />
                <polygon
                  className="cls-whole-10"
                  points="32.25 56.01 31.96 51.95 32.41 51.92 32.7 55.98 32.25 56.01"
                />
                <rect
                  className="cls-whole-10"
                  x="34.13"
                  y="53.63"
                  width="4.07"
                  height="0.45"
                  transform="translate(-20.17 86.01) rotate(-85.84)"
                />
                <rect
                  className="cls-whole-10"
                  x="33.98"
                  y="52.37"
                  width="0.45"
                  height="3.99"
                />
                <path
                  className="cls-whole-11"
                  d="M34.2,52.51c-2.4,0-4.28-1.17-4.28-2.67s1.88-2.67,4.28-2.67,4.29,1.17,4.29,2.67S36.61,52.51,34.2,52.51Zm0-4.82c-2,0-3.76,1-3.76,2.15S32.16,52,34.2,52,38,51,38,49.84,36.24,47.69,34.2,47.69Z"
                />
                <polygon
                  className="cls-whole-7"
                  points="25.89 49.9 32.56 61.97 32.56 48.22 25.89 37.1 25.89 49.9"
                />
                <polygon
                  className="cls-whole-8"
                  points="25.89 50.85 31.73 62.36 31.73 48.61 25.89 37.1 25.89 50.85"
                />
                <polygon
                  className="cls-whole-7"
                  points="31.73 62.36 32.56 61.97 32.56 48.13 31.73 48.61 31.73 62.36"
                />
                <polygon
                  className="cls-whole-9"
                  points="25.89 37.1 26.72 36.62 32.56 48.13 31.73 48.61 25.89 37.1"
                />
                <polygon
                  className="cls-whole-6"
                  points="27.96 48.81 23.01 54.17 23.01 96.03 24.78 96.03 24.78 54.98 29.09 50.46 27.96 48.81"
                />
                <path
                  className="cls-whole-6"
                  d="M27.85,49.21a1.65,1.65,0,0,0,.69,1.31c.38.22.69,0,.69-.52a1.65,1.65,0,0,0-.69-1.31C28.16,48.47,27.85,48.7,27.85,49.21Z"
                />
                <path
                  className="cls-whole-6"
                  d="M24.74,96c0,.29-.38.52-.86.52S23,96.32,23,96s.39-.52.87-.52S24.74,95.74,24.74,96Z"
                />
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BasketballWhole;
