import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Logo from "../images/logo.svg";
import { changeLanguage } from "../locales/config";

interface Props {
  buttonText: string;
  title: string;
  handleBtnClick: () => void;
}

const Header = ({ buttonText, title, handleBtnClick }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      height="186px"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      marginBottom={2}
    >
      <Grid item xs={3} sm={4} lg={3}>
        <Button
          color="primary"
          disabled={false}
          variant="contained"
          size="large"
          startIcon={<ArrowBackIosNewIcon />}
          onClick={handleBtnClick}
          sx={{
            borderRadius: "50px",
          }}
        >
          {isMobile ? "" : buttonText}
        </Button>
      </Grid>
      <Grid item xs={4} sm={4} lg={6}>
        <Stack direction="column" alignItems="center" spacing={2}>
          <img
            src={Logo}
            alt="Logo BomaCourt"
            width={isMobile ? "100px" : "155px"}
          />
        </Stack>
      </Grid>
      <Grid item xs={3} sm={4} lg={3}>
        <Stack
          direction="row"
          justifyContent="center"
          spacing={{ xs: 0, sm: 2 }}
        >
          <Button
            variant="text"
            style={{
              color: theme.palette.text.primary,
            }}
            onClick={() => changeLanguage("sr")}
            size={isMobile ? "small" : "medium"}
          >
            <Typography variant="h6">SRB</Typography>
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button
            variant="text"
            style={{
              color: theme.palette.text.primary,
            }}
            onClick={() => changeLanguage("en")}
            size={isMobile ? "small" : "medium"}
          >
            <Typography variant="h6">EN</Typography>
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12} lg={12} sm={12}>
        <Typography
          variant={isMobile ? "h4" : "h3"}
          fontWeight="700"
          textAlign="center"
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Header;
