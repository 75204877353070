import i18n from "../locales/config";
import { SelectedCourtValues } from "../types/types";
import { formatColor } from "./formatColor";

const makeMessage = (selectedCourtStates: SelectedCourtValues) => {
  const { width, depth, outside, colors, extras } = selectedCourtStates;

  const formatSection = (
    label: string,
    values: Record<string, string | boolean>
  ) => {
    return `${Object.keys(values)
      .map((key) => {
        const value = values[key];
        const translatedValue =
          label === "color" && typeof value === "string"
            ? formatColor(value)
            : value === true
            ? i18n.t("yes")
            : value === false
            ? i18n.t("no")
            : value;
        return `${i18n.t(`${label}.${key}`)}: ${translatedValue}`;
      })
      .join("\n")}
    `;
  };

  const message = `${i18n.t("messageText")}
  
${i18n.t("courtInformation")}:
${i18n.t("courtName")}: ${i18n.t(selectedCourtStates.name)}
${i18n.t("depth")}: ${depth}m
${i18n.t("width")}: ${width}m
${i18n.t("environment.environment")}: ${
    outside ? i18n.t("environment.outside") : i18n.t("environment.inside")
  }
${i18n.t("model.model")}: ${i18n.t(selectedCourtStates.model)}

${i18n.t("color.colors")}:
${formatSection("color", colors)}
${i18n.t("extras.extras")}:
${formatSection("extras", extras)}
`;

  return message;
};

export default makeMessage;
