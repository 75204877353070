import { Container } from "@mui/material";
import RouterApp from "./router/RouterApp";

function App() {
  return (
    <Container maxWidth="xl">
      <RouterApp />
    </Container>
  );
}

export default App;
