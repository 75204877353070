export const companyEmail = process.env.REACT_APP_COMPANY_EMAIL;
export const backendUrl = process.env.REACT_APP_BACKEND_URL;

export enum CourtType {
  BasketballWhole = "basketballWhole",
  BasketballHalf = "basketballHalf",
  Volleyball = "volleyball",
  Tennis = "tennis",
  FootballAndHandball = "footballAndHandball",
  BasketballAndVolleyball = "basketballAndVolleyball",
  FootballBasketballVolleyball = "footballBasketballAndVolleyball",
  BasketballAndTennis = "basketballAndTennis",
  FootballHandballVolleyballTennis = "footballHandballVolleyballAndTennis",
}

export enum CourtColors {
  lineColor = "lineColor",
  aroundColor = "aroundColor",
  fieldColor = "fieldColor",
  keyColor = "keyColor",
  attackZone = "attackZone",
  tennisColor = "tennisColor",
}

export enum CourtExtras {
  goals = "goals",
  baskets = "baskets",
  volleyballNet = "volleyballNet",
  tennisNet = "tennisNet",
}

export enum ModelCourt {
  boma = "Boma",
  bomaIndoor = "Boma indoor",
  star = "Star",
}

export enum ColorValues {
  RAL1002 = "#d2aa6d",
  RAL1011 = "#af804f",
  RAL1018 = "#fbca2f",
  RAL2008 = "#ec681a",
  RAL3020 = "#b81b0e",
  RAL4009 = "#9d8692",
  RAL5010 = "#004f7c",
  RAL5015 = "#0079ab",
  RAL5022 = "#222d5a",
  RAL6002 = "#325928",
  RAL6005 = "#0c3f2f",
  RAL6018 = "#609839",
  RAL7016 = "#353c40",
  RAL7040 = "#989ea1",
  RAL9005 = "#0a0c10",
  RAL9016 = "#ffffff",
}

export enum LineColorValues {
  RAL1018 = "#fbca2f",
  RAL3020 = "#b81b0e",
  RAL9016 = "#ffffff",
}
