import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Radio from "@mui/material/Radio";
import { Dispatch } from "react";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

import { SelectedCourtValues } from "../types/types";
import {
  ColorValues,
  CourtColors,
  LineColorValues,
  ModelCourt,
} from "../constants/constants";
import { formatColor } from "../utils/formatColor";

interface Props {
  selectedCourtStates: SelectedCourtValues;
  setSelectedCourtStates: Dispatch<React.SetStateAction<SelectedCourtValues>>;
  setIsSecondPage: Dispatch<React.SetStateAction<boolean>>;
  isSecondPage: boolean;
}

const StyledCheckbox = styled(Checkbox)`
  padding: 2px;
  & .MuiSvgIcon-root {
    width: 32px;
    height: 32px;
  }
`;

const StyledRadio = styled(Radio)(({ theme }) => ({
  padding: "2px",
  "& .MuiSvgIcon-root": {
    width: "32px",
    height: "32px",
    color: theme.palette.primary.main,
  },
}));

const CourtParams = ({
  selectedCourtStates,
  setSelectedCourtStates,
  setIsSecondPage,
  isSecondPage,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOptionChange = () => {
    setSelectedCourtStates({
      ...selectedCourtStates,
      outside: !selectedCourtStates.outside,
    });
  };

  const handleModelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCourtStates({
      ...selectedCourtStates,
      model: event.target.value as ModelCourt,
    });
  };

  return (
    <Grid
      container
      spacing={5}
      pt={2}
      justifyContent="space-between"
      alignItems="baseline"
    >
      {(!isMobile || (isMobile && !isSecondPage)) && (
        <Grid item xs={12} sm={12} lg={6}>
          <Stack>
            <Typography variant="h4" alignSelf="center">
              {t("color.color")}
            </Typography>
            <Divider />
            {Object.keys(selectedCourtStates.colors).map((colorItem) => (
              <Stack marginTop={1} id={colorItem} key={colorItem}>
                <Grid
                  container
                  key={colorItem}
                  direction={{ xs: "column", sm: "row", lg: "row" }}
                  alignItems="center"
                  justifyContent="center"
                  wrap="nowrap"
                >
                  <Grid item xs={12} sm={2} md={3} lg={2}>
                    <Typography variant="h5" textAlign="right">
                      {t(`color.${colorItem}`)}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={10} md={9} lg={10}>
                    {colorItem !== CourtColors.lineColor ? (
                      <>
                        {Object.values(ColorValues).map((colorValue) => (
                          <Tooltip
                            title={formatColor(colorValue).trim().split(" ")[0]}
                            placement="top"
                            arrow
                          >
                            <StyledCheckbox
                              key={colorValue + colorItem}
                              name={colorValue}
                              checked={
                                selectedCourtStates.colors[colorItem] ===
                                colorValue
                              }
                              onChange={() => {
                                setSelectedCourtStates((prev) => ({
                                  ...prev,
                                  colors: {
                                    ...prev.colors,
                                    [colorItem]: colorValue,
                                  },
                                }));
                              }}
                              icon={
                                colorValue !== ColorValues.RAL9016 ? (
                                  <RadioButtonCheckedIcon
                                    sx={{
                                      color: colorValue,
                                    }}
                                  />
                                ) : (
                                  <RadioButtonUncheckedIcon
                                    sx={{ color: "black" }}
                                  />
                                )
                              }
                              checkedIcon={
                                colorValue !== ColorValues.RAL9016 ? (
                                  <CheckCircleIcon sx={{ color: colorValue }} />
                                ) : (
                                  <CheckCircleOutlineIcon
                                    sx={{ color: "black" }}
                                  />
                                )
                              }
                            />
                          </Tooltip>
                        ))}
                      </>
                    ) : (
                      <>
                        {Object.values(LineColorValues).map(
                          (lineColorValue) => (
                            <Tooltip
                              title={
                                formatColor(lineColorValue).trim().split(" ")[0]
                              }
                              placement="top"
                              arrow
                            >
                              <StyledCheckbox
                                key={lineColorValue + colorItem}
                                name={lineColorValue}
                                checked={
                                  selectedCourtStates.colors[colorItem] ===
                                  lineColorValue
                                }
                                onChange={() => {
                                  setSelectedCourtStates((prev) => ({
                                    ...prev,
                                    colors: {
                                      ...prev.colors,
                                      [colorItem]: lineColorValue,
                                    },
                                  }));
                                }}
                                icon={
                                  lineColorValue !== LineColorValues.RAL9016 ? (
                                    <RadioButtonCheckedIcon
                                      sx={{
                                        color: lineColorValue,
                                      }}
                                    />
                                  ) : (
                                    <RadioButtonUncheckedIcon
                                      sx={{ color: "black" }}
                                    />
                                  )
                                }
                                checkedIcon={
                                  lineColorValue !== LineColorValues.RAL9016 ? (
                                    <CheckCircleIcon
                                      sx={{ color: lineColorValue }}
                                    />
                                  ) : (
                                    <CheckCircleOutlineIcon
                                      sx={{ color: "black" }}
                                    />
                                  )
                                }
                              />
                            </Tooltip>
                          )
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Stack>
            ))}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              justifyContent="center"
              marginTop={1}
            >
              <Typography
                variant="caption"
                fontWeight={700}
                sx={{ color: "#989EA1" }}
                alignSelf={{ xs: "center", sm: "flex-start" }}
              >
                {`${t("disclaimer.disclaimer")}:`}
              </Typography>
              {!isMobile && <>&nbsp;</>}
              <Typography
                variant="caption"
                textAlign={{ xs: "center", sm: "left" }}
                sx={{ color: "#989EA1" }}
              >
                {t("disclaimer.text")}
              </Typography>
              {isMobile && !isSecondPage && (
                <Stack mt={3}>
                  <Button
                    color="primary"
                    disabled={false}
                    variant="contained"
                    size="medium"
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={() => setIsSecondPage(true)}
                    sx={{
                      borderRadius: "50px",
                    }}
                  >
                    {t("buttons.next")}
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Grid>
      )}
      {(!isMobile || (isMobile && isSecondPage)) && (
        <>
          <Grid item xs={12} sm={6} lg={3}>
            <Stack>
              <Typography variant="h4" alignSelf="center">
                {t("extras.extras")}
              </Typography>
              <Divider />
              {Object.keys(selectedCourtStates.extras).map((extraItem) => (
                <Stack
                  direction="column"
                  alignItems="flex-end"
                  paddingRight="30%"
                  marginTop={1}
                  id={extraItem}
                  key={extraItem}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Typography variant="h5" textAlign="right">
                      {t(`extras.${extraItem}`)}:
                    </Typography>
                    <StyledCheckbox
                      name={extraItem}
                      checked={selectedCourtStates.extras[extraItem]}
                      onChange={(event) => {
                        const { name, checked } = event.target;
                        setSelectedCourtStates((prev) => ({
                          ...prev,
                          extras: {
                            ...prev.extras,
                            [name]: checked,
                          },
                        }));
                      }}
                      icon={
                        <CheckCircleOutlineIcon
                          sx={{ color: (theme) => theme.palette.primary.main }}
                        />
                      }
                      checkedIcon={<CheckCircleIcon />}
                    />
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Stack>
              <Typography variant="h4" alignSelf="center">
                {t("environment.environment")}
              </Typography>
              <Divider />
              <Stack
                marginTop={1}
                direction="column"
                alignItems="flex-end"
                paddingRight="30%"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography variant="h5">
                    {t("environment.outside")}:
                  </Typography>
                  <StyledRadio
                    checked={selectedCourtStates.outside}
                    value="outside"
                    name="radio-buttons"
                    onChange={handleOptionChange}
                  />
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography variant="h5">
                    {t("environment.inside")}:
                  </Typography>
                  <StyledRadio
                    value="inside"
                    checked={!selectedCourtStates.outside}
                    name="radio-buttons"
                    onChange={handleOptionChange}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack marginTop={isMobile ? 5 : 3}>
              <Typography variant="h4" alignSelf="center">
                {t("model.model")}
              </Typography>
              <Divider />
              <Stack
                marginTop={1}
                direction="column"
                alignItems="flex-end"
                paddingRight="30%"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography variant="h5">{t("model.boma")}:</Typography>
                  <StyledRadio
                    checked={selectedCourtStates.model === ModelCourt.boma}
                    value={ModelCourt.boma}
                    name="boma-Model"
                    onChange={handleModelChange}
                  />
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography variant="h5" noWrap>
                    {t("model.bomaIndoor")}:
                  </Typography>
                  <StyledRadio
                    value={ModelCourt.bomaIndoor}
                    checked={
                      selectedCourtStates.model === ModelCourt.bomaIndoor
                    }
                    name="boma-Model"
                    onChange={handleModelChange}
                  />
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography variant="h5">{t("model.star")}:</Typography>
                  <StyledRadio
                    value={ModelCourt.star}
                    checked={selectedCourtStates.model === ModelCourt.star}
                    name="boma-Model"
                    onChange={handleModelChange}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CourtParams;
