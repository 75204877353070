import { ColorValues } from "../constants/constants";

interface Props {
  lineColor?: ColorValues;
  fieldColor?: ColorValues;
  aroundColor?: ColorValues;
  tennisNet?: boolean;
}

const Tennis = ({
  lineColor = ColorValues.RAL9016,
  aroundColor = ColorValues.RAL6005,
  fieldColor = ColorValues.RAL6002,
  tennisNet = true,
}: Props) => {
  return (
    <svg
      id="tennis"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 316.93 132.97"
      style={{
        width: "100%", // Make the SVG responsive
        height: "auto", // Let the height adjust proportionally
      }}
    >
      <defs>
        <style>
          {`
                .cls-tennis-1{fill:#6b6b6b;}
                .cls-tennis-2{fill:#7f7f7f;}
                .cls-tennis-3{fill:${aroundColor};}
                .cls-tennis-4{fill:${fieldColor};}
                .cls-tennis-5{fill:${lineColor};}
                .cls-tennis-6{fill:#0f0f0f;}
                .cls-tennis-7{fill:#353535;}
                .cls-tennis-8{fill:#e2e2e2;}
          `}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Tenis">
            <g id="Sivo">
              <polygon
                className="cls-tennis-1"
                points="24.07 127.7 24.07 132.97 316.93 49.23 316.93 43.96 24.07 127.7"
              />
              <polygon
                className="cls-tennis-2"
                points="0 36.08 0 41.34 24.07 132.97 24.07 127.7 0 36.08"
              />
            </g>
            <polygon
              id="Obod"
              className="cls-tennis-3"
              points="0 36.08 24.07 127.7 316.93 43.96 211.36 0 0 36.08"
            />
            <g id="Podloga">
              <polygon
                className="cls-tennis-4"
                points="37.16 37.96 67.45 99.01 273.98 44.89 197.47 9.8 37.16 37.96"
              />
            </g>
            <g id="Linija">
              <polygon
                className="cls-tennis-5"
                points="264.19 40.36 64.47 91.19 64.02 90.2 262.81 39.61 264.19 40.36"
              />
              <polygon
                className="cls-tennis-5"
                points="192.36 32.53 106.5 50.69 106.05 49.7 190.98 31.77 192.36 32.53"
              />
              <polygon
                className="cls-tennis-5"
                points="52.07 63.44 50.17 63.85 49.82 63.06 51.65 62.65 52.07 63.44"
              />
              <polygon
                className="cls-tennis-5"
                points="229.69 24.44 227.05 25.03 226.02 24.55 228.57 24 229.69 24.44"
              />
              <polygon
                className="cls-tennis-5"
                points="202.84 12.33 39.48 42.54 39.06 41.71 201.68 11.64 202.84 12.33"
              />
              <path
                className="cls-tennis-5"
                d="M67,100,36.51,37.67,197.69,9.35l77.45,35.53ZM37.81,38.17,68,98.29,272.75,44.76,197.53,10.35Z"
              />
              <polygon
                className="cls-tennis-5"
                points="126.24 75.04 91.26 32.48 92.31 32.2 127.37 74.74 126.24 75.04"
              />
              <polygon
                className="cls-tennis-5"
                points="221.6 50.95 167.69 18.37 169.17 18.05 222.68 50.64 221.6 50.95"
              />
              <polygon
                className="cls-tennis-5"
                points="185.94 68.28 127.88 22.17 129.18 21.83 187.07 67.81 185.94 68.28"
              />
            </g>
            {tennisNet && (
              <g id="Teniska_mreza" data-name="Teniska mreza">
                <path
                  className="cls-tennis-6"
                  d="M129.61,22.24a.46.46,0,1,1-.45-.38A.42.42,0,0,1,129.61,22.24Z"
                />
                <rect
                  className="cls-tennis-6"
                  x="128.71"
                  y="7.6"
                  width="0.9"
                  height="14.62"
                />
                <path
                  className="cls-tennis-7"
                  d="M129.61,7.6a.46.46,0,1,1-.45-.38A.42.42,0,0,1,129.61,7.6Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M186.16,54a.08.08,0,0,1-.07,0L129.37,8.12a.24.24,0,0,1-.05-.27c0-.09.12-.12.19-.07l56.72,45.91a.24.24,0,0,1,.05.26A.15.15,0,0,1,186.16,54Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M186.12,55.11a.11.11,0,0,1-.07,0L129.37,9.17a.22.22,0,0,1-.05-.26c0-.1.12-.13.19-.07l56.68,45.91a.22.22,0,0,1,.05.26A.14.14,0,0,1,186.12,55.11Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M186.16,56.27a.09.09,0,0,1-.07,0L129.37,10.3a.22.22,0,0,1-.05-.26c0-.09.12-.12.19-.07l56.72,45.94a.26.26,0,0,1,.05.26A.16.16,0,0,1,186.16,56.27Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M186.16,57.32l-.07,0L129.37,11.36a.24.24,0,0,1-.05-.27c0-.09.12-.12.19-.07L186.23,57a.28.28,0,0,1,.05.27A.15.15,0,0,1,186.16,57.32Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M185.84,58.2l-.07,0-56.4-45.68a.23.23,0,0,1-.05-.26c0-.1.12-.13.19-.07l56.4,45.68a.24.24,0,0,1,.05.27A.13.13,0,0,1,185.84,58.2Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M185.84,62.57a.11.11,0,0,1-.07,0l-56.4-45.69a.22.22,0,0,1-.05-.26c0-.1.12-.13.19-.07l56.4,45.69a.22.22,0,0,1,.05.26A.13.13,0,0,1,185.84,62.57Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M186,63.75a.11.11,0,0,1-.07,0L129.37,17.91a.22.22,0,0,1-.05-.26c0-.09.12-.12.19-.07l56.55,45.81a.22.22,0,0,1,.05.26A.14.14,0,0,1,186,63.75Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M186,64.88a.11.11,0,0,1-.07,0l-56.55-45.8a.24.24,0,0,1-.05-.27c0-.09.12-.12.19-.06l56.55,45.8a.23.23,0,0,1,.05.26A.12.12,0,0,1,186,64.88Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M186,65.93a.1.1,0,0,1-.07,0L129.37,20.1a.22.22,0,0,1-.05-.26c0-.1.12-.13.19-.07l56.55,45.81a.22.22,0,0,1,.05.26A.13.13,0,0,1,186,65.93Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M186.16,67.2a.09.09,0,0,1-.07,0L129.37,21.23a.22.22,0,0,1-.05-.26c0-.09.12-.12.19-.07l56.72,45.94a.24.24,0,0,1,.05.26A.14.14,0,0,1,186.16,67.2Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M186.16,59.51a.09.09,0,0,1-.07,0L129.37,13.54a.22.22,0,0,1-.05-.26c0-.09.12-.12.19-.07l56.72,45.94a.26.26,0,0,1,.05.26A.16.16,0,0,1,186.16,59.51Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M186.16,60.64a.09.09,0,0,1-.07,0L129.37,14.67a.22.22,0,0,1-.05-.26c0-.09.12-.12.19-.07l56.72,45.94a.26.26,0,0,1,.05.27A.15.15,0,0,1,186.16,60.64Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M186,61.56l-.07,0L129.37,15.73a.22.22,0,0,1-.05-.26c0-.1.12-.13.19-.07l56.55,45.8a.24.24,0,0,1,.05.27A.13.13,0,0,1,186,61.56Z"
                />
                <path
                  className="cls-tennis-6"
                  d="M186.49,68.16a.42.42,0,0,1-.45.38.39.39,0,1,1,0-.76A.42.42,0,0,1,186.49,68.16Z"
                />
                <rect
                  className="cls-tennis-6"
                  x="185.58"
                  y="53.52"
                  width="0.9"
                  height="14.62"
                />
                <path
                  className="cls-tennis-7"
                  d="M186.49,53.52a.42.42,0,0,1-.45.38.39.39,0,1,1,0-.76A.42.42,0,0,1,186.49,53.52Z"
                />
                <polygon
                  className="cls-tennis-8"
                  points="152.11 39.62 152.69 40.09 152.69 26.55 152.11 26.11 152.11 39.62"
                />
                <path
                  className="cls-tennis-8"
                  d="M130.47,22c-.08,0-.14-.08-.14-.19v-13c0-.11.06-.19.14-.19a.17.17,0,0,1,.14.19v13A.17.17,0,0,1,130.47,22Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M131.68,23c-.08,0-.14-.09-.14-.19v-13c0-.11.06-.2.14-.2s.14.09.14.2v13C131.82,22.94,131.75,23,131.68,23Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M132.89,24.05c-.08,0-.14-.08-.14-.19v-13c0-.11.06-.19.14-.19s.14.08.14.19v13C133,24,133,24.05,132.89,24.05Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M134.1,24.89c-.08,0-.14-.09-.14-.2V11.92c0-.11.06-.19.14-.19s.14.08.14.19V24.69C134.24,24.8,134.18,24.89,134.1,24.89Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M135.31,25.91c-.08,0-.14-.09-.14-.19v-13c0-.11.06-.19.14-.19s.14.08.14.19v13C135.45,25.82,135.39,25.91,135.31,25.91Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M136.52,26.85c-.07,0-.14-.09-.14-.2v-13a.17.17,0,0,1,.14-.19c.08,0,.14.08.14.19v13C136.66,26.76,136.6,26.85,136.52,26.85Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M137.73,27.87c-.07,0-.14-.09-.14-.19v-13a.17.17,0,0,1,.14-.19c.08,0,.14.08.14.19v13C137.87,27.78,137.81,27.87,137.73,27.87Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M138.94,29c-.07,0-.13-.09-.13-.2v-13c0-.11.06-.19.13-.19s.14.08.14.19v13C139.08,28.87,139,29,138.94,29Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M140.15,29.94c-.07,0-.13-.09-.13-.2v-13c0-.1.06-.19.13-.19s.14.09.14.19v13C140.29,29.85,140.23,29.94,140.15,29.94Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M141.37,30.92c-.08,0-.14-.09-.14-.2V17.78c0-.11.06-.19.14-.19s.13.08.13.19V30.72C141.5,30.83,141.44,30.92,141.37,30.92Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M142.58,31.9c-.08,0-.14-.09-.14-.2V18.81c0-.11.06-.2.14-.2s.14.09.14.2V31.7C142.72,31.81,142.65,31.9,142.58,31.9Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M143.79,32.86c-.08,0-.14-.09-.14-.19V19.56c0-.11.06-.2.14-.2s.14.09.14.2V32.67C143.93,32.77,143.86,32.86,143.79,32.86Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M145,33.89c-.08,0-.14-.09-.14-.2v-13c0-.1.06-.19.14-.19s.14.09.14.19v13C145.14,33.8,145.08,33.89,145,33.89Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M146.21,34.82c-.08,0-.14-.09-.14-.19v-13c0-.1.06-.19.14-.19s.14.09.14.19v13C146.35,34.73,146.29,34.82,146.21,34.82Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M147.42,35.69c-.08,0-.14-.09-.14-.19V22.63c0-.1.06-.19.14-.19s.14.09.14.19V35.5C147.56,35.6,147.5,35.69,147.42,35.69Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M148.63,36.8c-.08,0-.14-.09-.14-.19V23.71c0-.1.06-.19.14-.19s.14.09.14.19v12.9C148.77,36.71,148.71,36.8,148.63,36.8Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M149.84,37.77c-.07,0-.14-.09-.14-.2V24.67a.17.17,0,0,1,.14-.19c.08,0,.14.08.14.19v12.9C150,37.68,149.92,37.77,149.84,37.77Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M151,38.52c-.08,0-.14-.08-.14-.19V25.43c0-.11.06-.19.14-.19s.14.08.14.19v12.9C151.1,38.44,151,38.52,151,38.52Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M155,41.75c-.08,0-.14-.09-.14-.19v-13c0-.11.06-.2.14-.2s.14.09.14.2v13C155.09,41.66,155,41.75,155,41.75Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M153.79,40.93c-.08,0-.14-.08-.14-.19v-13c0-.11.06-.19.14-.19s.14.08.14.19v13C153.93,40.85,153.87,40.93,153.79,40.93Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M156.16,42.77c-.08,0-.14-.08-.14-.19v-13c0-.11.06-.2.14-.2s.14.09.14.2v13C156.3,42.69,156.24,42.77,156.16,42.77Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M157.37,43.8c-.08,0-.14-.09-.14-.19v-13c0-.11.06-.19.14-.19s.14.08.14.19v13C157.51,43.71,157.45,43.8,157.37,43.8Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M158.58,44.63c-.08,0-.14-.08-.14-.19V31.67c0-.11.06-.2.14-.2s.14.09.14.2V44.44C158.72,44.55,158.66,44.63,158.58,44.63Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M159.79,45.66c-.07,0-.14-.09-.14-.19v-13c0-.11.07-.2.14-.2s.14.09.14.2v13C159.93,45.57,159.87,45.66,159.79,45.66Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M161,46.59a.17.17,0,0,1-.14-.19V33.49c0-.11.07-.2.14-.2s.14.09.14.2V46.4C161.14,46.51,161.08,46.59,161,46.59Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M162.21,47.62c-.07,0-.13-.09-.13-.19v-13c0-.1.06-.19.13-.19s.14.09.14.19v13C162.35,47.53,162.29,47.62,162.21,47.62Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M163.43,48.7c-.08,0-.14-.08-.14-.19v-13c0-.1.06-.19.14-.19s.13.09.13.19v13C163.56,48.62,163.5,48.7,163.43,48.7Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M164.64,49.68c-.08,0-.14-.08-.14-.19V36.44c0-.1.06-.19.14-.19s.14.09.14.19V49.49A.17.17,0,0,1,164.64,49.68Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M165.85,50.66c-.08,0-.14-.08-.14-.19V37.53c0-.11.06-.19.14-.19a.17.17,0,0,1,.14.19V50.47A.17.17,0,0,1,165.85,50.66Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M167.06,51.64c-.08,0-.14-.08-.14-.19V38.55c0-.1.06-.19.14-.19s.14.09.14.19v12.9A.17.17,0,0,1,167.06,51.64Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M168.27,52.61c-.08,0-.14-.09-.14-.2v-13c0-.1.06-.19.14-.19s.14.09.14.19v13C168.41,52.52,168.35,52.61,168.27,52.61Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M169.48,53.63c-.08,0-.14-.08-.14-.19v-13c0-.1.06-.19.14-.19s.14.09.14.19v13C169.62,53.55,169.56,53.63,169.48,53.63Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M170.69,54.57c-.08,0-.14-.09-.14-.2v-13c0-.1.06-.19.14-.19s.14.09.14.19v13C170.83,54.48,170.77,54.57,170.69,54.57Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M171.9,55.44c-.07,0-.14-.09-.14-.2V42.38c0-.1.07-.19.14-.19s.14.09.14.19V55.24C172,55.35,172,55.44,171.9,55.44Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M173.11,56.55c-.07,0-.14-.09-.14-.19V43.46a.17.17,0,0,1,.14-.19c.08,0,.14.08.14.19v12.9C173.25,56.46,173.19,56.55,173.11,56.55Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M174.32,57.51c-.07,0-.13-.08-.13-.19V44.42c0-.11.06-.2.13-.2s.14.09.14.2v12.9C174.46,57.43,174.4,57.51,174.32,57.51Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M175.53,58.44c-.07,0-.13-.09-.13-.19V45.34c0-.1.06-.19.13-.19s.14.09.14.19V58.25C175.67,58.35,175.61,58.44,175.53,58.44Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M176.75,59.53c-.08,0-.14-.09-.14-.2V46.44c0-.11.06-.2.14-.2s.13.09.13.2V59.33C176.88,59.44,176.82,59.53,176.75,59.53Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M178,60.49c-.08,0-.14-.09-.14-.19V47.39c0-.1.06-.19.14-.19s.14.09.14.19V60.3C178.1,60.4,178,60.49,178,60.49Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M179.17,61.42c-.08,0-.14-.09-.14-.19V48.32c0-.11.06-.19.14-.19a.17.17,0,0,1,.14.19V61.23C179.31,61.33,179.24,61.42,179.17,61.42Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M180.38,62.36c-.08,0-.14-.09-.14-.2V49.26c0-.11.06-.19.14-.19s.14.08.14.19v12.9C180.52,62.27,180.46,62.36,180.38,62.36Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M181.59,63.44c-.08,0-.14-.09-.14-.19V50.35c0-.1.06-.19.14-.19s.14.09.14.19v12.9C181.73,63.35,181.67,63.44,181.59,63.44Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M182.8,64.41c-.08,0-.14-.09-.14-.2V51.31c0-.11.06-.19.14-.19s.14.08.14.19v12.9C182.94,64.32,182.88,64.41,182.8,64.41Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M184,65.33c-.08,0-.14-.08-.14-.19V52.24c0-.11.06-.2.14-.2s.14.09.14.2v12.9C184.15,65.25,184.09,65.33,184,65.33Z"
                />
                <path
                  className="cls-tennis-8"
                  d="M185.22,66.33a.17.17,0,0,1-.14-.19V53.24c0-.11.07-.2.14-.2s.14.09.14.2v12.9C185.36,66.25,185.3,66.33,185.22,66.33Z"
                />
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Tennis;
