import { ColorValues } from "../constants/constants";

interface Props {
  lineColor?: ColorValues;
  fieldColor?: ColorValues;
  attackZone?: ColorValues;
  aroundColor?: ColorValues;
  volleyballNet?: boolean;
}

const Volleyball = ({
  lineColor = ColorValues.RAL9016,
  aroundColor = ColorValues.RAL5015,
  fieldColor = ColorValues.RAL2008,
  attackZone = ColorValues.RAL7016,
  volleyballNet = true,
}: Props) => {
  return (
    <svg
      id="volleyball"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 316.93 143.69"
      style={{
        width: "100%", // Make the SVG responsive
        height: "auto", // Let the height adjust proportionally
      }}
    >
      <defs>
        <style>
          {`
                .cls-volleyball1{fill:#6b6b6b;}
                .cls-volleyball2{fill:#7f7f7f;}
                .cls-volleyball3{fill:${aroundColor};}
                .cls-volleyball4{fill:${fieldColor};}
                .cls-volleyball5{fill:${attackZone};}
                .cls-volleyball6{fill:${lineColor};}
                .cls-volleyball7{fill:#0f0f0f;}
                .cls-volleyball8{fill:#353535;}
                .cls-volleyball9{fill:#e2e2e2;}
          `}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Odbojka">
            <g id="Sivo">
              <polygon
                className="cls-volleyball1"
                points="24.07 138.43 24.07 143.69 316.93 59.95 316.93 54.69 24.07 138.43"
              />
              <polygon
                className="cls-volleyball2"
                points="0 46.8 0 52.07 24.07 143.69 24.07 138.43 0 46.8"
              />
            </g>
            <polygon
              id="Obod"
              className="cls-volleyball3"
              points="0 46.8 24.07 138.43 316.93 54.69 211.36 10.73 0 46.8"
            />
            <polygon
              id="Podloga"
              className="cls-volleyball4"
              points="39.2 52.81 63.43 101.64 263.2 50.68 201.9 22.84 39.2 52.81"
            />
            <polygon
              id="Smec"
              className="cls-volleyball5"
              points="103.62 40.81 140.09 82.1 209.74 64.29 159.93 30.43 103.62 40.81"
            />
            <g id="Linija">
              <path
                className="cls-volleyball6"
                d="M63,102.46,38.62,52.55,202.3,22.2l62.1,28.52ZM39.92,53.05l24,47.76L262,50.61,202.14,23.19Z"
              />
              <polygon
                className="cls-volleyball6"
                points="139.68 82.4 103.04 41.09 104.1 40.81 140.81 82.1 139.68 82.4"
              />
              <polygon
                className="cls-volleyball6"
                points="101.84 39.62 100.91 38.67 101.89 38.53 102.88 39.47 101.84 39.62"
              />
              <polygon
                className="cls-volleyball6"
                points="99.8 37.55 98.87 36.6 99.85 36.46 100.84 37.4 99.8 37.55"
              />
              <polygon
                className="cls-volleyball6"
                points="97.81 35.44 96.88 34.49 97.86 34.35 98.84 35.29 97.81 35.44"
              />
              <polygon
                className="cls-volleyball6"
                points="95.77 33.37 94.84 32.43 95.82 32.28 96.8 33.23 95.77 33.37"
              />
              <polygon
                className="cls-volleyball6"
                points="151.09 94.54 149.75 93.27 151.11 93.02 152.52 94.29 151.09 94.54"
              />
              <polygon
                className="cls-volleyball6"
                points="54.59 103.27 58.57 102.4 59.18 103.42 55.16 104.38 54.59 103.27"
              />
              <polygon
                className="cls-volleyball6"
                points="32.9 53.67 36.18 53.1 36.64 53.96 33.32 54.6 32.9 53.67"
              />
              <polygon
                className="cls-volleyball6"
                points="148.19 91.59 146.85 90.32 148.21 90.08 149.62 91.35 148.19 91.59"
              />
              <polygon
                className="cls-volleyball6"
                points="145.4 88.64 144.06 87.36 145.42 87.12 146.83 88.39 145.4 88.64"
              />
              <polygon
                className="cls-volleyball6"
                points="142.48 85.84 141.14 84.57 142.5 84.33 143.91 85.6 142.48 85.84"
              />
              <polygon
                className="cls-volleyball6"
                points="223.65 74.11 222.11 73.09 223.4 72.61 225.01 73.61 223.65 74.11"
              />
              <polygon
                className="cls-volleyball6"
                points="220.28 71.88 218.74 70.87 220.03 70.39 221.65 71.38 220.28 71.88"
              />
              <polygon
                className="cls-volleyball6"
                points="216.96 69.59 215.42 68.57 216.71 68.09 218.33 69.09 216.96 69.59"
              />
              <polygon
                className="cls-volleyball6"
                points="213.59 67.36 212.05 66.35 213.34 65.87 214.96 66.87 213.59 67.36"
              />
              <polygon
                className="cls-volleyball6"
                points="157.2 29.38 156.05 28.62 157.01 28.27 158.22 29.01 157.2 29.38"
              />
              <polygon
                className="cls-volleyball6"
                points="154.68 27.72 153.53 26.96 154.49 26.61 155.7 27.36 154.68 27.72"
              />
              <polygon
                className="cls-volleyball6"
                points="152.2 26.01 151.05 25.26 152.01 24.91 153.22 25.65 152.2 26.01"
              />
              <polygon
                className="cls-volleyball6"
                points="149.68 24.36 148.53 23.6 149.5 23.25 150.71 23.99 149.68 24.36"
              />
              <polygon
                className="cls-volleyball6"
                points="209.24 64.44 158.9 30.75 160.38 30.43 210.32 64.13 209.24 64.44"
              />
              <polygon
                className="cls-volleyball6"
                points="177.99 72.69 131.43 35.73 132.73 35.39 179.12 72.22 177.99 72.69"
              />
              <polygon
                className="cls-volleyball6"
                points="209.26 21.35 206.05 22.09 205.05 21.57 208.09 20.92 209.26 21.35"
              />
              <polygon
                className="cls-volleyball6"
                points="271.28 48.85 266.68 50.04 265.39 49.46 269.79 48.36 271.28 48.85"
              />
            </g>
            {volleyballNet && (
              <g id="Odbojkaska_mreza" data-name="Odbojkaska mreza">
                <path
                  className="cls-volleyball7"
                  d="M126,32a.56.56,0,0,1-.62.47c-.35,0-.62-.21-.62-.47a.56.56,0,0,1,.62-.48A.57.57,0,0,1,126,32Z"
                />
                <rect
                  className="cls-volleyball7"
                  x="124.79"
                  y="0.47"
                  width="1.24"
                  height="31.45"
                />
                <path
                  className="cls-volleyball8"
                  d="M126,.47a.57.57,0,0,1-.62.48.56.56,0,0,1-.62-.48c0-.26.27-.47.62-.47A.56.56,0,0,1,126,.47Z"
                />
                <path
                  className="cls-volleyball9"
                  d="M189.63,48.83a.14.14,0,0,1-.1,0L125.69,2a.27.27,0,0,1-.06-.33.17.17,0,0,1,.26-.08l63.84,46.84a.27.27,0,0,1,.06.33A.18.18,0,0,1,189.63,48.83Z"
                />
                <path
                  className="cls-volleyball9"
                  d="M184,46.05a.19.19,0,0,1-.1,0L130.53,6.82a.26.26,0,0,1-.07-.33.17.17,0,0,1,.26-.09l53.37,39.2a.27.27,0,0,1,.06.33A.17.17,0,0,1,184,46.05Z"
                />
                <path
                  className="cls-volleyball9"
                  d="M184,47.46a.19.19,0,0,1-.1,0L130.53,8.23a.26.26,0,0,1-.07-.33.17.17,0,0,1,.26-.08L184.09,47a.25.25,0,0,1,.06.32A.17.17,0,0,1,184,47.46Z"
                />
                <path
                  className="cls-volleyball9"
                  d="M184,48.78a.19.19,0,0,1-.1,0L130.53,9.55a.26.26,0,0,1-.07-.33.17.17,0,0,1,.26-.09l53.37,39.2a.27.27,0,0,1,.06.33A.17.17,0,0,1,184,48.78Z"
                />
                <path
                  className="cls-volleyball9"
                  d="M184,50.2a.14.14,0,0,1-.1,0L130.53,11a.25.25,0,0,1-.07-.32.17.17,0,0,1,.26-.09l53.37,39.2a.27.27,0,0,1,.06.33A.18.18,0,0,1,184,50.2Z"
                />
                <path
                  className="cls-volleyball9"
                  d="M189.2,59.49a.13.13,0,0,1-.1,0L125.69,12.88a.27.27,0,0,1-.06-.33.17.17,0,0,1,.26-.08L189.29,59a.27.27,0,0,1,.07.33A.19.19,0,0,1,189.2,59.49Z"
                />
                <path
                  className="cls-volleyball9"
                  d="M184,51.51a.19.19,0,0,1-.1,0l-53.36-39.2a.26.26,0,0,1-.07-.33.17.17,0,0,1,.26-.08l53.37,39.2a.25.25,0,0,1,.06.32A.17.17,0,0,1,184,51.51Z"
                />
                <path
                  className="cls-volleyball9"
                  d="M184,52.93a.19.19,0,0,1-.1,0L130.53,13.7a.26.26,0,0,1-.07-.33.17.17,0,0,1,.26-.09l53.37,39.2a.27.27,0,0,1,.06.33A.17.17,0,0,1,184,52.93Z"
                />
                <path
                  className="cls-volleyball9"
                  d="M184,54.25a.14.14,0,0,1-.1,0L130.53,15a.25.25,0,0,1-.07-.32.17.17,0,0,1,.26-.09l53.37,39.2a.27.27,0,0,1,.06.33A.18.18,0,0,1,184,54.25Z"
                />
                <path
                  className="cls-volleyball7"
                  d="M190.25,78.76a.64.64,0,1,1-.62-.48A.56.56,0,0,1,190.25,78.76Z"
                />
                <rect
                  className="cls-volleyball7"
                  x="189.01"
                  y="47.44"
                  width="1.24"
                  height="31.29"
                />
                <path
                  className="cls-volleyball8"
                  d="M190.25,47.44a.64.64,0,0,1-1.24,0,.64.64,0,0,1,1.24,0Z"
                />
                <polygon
                  className="cls-volleyball9"
                  points="132.56 17.54 132.17 17.36 132.17 6.43 132.56 6.61 132.56 17.54"
                />
                <polygon
                  className="cls-volleyball9"
                  points="134.39 18.92 134.01 18.74 134.01 7.81 134.39 7.99 134.39 18.92"
                />
                <polygon
                  className="cls-volleyball9"
                  points="136.14 20.18 135.75 20 135.75 9.07 136.14 9.25 136.14 20.18"
                />
                <polygon
                  className="cls-volleyball9"
                  points="137.98 21.56 137.59 21.38 137.59 10.45 137.98 10.63 137.98 21.56"
                />
                <polygon
                  className="cls-volleyball9"
                  points="139.84 22.91 139.46 22.73 139.46 11.8 139.84 11.98 139.84 22.91"
                />
                <polygon
                  className="cls-volleyball9"
                  points="141.68 24.3 141.29 24.12 141.29 13.18 141.68 13.36 141.68 24.3"
                />
                <polygon
                  className="cls-volleyball9"
                  points="143.42 25.56 143.04 25.38 143.04 14.44 143.42 14.62 143.42 25.56"
                />
                <polygon
                  className="cls-volleyball9"
                  points="145.26 26.94 144.88 26.76 144.88 15.82 145.26 16 145.26 26.94"
                />
                <polygon
                  className="cls-volleyball9"
                  points="147.1 28.32 146.72 28.14 146.72 17.21 147.1 17.39 147.1 28.32"
                />
                <polygon
                  className="cls-volleyball9"
                  points="148.84 29.58 148.46 29.4 148.46 18.47 148.84 18.65 148.84 29.58"
                />
                <polygon
                  className="cls-volleyball9"
                  points="150.68 30.96 150.3 30.78 150.3 19.85 150.68 20.03 150.68 30.96"
                />
                <polygon
                  className="cls-volleyball9"
                  points="152.54 32.31 152.16 32.13 152.16 21.2 152.54 21.38 152.54 32.31"
                />
                <polygon
                  className="cls-volleyball9"
                  points="154.38 33.69 154 33.51 154 22.58 154.38 22.76 154.38 33.69"
                />
                <polygon
                  className="cls-volleyball9"
                  points="156.12 34.95 155.74 34.77 155.74 23.84 156.12 24.02 156.12 34.95"
                />
                <polygon
                  className="cls-volleyball9"
                  points="157.96 36.34 157.58 36.16 157.58 25.22 157.96 25.4 157.96 36.34"
                />
                <polygon
                  className="cls-volleyball9"
                  points="159.71 37.6 159.32 37.42 159.32 26.48 159.71 26.66 159.71 37.6"
                />
                <polygon
                  className="cls-volleyball9"
                  points="161.54 38.98 161.16 38.8 161.16 27.86 161.54 28.05 161.54 38.98"
                />
                <polygon
                  className="cls-volleyball9"
                  points="163.41 40.33 163.02 40.15 163.02 29.22 163.41 29.4 163.41 40.33"
                />
                <polygon
                  className="cls-volleyball9"
                  points="165.25 41.71 164.86 41.53 164.86 30.6 165.25 30.78 165.25 41.71"
                />
                <polygon
                  className="cls-volleyball9"
                  points="166.99 42.97 166.61 42.79 166.61 31.86 166.99 32.04 166.99 42.97"
                />
                <polygon
                  className="cls-volleyball9"
                  points="168.83 44.35 168.44 44.17 168.44 33.24 168.83 33.42 168.83 44.35"
                />
                <polygon
                  className="cls-volleyball9"
                  points="170.66 45.58 170.28 45.4 170.28 34.47 170.66 34.65 170.66 45.58"
                />
                <polygon
                  className="cls-volleyball9"
                  points="172.53 46.93 172.15 46.75 172.15 35.82 172.53 36 172.53 46.93"
                />
                <polygon
                  className="cls-volleyball9"
                  points="174.56 48.31 174.18 48.13 174.18 37.2 174.56 37.38 174.56 48.31"
                />
                <polygon
                  className="cls-volleyball9"
                  points="176.4 49.91 176.01 49.73 176.01 38.8 176.4 38.98 176.4 49.91"
                />
                <polygon
                  className="cls-volleyball9"
                  points="178.43 51.27 178.04 51.09 178.04 40.16 178.43 40.34 178.43 51.27"
                />
                <polygon
                  className="cls-volleyball9"
                  points="180.36 52.67 179.98 52.49 179.98 41.56 180.36 41.74 180.36 52.67"
                />
                <polygon
                  className="cls-volleyball9"
                  points="182.2 54.05 181.82 53.87 181.82 42.94 182.2 43.12 182.2 54.05"
                />
                <rect
                  className="cls-volleyball9"
                  x="183.8"
                  y="44.44"
                  width="0.38"
                  height="10.93"
                />
                <rect
                  className="cls-volleyball9"
                  x="130.43"
                  y="5.29"
                  width="0.38"
                  height="10.93"
                />
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Volleyball;
