import { ColorValues } from "../constants/constants";

interface Props {
  lineColor?: ColorValues;
  fieldColor?: ColorValues;
  aroundColor?: ColorValues;
  goals?: boolean;
}

const FootballAndHandball = ({
  lineColor = ColorValues.RAL9016,
  aroundColor = ColorValues.RAL3020,
  fieldColor = ColorValues.RAL5015,
  goals = true,
}: Props) => {
  return (
    <svg
      id="football-and-handball"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 395.97 185.5"
      style={{
        width: "100%", // Make the SVG responsive
        height: "auto", // Let the height adjust proportionally
      }}
    >
      <defs>
        <style>
          {`
           .cls-f-and-h-1{fill:#6b6b6b;}
           .cls-f-and-h-2{fill:#7f7f7f;}
           .cls-f-and-h-3{fill:${aroundColor};}
           .cls-f-and-h-4{fill:${fieldColor};}
           .cls-f-and-h-5{fill:${lineColor};}
           .cls-f-and-h-6,.cls-f-and-h-7,.cls-f-and-h-8{fill:none;}
           .cls-f-and-h-6{stroke:${lineColor};stroke-miterlimit:10;}
           .cls-f-and-h-10,.cls-f-and-h-7,.cls-f-and-h-8,.cls-f-and-h-9{stroke:#fff;stroke-miterlimit:10;}
           .cls-f-and-h-7{stroke-width:0.75px;}
           .cls-f-and-h-10,.cls-f-and-h-8,.cls-f-and-h-9{stroke-width:0.25px;}
           .cls-f-and-h-9{fill:#d86161;}.cls-f-and-h-10{fill:#170900;}
          `}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Fudbal_Rukomet" data-name="Fudbal &amp; Rukomet">
            <g id="Sivo">
              <polygon
                className="cls-f-and-h-1"
                points="104.67 180.24 104.67 185.5 395.97 38.68 395.97 33.41 104.67 180.24"
              />
              <polygon
                className="cls-f-and-h-2"
                points="0 74.19 0 79.45 104.67 185.5 104.67 180.24 0 74.19"
              />
            </g>
            <polygon
              id="Obod"
              className="cls-f-and-h-3"
              points="0 74.19 104.67 180.24 395.97 33.41 266.32 0 0 74.19"
            />
            <polygon
              id="Podloga"
              className="cls-f-and-h-4"
              points="31.9 72.14 126.51 156.06 369.66 36.67 260.16 7.28 31.9 72.14"
            />
            <g id="Linija">
              <path
                className="cls-f-and-h-5"
                d="M218.69,71.58c-13.32-1-23.79-6.7-23.34-12.71s11.65-10.11,25-9.12,23.79,6.69,23.34,12.71S232,72.57,218.69,71.58Zm1.56-20.91C207.61,49.73,197,53.45,196.58,59s9.54,10.76,22.18,11.7,23.25-2.78,23.66-8.29S232.89,51.61,220.25,50.67Z"
                transform="translate(0 -8.01)"
              />
              <polygon
                className="cls-f-and-h-5"
                points="283.3 79.41 170.89 33.05 171.45 32.09 283.86 78.45 283.3 79.41"
              />
              <path
                className="cls-f-and-h-5"
                d="M126.3,165.1,30.88,80,260.19,14.78l111,29.82ZM32.61,80.29l94.2,82.79L368.16,44.85l-108-29.08Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M155.78,148.56q1.68-1.47,3.18-3l1,.35c-1,1-2.09,2.05-3.22,3Zm5.11-5.07c.91-1,1.78-2.12,2.57-3.2l1.08.28c-.81,1.1-1.69,2.19-2.62,3.24Zm4.09-5.38c.71-1.1,1.36-2.22,1.94-3.35l1.13.2c-.59,1.14-1.26,2.28-2,3.4Zm3-5.62A35.14,35.14,0,0,0,169.3,129l1.16.12a33.81,33.81,0,0,1-1.32,3.5Zm1.93-5.77a33.28,33.28,0,0,0,.62-3.52l1.17,0a30.85,30.85,0,0,1-.63,3.56Zm.81-5.87a31.81,31.81,0,0,0-.07-3.52l1.18,0a30.35,30.35,0,0,1,.06,3.57Zm-.34-5.86a32.33,32.33,0,0,0-.74-3.49l1.16-.13a31.42,31.42,0,0,1,.75,3.54Zm-1.47-5.8a35.74,35.74,0,0,0-1.43-3.41l1.14-.2a36.68,36.68,0,0,1,1.44,3.46Zm-2.6-5.64a37.61,37.61,0,0,0-2.08-3.29l1.08-.28c.77,1.09,1.48,2.21,2.12,3.33Zm-3.68-5.42c-.84-1-1.75-2.1-2.71-3.11l1-.36c1,1,1.89,2.09,2.74,3.16Zm-8-8,1-.42c1.16,1,2.28,1.94,3.34,2.94L158,93C156.91,92,155.8,91.05,154.66,90.11Zm-6.21-4.5.87-.49c1.34.87,2.64,1.77,3.88,2.69l-.92.45C151.06,87.35,149.77,86.46,148.45,85.61Zm-7-4,.76-.54c1.51.76,3,1.56,4.39,2.39l-.82.51C144.34,83.12,142.88,82.33,141.4,81.58Zm-7.82-3.51.66-.59c1.65.65,3.27,1.35,4.84,2.07l-.73.56C136.81,79.4,135.21,78.71,133.58,78.07Zm-8.45-2.93.54-.63c1.76.53,3.52,1.11,5.21,1.71l-.61.61C128.6,76.23,126.87,75.67,125.13,75.14Zm-9-2.31.42-.67c1.86.41,3.71.86,5.51,1.34l-.49.64C119.82,73.67,118,73.22,116.15,72.83Zm-9.37-1.68.29-.68c1.92.27,3.85.58,5.74.94l-.36.67C110.59,71.73,108.68,71.42,106.78,71.15Zm-9.64-1,.15-.7c2,.14,3.95.32,5.89.53l-.23.7C101,70.45,99.08,70.27,97.14,70.14Zm-9.79-.34,0-.7c2,0,4,0,6,.12l-.1.7C91.28,69.84,89.31,69.8,87.35,69.8Zm-9.79.35-.13-.7c2-.14,4-.24,6-.29l0,.7C81.48,69.92,79.5,70,77.56,70.15Zm-9.67,1-.26-.69c1.93-.27,3.9-.51,5.86-.71l.18.7C71.74,70.67,69.79,70.9,67.89,71.18Zm-4.12-.08.31.68c-1.89.32-3.78.69-5.63,1.09"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M248.29,18.93a18.9,18.9,0,0,0-1.28,1.7c-.33-.13-.49-.2-.81-.35a18.5,18.5,0,0,1,1.33-1.7C247.83,18.73,248,18.8,248.29,18.93Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M246.39,21.7a6.79,6.79,0,0,0-.58,1.54c-.36-.11-.53-.18-.88-.31a7.75,7.75,0,0,1,.62-1.57C245.88,21.51,246.05,21.57,246.39,21.7Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M245.66,24.23a5,5,0,0,0,.15,1.44l-.93-.24a5.26,5.26,0,0,1-.12-1.48Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M246.14,26.6A7.24,7.24,0,0,0,247,28c-.38-.05-.57-.09-1-.16a7.54,7.54,0,0,1-.84-1.42C245.57,26.49,245.76,26.53,246.14,26.6Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M247.79,28.89a17.09,17.09,0,0,0,1.51,1.35l-1-.07a16.2,16.2,0,0,1-1.52-1.41Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M252.6,32.55h-.93c-.76-.48-1.47-1-2.11-1.44l.95,0C251.15,31.61,251.85,32.08,252.6,32.55Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M256.79,35l-.88.09c-.93-.53-1.82-1.05-2.65-1.55l.91,0C255,34,255.87,34.49,256.79,35Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M261.83,37.67l-.82.16c-1.11-.56-2.17-1.13-3.17-1.67l.87-.12C259.69,36.57,260.74,37.12,261.83,37.67Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M267.77,40.5l-.75.25c-1.3-.59-2.55-1.18-3.72-1.76l.79-.2C265.26,39.35,266.49,39.93,267.77,40.5Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M274.85,43.29l-.68.32c-1.64-.54-3.07-1.12-4.45-1.69l.71-.28C271.79,42.2,273.23,42.76,274.85,43.29Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M283.53,45.83l-.54.37c-1.87-.49-3.7-1-5.43-1.52l.63-.34C279.89,44.85,281.69,45.35,283.53,45.83Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M293.21,48.05l-.37.41c-2-.41-4.07-.86-6-1.32l.47-.38C289.21,47.21,291.21,47.64,293.21,48.05Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M303.56,49.86c-.08.19-.11.28-.18.47-2.14-.33-4.29-.69-6.39-1.07l.3-.43C299.34,49.2,301.45,49.55,303.56,49.86Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M314.17,51.18c0,.2,0,.3,0,.5-2.15-.21-4.34-.46-6.5-.74l.1-.48C309.91,50.73,312.06,51,314.17,51.18Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M323.33,51.89l.19.52c-1.81-.1-3.56-.22-5.2-.36l-.1-.52C319.83,51.67,321.56,51.8,323.33,51.89Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M332.71,52.08l.37.51c-2,0-3.95,0-5.85,0L327,52C328.83,52.08,330.77,52.1,332.71,52.08Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M342.62,51.6l.58.5c-2,.18-4.08.31-6.1.39l-.45-.51C338.62,51.9,340.64,51.77,342.62,51.6Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M352.36,50.26l.77.49c-1.92.37-3.91.68-5.9.93l-.66-.51C348.52,50.93,350.47,50.62,352.36,50.26Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M356.12,49.43l.85.48c.9-.22,1.81-.46,2.69-.72l-.9-.47C357.9,49,357,49.21,356.12,49.43Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M40.34,88c26-8.12,55.4-10.16,79.66,4,9.33,5.43,21.86,14.65,20.07,27-1,6.67-7.35,13-12.12,17.36-5.44,5-12.17,8.34-18.56,12a.59.59,0,0,0,.6,1c7-4,14.24-7.62,20-13.34,4.22-4.18,9.49-9.61,11-15.58,2.89-11.23-7.27-20.43-15.35-26.17-25-17.78-57.56-16.16-85.67-7.38-.73.23-.42,1.37.31,1.15Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-6"
                d="M270.44,18c-2.63.82-6.8,2.7-7.15,5.14-.38,2.6.28,4.37,3.92,6.39,2.64,1.47,13.28,5.52,18.06,6.83,6.4,1.76,19.36,5.49,28.29,6.37,10.45,1,18.82,1,24.64.18a83.27,83.27,0,0,0,14.22-2.84"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M128.51,90.13c2,1.38,4,2.7,6,4.07.63.44,1.23-.59.6-1-2-1.37-4-2.69-6-4.06-.63-.44-1.22.59-.6,1Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M278.05,37.68a56.76,56.76,0,0,0,5.73,1.79.3.3,0,0,0,.16-.57,56.76,56.76,0,0,1-5.73-1.79c-.36-.15-.51.42-.16.57Z"
                transform="translate(0 -8.01)"
              />
              <path
                className="cls-f-and-h-5"
                d="M219,61.68a1.49,1.49,0,0,0,0-3,1.49,1.49,0,0,0,0,3Z"
                transform="translate(0 -8.01)"
              />
            </g>
            {goals && (
              <g id="Golovi">
                <polygon
                  className="cls-f-and-h-7"
                  points="47.22 104.88 60.68 117.85 76.73 111.89 72.81 92.74 58.25 81.27 48.06 84.76 47.22 104.88"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="71.32"
                  y1="93.28"
                  x2="56.87"
                  y2="81.75"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="69.85"
                  y1="93.91"
                  x2="55.34"
                  y2="82.27"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="68.34"
                  y1="94.36"
                  x2="53.96"
                  y2="82.74"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="66.73"
                  y1="94.95"
                  x2="52.5"
                  y2="83.16"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="65.22"
                  y1="95.5"
                  x2="51.13"
                  y2="83.71"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="63.5"
                  y1="96.12"
                  x2="49.66"
                  y2="84.21"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="59.61"
                  y1="82.34"
                  x2="49.38"
                  y2="85.92"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="61.07"
                  y1="83.58"
                  x2="50.88"
                  y2="87.06"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="62.66"
                  y1="84.75"
                  x2="52.15"
                  y2="88.35"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="64.15"
                  y1="85.93"
                  x2="53.53"
                  y2="89.56"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="65.68"
                  y1="87.13"
                  x2="54.91"
                  y2="90.91"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="67.19"
                  y1="88.32"
                  x2="56.46"
                  y2="92.13"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="68.59"
                  y1="89.42"
                  x2="57.8"
                  y2="93.31"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="70.02"
                  y1="90.55"
                  x2="59.19"
                  y2="94.53"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="71.51"
                  y1="91.73"
                  x2="60.62"
                  y2="95.78"
                />
                <polyline
                  className="cls-f-and-h-7"
                  points="48.06 84.76 61.73 96.76 72.81 92.74"
                />
                <line
                  className="cls-f-and-h-7"
                  x1="60.68"
                  y1="117.85"
                  x2="61.73"
                  y2="96.76"
                />
                <polyline
                  className="cls-f-and-h-7"
                  points="58.25 81.27 62.75 99.56 47.22 104.88"
                />
                <line
                  className="cls-f-and-h-9"
                  x1="47.35"
                  y1="102.74"
                  x2="60.8"
                  y2="115.71"
                />
                <line
                  className="cls-f-and-h-9"
                  x1="47.4"
                  y1="100.65"
                  x2="60.9"
                  y2="113.67"
                />
                <line
                  className="cls-f-and-h-9"
                  x1="47.55"
                  y1="98.65"
                  x2="61"
                  y2="111.45"
                />
                <line
                  className="cls-f-and-h-9"
                  x1="47.65"
                  y1="96.61"
                  x2="61.11"
                  y2="109.37"
                />
                <line
                  className="cls-f-and-h-9"
                  x1="47.66"
                  y1="94.47"
                  x2="61.11"
                  y2="106.81"
                />
                <line
                  className="cls-f-and-h-9"
                  x1="47.75"
                  y1="92.18"
                  x2="61.33"
                  y2="104.82"
                />
                <line
                  className="cls-f-and-h-9"
                  x1="47.83"
                  y1="90.25"
                  x2="61.42"
                  y2="102.39"
                />
                <line
                  className="cls-f-and-h-9"
                  x1="48.06"
                  y1="88.32"
                  x2="61.56"
                  y2="100.41"
                />
                <line
                  className="cls-f-and-h-9"
                  x1="48.06"
                  y1="86.45"
                  x2="61.73"
                  y2="98.65"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="73.21"
                  y1="94.47"
                  x2="61.63"
                  y2="98.77"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="73.59"
                  y1="96.39"
                  x2="61.63"
                  y2="100.83"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="73.97"
                  y1="98.3"
                  x2="61.42"
                  y2="102.96"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="74.36"
                  y1="100.21"
                  x2="61.42"
                  y2="105.02"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="74.76"
                  y1="102.21"
                  x2="61.21"
                  y2="107.24"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="75.14"
                  y1="104.13"
                  x2="61.11"
                  y2="109.34"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="75.52"
                  y1="106.04"
                  x2="61.01"
                  y2="111.43"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="75.91"
                  y1="107.96"
                  x2="60.9"
                  y2="113.53"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="76.29"
                  y1="109.87"
                  x2="60.8"
                  y2="115.62"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="48.37"
                  y1="105.99"
                  x2="49.38"
                  y2="85.92"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="55.26"
                  y1="112.63"
                  x2="56.3"
                  y2="91.99"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="53.88"
                  y1="111.3"
                  x2="54.91"
                  y2="90.77"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="49.75"
                  y1="107.31"
                  x2="50.76"
                  y2="87.13"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="51.13"
                  y1="108.64"
                  x2="52.15"
                  y2="88.35"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="52.5"
                  y1="109.97"
                  x2="53.53"
                  y2="89.56"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="56.64"
                  y1="113.95"
                  x2="57.69"
                  y2="93.08"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="58.02"
                  y1="115.28"
                  x2="59.07"
                  y2="94.36"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="59.39"
                  y1="116.61"
                  x2="60.45"
                  y2="95.63"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="62.45"
                  y1="117.19"
                  x2="63.08"
                  y2="96.27"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="64.29"
                  y1="116.51"
                  x2="64.44"
                  y2="95.78"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="66.15"
                  y1="115.82"
                  x2="65.77"
                  y2="95.3"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="67.95"
                  y1="115.15"
                  x2="66.98"
                  y2="94.84"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="69.72"
                  y1="114.49"
                  x2="68.2"
                  y2="94.44"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="71.42"
                  y1="113.86"
                  x2="69.3"
                  y2="94.02"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="73.36"
                  y1="113.14"
                  x2="70.43"
                  y2="93.61"
                />
                <line
                  className="cls-f-and-h-10"
                  x1="75.14"
                  y1="112.48"
                  x2="71.58"
                  y2="93.19"
                />
                <polygon
                  className="cls-f-and-h-7"
                  points="299.51 17.41 301.39 5.17 305.04 4.29 322.1 8.29 321.69 20.23 316.33 22.05 316.88 18.63 304.98 15.7 299.51 17.41"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="316.93"
                  y1="17.16"
                  x2="305.04"
                  y2="14.24"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="317.43"
                  y1="15.84"
                  x2="305.04"
                  y2="12.79"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="317.67"
                  y1="14.43"
                  x2="305.04"
                  y2="11.32"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="317.85"
                  y1="13.01"
                  x2="305.04"
                  y2="9.86"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="318.17"
                  y1="11.62"
                  x2="305.04"
                  y2="8.39"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="318.29"
                  y1="10.34"
                  x2="305.11"
                  y2="7.09"
                />
                <polyline
                  className="cls-f-and-h-7"
                  points="316.33 22.05 318.57 9.35 322.1 8.29"
                />
                <line
                  className="cls-f-and-h-7"
                  x1="301.39"
                  y1="5.17"
                  x2="318.57"
                  y2="9.35"
                />
                <line
                  className="cls-f-and-h-7"
                  x1="304.98"
                  y1="15.7"
                  x2="305.04"
                  y2="4.29"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="299.75"
                  y1="15.84"
                  x2="304.98"
                  y2="14.3"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="300"
                  y1="14.25"
                  x2="304.98"
                  y2="12.78"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="300.24"
                  y1="12.68"
                  x2="305.04"
                  y2="11.27"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="300.48"
                  y1="11.1"
                  x2="304.98"
                  y2="9.78"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="300.72"
                  y1="9.56"
                  x2="304.98"
                  y2="8.31"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="300.95"
                  y1="8.06"
                  x2="304.87"
                  y2="6.91"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="301.17"
                  y1="6.65"
                  x2="304.87"
                  y2="5.56"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="316.66"
                  y1="20.49"
                  x2="321.89"
                  y2="18.73"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="316.91"
                  y1="18.78"
                  x2="321.89"
                  y2="17.21"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="317.19"
                  y1="17.21"
                  x2="321.95"
                  y2="15.7"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="317.46"
                  y1="15.63"
                  x2="321.89"
                  y2="14.2"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="317.75"
                  y1="13.99"
                  x2="321.95"
                  y2="12.72"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="318.03"
                  y1="12.44"
                  x2="322"
                  y2="11.27"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="318.29"
                  y1="10.92"
                  x2="322.05"
                  y2="9.81"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="303.19"
                  y1="4.73"
                  x2="320.38"
                  y2="8.82"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="321.21"
                  y1="8.55"
                  x2="320.5"
                  y2="20.49"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="320.27"
                  y1="8.94"
                  x2="319.28"
                  y2="21.01"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="317.85"
                  y1="21.44"
                  x2="319.4"
                  y2="9.28"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="304.18"
                  y1="4.5"
                  x2="303.72"
                  y2="16.09"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="303.19"
                  y1="4.73"
                  x2="302.39"
                  y2="16.61"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="300.96"
                  y1="17.05"
                  x2="302.37"
                  y2="5.02"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="306.25"
                  y1="16.01"
                  x2="306.42"
                  y2="6.39"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="307.56"
                  y1="16.45"
                  x2="307.77"
                  y2="6.78"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="308.89"
                  y1="16.8"
                  x2="309.12"
                  y2="7.09"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="310.21"
                  y1="17.27"
                  x2="310.5"
                  y2="7.38"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="311.6"
                  y1="17.33"
                  x2="311.96"
                  y2="7.74"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="313.08"
                  y1="17.69"
                  x2="313.45"
                  y2="8.1"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="314.4"
                  y1="18.02"
                  x2="314.92"
                  y2="8.46"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="315.86"
                  y1="18.38"
                  x2="316.48"
                  y2="8.84"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="316.85"
                  y1="18.6"
                  x2="317.85"
                  y2="9.17"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="306.33"
                  y1="4.59"
                  x2="303.13"
                  y2="5.59"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="308.13"
                  y1="5.01"
                  x2="304.98"
                  y2="6.12"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="309.98"
                  y1="5.45"
                  x2="307.17"
                  y2="6.57"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="311.78"
                  y1="5.87"
                  x2="309.19"
                  y2="7.06"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="313.45"
                  y1="6.26"
                  x2="311.07"
                  y2="7.52"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="315.07"
                  y1="6.64"
                  x2="312.75"
                  y2="7.93"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="316.63"
                  y1="7"
                  x2="314.53"
                  y2="8.36"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="318.46"
                  y1="7.48"
                  x2="316.08"
                  y2="8.72"
                />
                <line
                  className="cls-f-and-h-8"
                  x1="320.5"
                  y1="7.91"
                  x2="317.35"
                  y2="9.07"
                />
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FootballAndHandball;
