import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";

import Header from "./Header";
import { CourtType } from "../constants/constants";
import { getCourtComponentChooseMode } from "../utils/getCourtComponent";

const ZoomInStackItem = styled(Stack)`
  &:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    cursor: pointer;
  }
`;

const courtTypes = [
  CourtType.BasketballWhole,
  CourtType.BasketballHalf,
  CourtType.Volleyball,
  CourtType.Tennis,
  CourtType.FootballAndHandball,
  CourtType.BasketballAndVolleyball,
  CourtType.FootballBasketballVolleyball,
  CourtType.BasketballAndTennis,
  CourtType.FootballHandballVolleyballTennis,
];

const ChooseCourt = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleItemClick = (i: CourtType) => {
    navigate(`/EditCourt/${i}`);
  };

  const handleBtnClick = () => {
    window.location.href = "https://boma-court.rs/";
  };

  return (
    <Grid>
      <Header
        title={t("title")}
        buttonText={t("buttons.backToWebsiteBtn")}
        handleBtnClick={handleBtnClick}
      />
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 0, sm: 10, lg: 25 }}
        justifyContent="space-between"
        alignItems="center"
      >
        {courtTypes.map((courtType) => (
          <Grid item xs={12} sm={6} lg={4} key={courtType}>
            <ZoomInStackItem
              direction="column"
              spacing={2}
              onClick={() => handleItemClick(courtType)}
            >
              {getCourtComponentChooseMode(courtType)}
              <Typography variant="h5" alignSelf="center" textAlign="center">
                {t(courtType)}
              </Typography>
            </ZoomInStackItem>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ChooseCourt;
