import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { SelectedCourtValues } from "../types/types";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import DraftsIcon from "@mui/icons-material/Drafts";
import { ChangeEvent, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import { closeSnackbar, useSnackbar } from "notistack";
import { companyEmail } from "../constants/constants";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (
    finalMessage: string,
    userName: string,
    userEmail: string
  ) => void;
  selectedCourtStates: SelectedCourtValues;
  message: string;
}

const style = {
  bgcolor: "background.paper",
  border: "4px solid",
  borderColor: "primary.main",
  boxShadow: 24,
  p: 4,
};

const ReadOnlyTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root.Mui-disabled": {
    "& > fieldset": {
      borderColor: `${theme.palette.primary.main}`,
    },
  },
  "& .MuiInputBase-root": {
    borderRadius: "50px",
    borderColor: `${theme.palette.primary.main} !important`,
    textAlign: "center",
    pointerEvents: "none",
    "& input": {
      textAlign: "center",
    },
  },
}));

const ModalEmail = ({
  open,
  handleClose,
  selectedCourtStates,
  message,
  handleSubmit,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [emailMessage, setEmailMessage] = useState(message);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setEmailMessage(message);
  }, [message]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newMessage = event.target.value;
    setEmailMessage(newMessage);
  };

  const handleUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const handleUserEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserEmail(event.target.value);
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!emailPattern.test(event.target.value)) {
      setErrorEmail(true);
    } else {
      setErrorEmail(false);
    }
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();

    if (!userName || !userEmail || errorEmail) {
      enqueueSnackbar(`${t("validationError")}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        style: { fontSize: "20px" },
        action: (key) => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
      return;
    }

    handleSubmit(emailMessage, userName, userEmail);

    setUserName("");
    setUserEmail("");
  };

  return (
    <Dialog
      fullScreen={isMobile ? true : false}
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { ...style } }}
    >
      <form onSubmit={handleSubmitForm} noValidate>
        <Stack
          direction="column"
          justifyContent="center"
          spacing={1}
          mt={{ xs: 4, sm: 0 }}
          mb={{ xs: 4, sm: 0 }}
          alignItems="center"
        >
          <Typography variant="h4" fontWeight="700" textAlign="center">
            {t("courtRequest")}
          </Typography>
          <DialogContent>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, lg: 2 }}
              alignItems="flex-end"
              justifyContent="center"
            >
              <Grid item xs={12} sm={12} lg={6} order={{ xs: 1, lg: 1 }}>
                <TextField
                  fullWidth
                  name="name"
                  variant="outlined"
                  size="small"
                  placeholder={`${t("userName")}*`}
                  value={userName}
                  onChange={handleUserNameChange}
                  required
                  InputProps={{
                    style: {
                      borderRadius: "50px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={6} order={{ xs: 3, lg: 2 }}>
                <Stack>
                  <Typography
                    variant="overline"
                    fontWeight={700}
                    ml={1}
                    textAlign="left"
                    color={theme.palette.primary.main}
                  >
                    {`${t("for")}:`}
                  </Typography>
                  <ReadOnlyTextField
                    fullWidth
                    name="email-office"
                    variant="outlined"
                    size="small"
                    value={companyEmail}
                    disabled
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} lg={6} order={{ xs: 2, lg: 3 }}>
                <TextField
                  sx={{ marginTop: { xs: 2, lg: 0 } }}
                  fullWidth
                  name="email-user"
                  variant="outlined"
                  placeholder={`${t("userEmail")}*`}
                  size="small"
                  type="email"
                  required
                  value={userEmail}
                  onChange={handleUserEmailChange}
                  InputProps={{
                    style: {
                      borderRadius: "50px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={6} order={{ xs: 4, lg: 4 }}>
                <Stack>
                  <Typography
                    variant="overline"
                    ml={1}
                    fontWeight={700}
                    textAlign="left"
                    color={theme.palette.primary.main}
                  >
                    {`${t("court")}:`}
                  </Typography>
                  <ReadOnlyTextField
                    fullWidth
                    name="court-name"
                    variant="outlined"
                    size="small"
                    disabled
                    value={t(selectedCourtStates.name)}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} order={{ xs: 5, lg: 5 }}>
                <Typography
                  variant="overline"
                  fontWeight={700}
                  ml={1}
                  textAlign="left"
                >
                  {`${t("message")}:`}
                </Typography>
                <TextField
                  fullWidth
                  name="email-user"
                  variant="outlined"
                  multiline
                  defaultValue={message}
                  onChange={handleInputChange}
                  rows={10}
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: "20px",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Stack direction="row" spacing={2}>
            <Button
              color="primary"
              disabled={false}
              variant="contained"
              size="large"
              onClick={handleClose}
              startIcon={<CloseIcon />}
              sx={{
                borderRadius: "50px",
              }}
            >
              {t("buttons.cancel")}
            </Button>
            <Button
              color="primary"
              disabled={false}
              variant="contained"
              size="large"
              type="submit"
              startIcon={<DraftsIcon />}
              sx={{
                borderRadius: "50px",
              }}
            >
              {t("buttons.send")}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

export default ModalEmail;
