import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { useState, ChangeEvent, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DraftsIcon from "@mui/icons-material/Drafts";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import { closeSnackbar, useSnackbar } from "notistack";

import Header from "./Header";
import CourtParams from "../components/CourtParams";
import { defaultValues } from "../utils/courtConfiguration";
import { getCourtComponentEditMode } from "../utils/getCourtComponent";
import { SelectedCourtValues } from "../types/types";
import html2canvas from "html2canvas";
import ModalEmail from "../components/ModalEmail";
import makeMessage from "../utils/makeMessage";
import { handleSendEmail } from "../api/api";
import { ModelCourt } from "../constants/constants";

const EditCourt = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false); //Modal Open
  const [isSecondPage, setIsSecondPage] = useState<boolean>(false);

  const elementToCaptureRef = useRef<HTMLDivElement>(null);
  const [screenshot, setScreenshot] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [selectedCourtStates, setSelectedCourtStates] =
    useState<SelectedCourtValues>({
      name: "",
      width: "",
      depth: "",
      outside: true,
      model: ModelCourt.boma,
      colors: {},
      extras: {},
    });

  const handleOpen = () => {
    setOpen(true);
    captureScreenshot();
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const selectedCourt = defaultValues.find((item) => item.name === id);

    if (!selectedCourt) {
      navigate("/404");
      return;
    }

    setSelectedCourtStates(selectedCourt);
  }, [id, navigate]);

  useEffect(() => {
    setMessage(makeMessage(selectedCourtStates));
  }, [selectedCourtStates, i18n.language]);

  if (selectedCourtStates === null) {
    return null;
  }

  const handleBtnClick = () => {
    navigate(`/`);
  };

  const handleCourtDepthChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedCourtStates({
      ...selectedCourtStates,
      depth: event.target.value,
    });
  };

  const handleCourtWidthChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedCourtStates({
      ...selectedCourtStates,
      width: event.target.value,
    });
  };

  const captureScreenshot = () => {
    if (elementToCaptureRef.current) {
      html2canvas(elementToCaptureRef.current).then((canvas) => {
        const dataUrl = canvas.toDataURL();
        setScreenshot(dataUrl);
      });
    }
  };

  const saveScreenshot = () => {
    if (elementToCaptureRef.current) {
      html2canvas(elementToCaptureRef.current).then((canvas) => {
        // Convert the canvas to a data URL
        const dataURL = canvas.toDataURL("image/png");

        // Create a link element to download the image
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = `${t(selectedCourtStates.name)}.png`;

        // Simulate a click on the link to trigger the download
        link.click();
      });
    }
  };

  const handleSubmit = async (
    finalMessage: string,
    userName: string,
    userEmail: string
  ) => {
    const success = await handleSendEmail(
      finalMessage,
      userName,
      userEmail,
      screenshot as string
    );

    if (success) {
      enqueueSnackbar(t("emailNotification"), {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        style: { fontSize: "20px" },
        action: (key) => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    } else {
      enqueueSnackbar(t("errorEmail"), {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        style: { fontSize: "20px" },
        action: (key) => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    }
    handleClose();
  };

  return (
    <Grid>
      <Header
        title={t(selectedCourtStates.name)}
        buttonText={t("buttons.back")}
        handleBtnClick={handleBtnClick}
      />
      <Box ref={elementToCaptureRef}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={12} lg={6} p={2}>
            {selectedCourtStates &&
              getCourtComponentEditMode(selectedCourtStates)}
          </Grid>
          {!isMobile && (
            <Grid item sm={12} lg={6}>
              <Stack spacing={3} alignItems={{ sm: "end", lg: "center" }}>
                <Stack>
                  <Typography variant="h6" ml={1} textAlign="left">
                    {`${t("depth")} (${t("inMeters")})`}
                  </Typography>
                  <TextField
                    id="depth"
                    type="number"
                    variant="outlined"
                    onChange={handleCourtDepthChange}
                    fullWidth
                    style={{
                      width: "300px",
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "50px",
                      },
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography variant="h6" ml={1} textAlign="left">
                    {`${t("width")} (${t("inMeters")})`}
                  </Typography>
                  <TextField
                    id="width"
                    type="number"
                    variant="outlined"
                    onChange={handleCourtWidthChange}
                    fullWidth
                    style={{
                      width: "300px",
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "50px",
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
        <CourtParams
          setSelectedCourtStates={setSelectedCourtStates}
          selectedCourtStates={selectedCourtStates}
          setIsSecondPage={setIsSecondPage}
          isSecondPage={isSecondPage}
        />
        {isMobile && isSecondPage && (
          <>
            <Grid item xs={12}>
              <Stack mt={5} spacing={3} alignItems="center">
                <Stack>
                  <Typography variant="h6" ml={1} textAlign="left">
                    {`${t("depth")} (${t("inMeters")})`}
                  </Typography>
                  <TextField
                    id="depth"
                    type="number"
                    variant="outlined"
                    onChange={handleCourtDepthChange}
                    fullWidth
                    style={{
                      width: "300px",
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "50px",
                      },
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography variant="h6" ml={1} textAlign="left">
                    {`${t("width")} (${t("inMeters")})`}
                  </Typography>
                  <TextField
                    id="width"
                    type="number"
                    variant="outlined"
                    onChange={handleCourtWidthChange}
                    fullWidth
                    style={{
                      width: "300px",
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "50px",
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Stack mt={3} alignItems="center">
              <Button
                color="primary"
                disabled={false}
                variant="contained"
                size="medium"
                startIcon={<ArrowBackIosNewIcon />}
                onClick={() => setIsSecondPage(false)}
                sx={{
                  borderRadius: "50px",
                }}
              >
                {t("buttons.back")}
              </Button>
            </Stack>
          </>
        )}
      </Box>
      <Stack
        direction="row"
        justifyContent={{ xs: "center", sm: "center", lg: "flex-end" }}
        spacing={2}
        alignItems="center"
        mt={{ xs: 5, sm: 5, lg: 2 }}
      >
        <Button
          color="primary"
          disabled={false}
          variant="outlined"
          size="large"
          startIcon={<PhotoCameraIcon />}
          onClick={saveScreenshot}
          sx={{
            borderRadius: "50px",
          }}
        >
          {t("buttons.screenshot")}
        </Button>
        <Button
          color="primary"
          disabled={false}
          variant="contained"
          size="large"
          startIcon={<DraftsIcon />}
          onClick={handleOpen}
          sx={{
            borderRadius: "50px",
          }}
        >
          {t("buttons.send")}
        </Button>
      </Stack>
      <ModalEmail
        open={open}
        handleClose={handleClose}
        selectedCourtStates={selectedCourtStates}
        message={message}
        handleSubmit={handleSubmit}
      />
    </Grid>
  );
};

export default EditCourt;
