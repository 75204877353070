export const formatColor = (color: string): string => {
  // Define a mapping from hex values to RAL values
  const colorMap: Record<string, string> = {
    "#d2aa6d": "RAL1002",
    "#af804f": "RAL1011",
    "#fbca2f": "RAL1018",
    "#ec681a": "RAL2008",
    "#b81b0e": "RAL3020",
    "#9d8692": "RAL4009",
    "#004f7c": "RAL5010",
    "#0079ab": "RAL5015",
    "#222d5a": "RAL5022",
    "#325928": "RAL6002",
    "#0c3f2f": "RAL6005",
    "#609839": "RAL6018",
    "#353c40": "RAL7016",
    "#989ea1": "RAL7040",
    "#0a0c10": "RAL9005",
    "#ffffff": "RAL9016",
  };

  if (colorMap[color]) {
    return `${colorMap[color]} (${color})`;
  } else {
    return color; // If not in the map, return the original color
  }
};
