import { ColorValues } from "../constants/constants";

interface Props {
  lineColor?: ColorValues;
  fieldColor?: ColorValues;
  keyColor?: ColorValues;
  aroundColor?: ColorValues;
  baskets?: boolean;
}

const BasketballHalf = ({
  lineColor = ColorValues.RAL9016,
  aroundColor = ColorValues.RAL9005,
  fieldColor = ColorValues.RAL7040,
  keyColor = ColorValues.RAL7016,
  baskets = true,
}: Props) => {
  return (
    <svg
      id="basketballHalf"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 236.05 118.39"
      style={{
        width: "100%", // Make the SVG responsive
        height: "auto", // Let the height adjust proportionally
      }}
    >
      <defs>
        <style>
          {`
                .cls-half-1{fill:#6b6b6b;}
                .cls-half-2{fill:#7f7f7f;}
                .cls-half-3{ fill: ${aroundColor};}
                .cls-half-4{fill:${fieldColor};}
                .cls-half-5{fill:${keyColor};}
                .cls-half-6{fill:${lineColor};}
                .cls-half-7{fill:#0f0f0f;}
                .cls-half-8{fill:#8e8e8e;}
                .cls-half-9{fill:#b2b2b2;}
                .cls-half-10{fill:#dbdbdb;}
                .cls-half-11{fill:#e2e2e2;}
                .cls-half-12{fill:#d6403c;}
                .cls-half-13{fill:#ffffff;}
          `}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Kosarka_pola_terena_" data-name="Kosarka (pola terena)">
            <g id="Sivo">
              <polygon
                className="cls-half-1"
                points="90.62 112.13 90.62 118.39 236.05 75.41 236.05 69.15 90.62 112.13"
              />
              <polygon
                className="cls-half-2"
                points="0 36.49 0 42.75 90.62 118.39 90.62 112.13 0 36.49"
              />
            </g>
            <polygon
              id="Obod"
              className="cls-half-3"
              points="0 36.49 90.62 112.13 236.05 69.15 115.9 16.92 0 36.49"
            />
            <polygon
              id="Podloga"
              className="cls-half-4"
              points="19.05 38.96 92.83 97.55 206.35 66.25 111.78 22.74 19.05 38.96"
            />
            <polygon
              id="Reket"
              className="cls-half-5"
              points="119.34 56.51 99.05 44.95 141.25 36.2 163.61 46.59 119.34 56.51"
            />
            <g id="Linija">
              <path
                className="cls-half-6"
                d="M18,38.9l94-16.62,95.66,44.08-115,31.82ZM93,97.05,205.25,66.24l-93.43-43-91.58,16"
              />
              <path
                className="cls-half-6"
                d="M98.69,44.72l.76.52c-6.55,1.53-10.34,4.84-6.36,7.62s18.39,5.65,25.64,3.47l.61.55c-8.3,2.77-22.63-.69-26.8-3.75S91,46.28,98.69,44.72Z"
              />
              <polygon
                className="cls-half-6"
                points="98.29 44.96 99 44.8 119.83 56.55 119.13 56.88 98.29 44.96"
              />
              <polygon
                className="cls-half-6"
                points="118.45 56.41 162.57 46.37 163.09 46.95 119.12 56.95 118.45 56.41"
              />
              <polygon
                className="cls-half-6"
                points="98.43 44.77 140.52 36.14 141.27 36.66 99.18 45.3 98.43 44.77"
              />
              <path
                className="cls-half-6"
                d="M116.47,25.23l.85.38c-12.55,1.83-50.72,9.65-21.93,28.66,20.42,13.48,77.55,17.12,100.48,7.34l.81.51c-22.06,10.24-81,6.75-102.14-7.41C66.4,35.88,101.93,27.13,116.47,25.23Z"
              />
            </g>
            {baskets && (
              <g id="Kosevi">
                <path
                  className="cls-half-7"
                  d="M153.08,38.31h1.33V9.23s-5.59-2.4-6.57-2.74V8c1.5.58,3.42,1.46,5.24,2.25Z"
                />
                <path
                  className="cls-half-7"
                  d="M154.41,38.38c0,.22-.29.4-.66.4s-.67-.18-.67-.4.3-.4.67-.4S154.41,38.16,154.41,38.38Z"
                />
                <polygon
                  className="cls-half-8"
                  points="143.16 10.65 153.53 16.63 153.53 6.04 143.16 0.8 143.16 10.65"
                />
                <polygon
                  className="cls-half-9"
                  points="142.52 10.95 152.89 16.93 152.89 6.34 142.52 0.37 142.52 10.95"
                />
                <polygon
                  className="cls-half-8"
                  points="152.89 16.93 153.53 16.63 153.53 5.97 152.89 6.34 152.89 16.93"
                />
                <polygon
                  className="cls-half-10"
                  points="142.52 0.37 143.16 0 153.53 5.97 152.89 6.34 142.52 0.37"
                />
                <path
                  className="cls-half-13"
                  d="M150.4,13,145,9.86V4.33l5.39,3.11Zm-5.18-3.22,5,2.87v-5l-5-2.87Z"
                />
                <path
                  className="cls-half-13"
                  d="M152.43,15.89l-9.37-5.4V1.41l9.37,5.4Zm-9.17-5.52,9,5.16V6.93l-9-5.16Z"
                />
                <rect
                  className="cls-half-11"
                  x="145.18"
                  y="10.47"
                  width="0.34"
                  height="3.66"
                />
                <rect
                  className="cls-half-11"
                  x="143.56"
                  y="10.8"
                  width="0.34"
                  height="3.71"
                  transform="matrix(0.99, -0.14, 0.14, 0.99, -0.32, 20.92)"
                />
                <rect
                  className="cls-half-11"
                  x="145.11"
                  y="12.61"
                  width="3.72"
                  height="0.34"
                  transform="translate(113.07 156.36) rotate(-81.68)"
                />
                <path
                  className="cls-half-11"
                  d="M145.35,17.54c-1.48,0-2.63-.75-2.63-1.7s1.15-1.71,2.63-1.71,2.63.75,2.63,1.71S146.82,17.54,145.35,17.54Zm0-3.07c-1.28,0-2.29.6-2.29,1.37s1,1.36,2.29,1.36,2.29-.6,2.29-1.36S146.63,14.47,145.35,14.47Z"
                />
                <path
                  className="cls-half-11"
                  d="M145.34,16.38c-1.61,0-2.87-.81-2.87-1.86s1.26-1.86,2.87-1.86,2.87.82,2.87,1.86S147,16.38,145.34,16.38Zm0-3.34c-1.4,0-2.49.65-2.49,1.48S143.94,16,145.34,16s2.5-.65,2.5-1.49S146.74,13,145.34,13Z"
                />
                <path
                  className="cls-half-11"
                  d="M145.34,15.27c-1.7,0-3-.86-3-2s1.34-2,3-2,3,.86,3,2S147.05,15.27,145.34,15.27Zm0-3.54c-1.48,0-2.64.69-2.64,1.57s1.16,1.57,2.64,1.57S148,14.18,148,13.3,146.82,11.73,145.34,11.73Z"
                />
                <rect
                  className="cls-half-11"
                  x="142.41"
                  y="12.51"
                  width="0.34"
                  height="3.35"
                  transform="translate(-0.2 26.24) rotate(-10.51)"
                />
                <path
                  className="cls-half-11"
                  d="M148,15.87l-.34-.06c.25-1.33.62-3.34.63-3.47h.35C148.62,12.44,148.27,14.32,148,15.87Z"
                />
                <polygon
                  className="cls-half-11"
                  points="143.85 17.1 143.62 13.98 143.97 13.96 144.19 17.08 143.85 17.1"
                />
                <rect
                  className="cls-half-11"
                  x="145.29"
                  y="15.27"
                  width="3.13"
                  height="0.34"
                  transform="translate(120.81 160.79) rotate(-85.84)"
                />
                <rect
                  className="cls-half-11"
                  x="145.18"
                  y="14.3"
                  width="0.34"
                  height="3.07"
                />
                <path
                  className="cls-half-12"
                  d="M145.35,14.41c-1.85,0-3.3-.9-3.3-2.06s1.45-2.06,3.3-2.06,3.3.91,3.3,2.06S147.2,14.41,145.35,14.41Zm0-3.71c-1.57,0-2.9.75-2.9,1.65s1.33,1.66,2.9,1.66,2.9-.76,2.9-1.66S146.92,10.7,145.35,10.7Z"
                />
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BasketballHalf;
