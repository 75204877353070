import BasketballWhole from "../courtsSVG/BasketballWhole";
import BasketballHalf from "../courtsSVG/BasketballHalf";
import Volleyball from "../courtsSVG/Volleyball";
import { CourtType } from "../constants/constants";
import Tennis from "../courtsSVG/Tennis";
import FootballAndHandball from "../courtsSVG/FootballAndHandball";
import BasketballAndVolleyball from "../courtsSVG/BasketballAndVolleyball";
import FootballBasketballVolleyball from "../courtsSVG/FBV";
import BasketballAndTennis from "../courtsSVG/BasketballAndTennis";
import FootballHandballVolleyballTennis from "../courtsSVG/FHVT";
import { SelectedCourtValues } from "../types/types";

const courtComponents = {
  [CourtType.BasketballWhole]: BasketballWhole,
  [CourtType.BasketballHalf]: BasketballHalf,
  [CourtType.Volleyball]: Volleyball,
  [CourtType.Tennis]: Tennis,
  [CourtType.FootballAndHandball]: FootballAndHandball,
  [CourtType.BasketballAndVolleyball]: BasketballAndVolleyball,
  [CourtType.FootballBasketballVolleyball]: FootballBasketballVolleyball,
  [CourtType.BasketballAndTennis]: BasketballAndTennis,
  [CourtType.FootballHandballVolleyballTennis]:
    FootballHandballVolleyballTennis,
};

export const getCourtComponentEditMode = (
  selectedCourt: SelectedCourtValues
) => {
  if (!selectedCourt.name || !selectedCourt.colors || !selectedCourt.extras)
    return <></>;

  const CourtComponent = courtComponents[selectedCourt.name];

  return <CourtComponent {...selectedCourt.colors} {...selectedCourt.extras} />;
};

export const getCourtComponentChooseMode = (courtType: CourtType) => {
  const CourtComponent = courtComponents[courtType];
  return <CourtComponent />;
};
