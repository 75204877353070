import { initReactI18next } from "react-i18next";
import i18n from "i18next";

export const changeLanguage = (language: string) => {
  i18n.changeLanguage(language);
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require("./en.json"),
    },
    sr: {
      translation: require("./sr.json"),
    },
  },
  lng: "sr",
  fallbackLng: "sr",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
