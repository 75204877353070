import { ColorValues } from "../constants/constants";

interface Props {
  lineColor?: ColorValues;
  fieldColor?: ColorValues;
  attackZone?: ColorValues;
  aroundColor?: ColorValues;
  keyColor?: ColorValues;
  baskets?: boolean;
  volleyballNet?: boolean;
  goals?: boolean;
}

const FootballBasketballVolleyball = ({
  lineColor = ColorValues.RAL9016,
  aroundColor = ColorValues.RAL1018,
  fieldColor = ColorValues.RAL5015,
  attackZone = ColorValues.RAL2008,
  keyColor = ColorValues.RAL1018,
  baskets = true,
  volleyballNet = true,
  goals = true,
}: Props) => {
  return (
    <svg
      id="football-basketball-volleyball"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 395.97 206.8"
      style={{
        width: "100%", // Make the SVG responsive
        height: "auto", // Let the height adjust proportionally
      }}
    >
      <defs>
        <style>
          {`
         .cls-fbv-1{fill:#6b6b6b;}
         .cls-fbv-2{fill:#7f7f7f;}
         .cls-fbv-3{fill:${aroundColor};}
         .cls-fbv-4{fill:${fieldColor};}
         .cls-fbv-5{fill:${attackZone};}
         .cls-fbv-6{fill:${lineColor};}
         .cls-fbv-7,.cls-fbv-8,.cls-fbv-9,.cls-fbv-10,.cls-fbv-11,.cls-fbv-12,.cls-fbv-13{stroke:${lineColor};stroke-miterlimit:10;}
         .cls-fbv-10,.cls-fbv-11,.cls-fbv-12,.cls-fbv-13,.cls-fbv-21,.cls-fbv-7,.cls-fbv-8,.cls-fbv-9{fill:none;}
         .cls-fbv-21,.cls-fbv-22,.cls-fbv-23{stroke:#fff;stroke-miterlimit:10;}
         .cls-fbv-7,.cls-fbv-8{stroke-width:0.75px;}
         .cls-fbv-10,.cls-fbv-12,.cls-fbv-8{stroke-linecap:round;}
         .cls-fbv-10,.cls-fbv-9{stroke-width:0.5px;}
         .cls-fbv-13{stroke-width:0.65px;}
         .cls-fbv-14{fill:#0f0f0f;}
         .cls-fbv-15{fill:#353535;}
         .cls-fbv-16{fill:#e2e2e2;}
         .cls-fbv-17{fill:#8e8e8e;}
         .cls-fbv-18{fill:#b2b2b2;}
         .cls-fbv-19{fill:#dbdbdb;}
         .cls-fbv-20{fill:#d6403c;}
         .cls-fbv-21,.cls-fbv-22,.cls-fbv-23{stroke-width:0.25px;}
         .cls-fbv-22{fill:#d86161;}
         .cls-fbv-23{fill:#170900;}
         .cls-fbv-24{fill:${keyColor};}
         .cls-goal-2,.cls-goal-3{fill:none;}.cls-goal-2,.cls-goal-3,.cls-goal-4,.cls-goal-5{stroke:#fff;stroke-miterlimit:10;}.cls-goal-2{stroke-width:0.75px;}.cls-goal-3,.cls-goal-4,.cls-goal-5{stroke-width:0.25px;}.cls-goal-4{fill:#d86161;}.cls-goal-5{fill:#170900;}
          `}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g
            id="Fudbal_Kosarka_Odbojka"
            data-name="Fudbal, Kosarka &amp; Odbojka"
          >
            <g id="Sivo">
              <polygon
                className="cls-fbv-1"
                points="104.67 201.54 104.67 206.8 395.97 59.98 395.97 54.71 104.67 201.54"
              />
              <polygon
                className="cls-fbv-2"
                points="0 95.49 0 100.76 104.67 206.8 104.67 201.54 0 95.49"
              />
            </g>
            <polygon
              id="Obod"
              className="cls-fbv-3"
              points="0 95.49 104.67 201.54 395.97 54.71 266.32 21.3 0 95.49"
            />
            <polygon
              id="Podloga"
              className="cls-fbv-4"
              points="31.9 93.44 126.51 177.5 369.79 58.01 260.16 28.58 31.9 93.44"
            />
            <g id="Reket">
              <polygon
                className="cls-fbv-24"
                points="175.36 153.45 139.38 127 177.39 111.06 215.77 133.69 175.36 153.45"
              />
              <polygon
                className="cls-fbv-24"
                points="75.14 80.97 98.39 97.92 135.04 85.62 110.55 71.09 75.14 80.97"
              />
              <polygon
                className="cls-fbv-24"
                points="223.95 38.68 245.86 32.56 269.37 40.6 248.51 47.74 223.95 38.68"
              />
              <polygon
                className="cls-fbv-24"
                points="331.46 76.86 293.59 63.43 314.7 54.92 351.52 66.92 331.46 76.86"
              />
            </g>
            <polygon
              id="Smec"
              className="cls-fbv-5"
              points="180.28 70.44 228.01 92.58 262.69 78.71 214.38 59.75 180.28 70.44"
            />
            <g id="Linija">
              <path
                className="cls-fbv-6"
                d="M188.19,108.75l-48.5-25.68L244.18,49.78l49.41,17.43ZM141.85,83.24l46.32,24.31L291.35,67.1,244,50.71Z"
              />
              <polygon
                className="cls-fbv-6"
                points="245.52 85.74 197.09 65.16 198.47 64.87 246.58 85.34 245.52 85.74"
              />
              <polygon
                className="cls-fbv-6"
                points="262.28 78.83 213.56 59.92 214.94 59.63 263.29 78.54 262.28 78.83"
              />
              <polygon
                className="cls-fbv-6"
                points="227.63 92.68 179.56 70.78 180.94 70.48 228.65 92.39 227.63 92.68"
              />
              <polygon
                className="cls-fbv-6"
                points="250.97 116.06 143.68 61.68 145.13 61.17 252.19 115.43 250.97 116.06"
              />
              <polygon
                className="cls-fbv-6"
                points="306.29 89.1 198.22 46.39 199.35 45.99 307.32 88.57 306.29 89.1"
              />
              <path
                className="cls-fbv-6"
                d="M126.3,178.39,30.88,93.3,260.19,28.07l111,29.82ZM32.61,93.58l94.2,82.79L368.16,58.14l-108-29.08Z"
              />
              <path
                className="cls-fbv-7"
                d="M315.07,84.78l-23.72-8.62s-20.08-11.23,1-20.89c16.56-7.59,52.33-1.59,52.33-1.59l21,6.44"
              />
              <polyline
                className="cls-fbv-7"
                points="331.51 76.8 293.59 63.43 314.7 54.92 351.76 66.92"
              />
              <path
                className="cls-fbv-7"
                d="M297.3,61.94s-8.79-3.79-1.47-6.11C303.75,53.33,312,56,312,56"
              />
              <path
                className="cls-fbv-8"
                d="M326.7,70.8s-9.31-3.28-4-5.64c5.85-2.62,14.19.7,14.19.7"
              />
              <polygon
                className="cls-fbv-6"
                points="315.32 72.58 317.74 71.62 319.13 72.09 316.83 73 315.32 72.58"
              />
              <polygon
                className="cls-fbv-6"
                points="301.91 67.79 304.32 66.83 305.59 67.35 303.29 68.26 301.91 67.79"
              />
              <polygon
                className="cls-fbv-6"
                points="296.23 65.82 298.64 64.86 299.9 65.38 297.61 66.29 296.23 65.82"
              />
              <polygon
                className="cls-fbv-6"
                points="307.32 69.68 309.62 68.75 313.31 70.08 311.05 71.02 307.32 69.68"
              />
              <polygon
                className="cls-fbv-6"
                points="324.18 58.33 325.84 57.66 326.81 57.98 325.24 58.62 324.18 58.33"
              />
              <polygon
                className="cls-fbv-6"
                points="337.92 62.75 339.57 62.09 340.55 62.41 338.98 63.05 337.92 62.75"
              />
              <polygon
                className="cls-fbv-6"
                points="319.43 56.71 321.08 56.04 322.06 56.37 320.48 57 319.43 56.71"
              />
              <polygon
                className="cls-fbv-6"
                points="329.93 60.14 331.58 59.48 334.33 60.37 332.71 61.05 329.93 60.14"
              />
              <path
                className="cls-fbv-9"
                d="M206.13,44l13.5,4.67s29.7,6.2,48.94-1.73c16.41-6.77,3.91-13.12,3.91-13.12l-15.21-4.47"
              />
              <polyline
                className="cls-fbv-9"
                points="245.45 32.56 269.48 40.56 248.51 47.74 223.78 38.83"
              />
              <path
                className="cls-fbv-9"
                d="M252.57,46.35a23.55,23.55,0,0,0,15,0c5.12-1.79-1.13-4.75-1.13-4.75"
              />
              <path
                className="cls-fbv-10"
                d="M232.66,38.5s7.29,2.84,13,.53c4.41-1.76-2.07-3.77-2.07-3.77"
              />
              <polygon
                className="cls-fbv-6"
                points="240.41 44.72 239.19 45.14 238.71 44.99 239.91 44.55 240.41 44.72"
              />
              <polygon
                className="cls-fbv-6"
                points="230.91 41.24 229.69 41.67 229.2 41.52 230.4 41.08 230.91 41.24"
              />
              <polygon
                className="cls-fbv-6"
                points="244.67 46.24 243.45 46.66 242.96 46.51 244.17 46.08 244.67 46.24"
              />
              <polygon
                className="cls-fbv-6"
                points="236.22 43.21 234.98 43.74 233.07 43.03 234.29 42.49 236.22 43.21"
              />
              <polygon
                className="cls-fbv-6"
                points="266.26 38.91 265.15 39.28 264.71 39.16 265.82 38.78 266.26 38.91"
              />
              <polygon
                className="cls-fbv-6"
                points="262.01 37.51 260.89 37.88 260.46 37.76 261.57 37.38 262.01 37.51"
              />
              <polygon
                className="cls-fbv-6"
                points="252.45 34.3 251.33 34.68 250.9 34.56 252 34.17 252.45 34.3"
              />
              <polygon
                className="cls-fbv-6"
                points="258.19 36.15 256.94 36.6 255.26 36.03 256.49 35.58 258.19 36.15"
              />
              <path
                className="cls-fbv-6"
                d="M214.67,133.62l-39.43,19.25-34.76-25.95,36.93-15.26Zm-76-6.7,36.59,27,41.26-20.3-39.09-23Z"
              />
              <polygon
                className="cls-fbv-6"
                points="146.75 135.07 148.88 134.27 149.66 134.84 147.56 135.68 146.75 135.07"
              />
              <polygon
                className="cls-fbv-6"
                points="141.7 130.75 143.83 129.94 144.61 130.52 142.51 131.35 141.7 130.75"
              />
              <polygon
                className="cls-fbv-6"
                points="159.43 144.1 161.57 143.3 162.65 144.13 160.55 144.96 159.43 144.1"
              />
              <polygon
                className="cls-fbv-6"
                points="151.98 138.14 154.12 137.33 157.25 139.68 155.14 140.51 151.98 138.14"
              />
              <polygon
                className="cls-fbv-6"
                points="187.78 117.18 189.85 116.23 190.67 116.74 188.63 117.72 187.78 117.18"
              />
              <polygon
                className="cls-fbv-6"
                points="182.27 114.02 184.33 113.04 185.16 113.56 183.13 114.55 182.27 114.02"
              />
              <polygon
                className="cls-fbv-6"
                points="200.78 124.98 202.8 124.11 204.15 124.86 201.99 125.77 200.78 124.98"
              />
              <polygon
                className="cls-fbv-6"
                points="192.07 120.19 194.1 119.15 197.47 121.12 195.48 122.19 192.07 120.19"
              />
              <path
                className="cls-fbv-11"
                d="M146,124.2c-5.41-4-.74-9.89,6.13-11.85,8.94-2.55,13.7-1.3,18.92,1.48"
              />
              <path
                className="cls-fbv-12"
                d="M176.7,142s-11-7.5,1.07-12.47c8.32-3.42,18.63,2.86,18.63,2.86"
              />
              <path
                className="cls-fbv-11"
                d="M140.83,170.33l-20.56-17.46s-12.86-24.73,29.4-41.45c34.56-13.68,68.49-2.67,68.49-2.67l23.42,12.11"
              />
              <path
                className="cls-fbv-7"
                d="M46,89.41l12.57,10.8s32.79,11.21,70.32-4.12C160,83.36,148.21,71.5,148.21,71.5l-13.46-7.41"
              />
              <polyline
                className="cls-fbv-7"
                points="75.14 81.16 98.39 97.92 135.04 85.62 110.22 71.09"
              />
              <path
                className="cls-fbv-7"
                d="M105.09,95.49s6.42,5.31,18.77.9c11.18-4,5.41-8.83,5.41-8.83"
              />
              <path
                className="cls-fbv-8"
                d="M88.92,82.56a21,21,0,0,0,15.3,1.18c10.13-3.23,3.47-7,3.47-7"
              />
              <polygon
                className="cls-fbv-6"
                points="91.34 92.8 90.01 93.3 89.52 92.94 90.84 92.42 91.34 92.8"
              />
              <polygon
                className="cls-fbv-6"
                points="94.25 95.17 92.91 95.67 92.42 95.31 93.74 94.79 94.25 95.17"
              />
              <polygon
                className="cls-fbv-6"
                points="83.23 86.93 81.9 87.43 81.22 86.91 82.54 86.39 83.23 86.93"
              />
              <polygon
                className="cls-fbv-6"
                points="87.79 90.52 86.46 91.03 84.5 89.56 85.82 89.04 87.79 90.52"
              />
              <polygon
                className="cls-fbv-6"
                points="128.77 80.98 127.38 81.46 126.86 81.15 128.22 80.66 128.77 80.98"
              />
              <polygon
                className="cls-fbv-6"
                points="132.56 83.17 131.07 83.65 130.54 83.34 132.02 82.85 132.56 83.17"
              />
              <polygon
                className="cls-fbv-6"
                points="119.9 75.71 118.36 76.21 117.63 75.76 119.15 75.25 119.9 75.71"
              />
              <polygon
                className="cls-fbv-6"
                points="124.95 78.72 123.51 79.21 121.41 77.96 122.84 77.45 124.95 78.72"
              />
              <path
                className="cls-fbv-6"
                d="M40.34,101.3c26-8.11,55.4-10.16,79.66,4,9.33,5.43,21.86,14.65,20.07,27-1,6.67-7.35,12.95-12.12,17.36-5.44,5-12.17,8.34-18.56,12a.59.59,0,0,0,.6,1c7-4,14.24-7.62,20-13.34,4.22-4.18,9.49-9.61,11-15.58,2.89-11.23-7.27-20.43-15.35-26.17-25-17.78-57.56-16.16-85.67-7.38-.73.23-.42,1.38.31,1.15Z"
              />
              <ellipse
                className="cls-fbv-6"
                cx="129.93"
                cy="107.85"
                rx="0.67"
                ry="0.4"
              />
              <path
                className="cls-fbv-13"
                d="M270.44,31.32c-2.63.82-6.8,2.7-7.15,5.14-.38,2.6.28,4.37,3.92,6.39,2.64,1.47,13.28,5.52,18.06,6.83,6.4,1.76,19.36,5.49,28.29,6.37,10.45,1,18.82,1,24.64.18a83.27,83.27,0,0,0,14.22-2.84"
              />
              <ellipse
                className="cls-fbv-6"
                cx="281.8"
                cy="50.71"
                rx="0.44"
                ry="0.26"
              />
            </g>
            {volleyballNet && (
              <g id="Odbojkaska_mreza" data-name="Odbojkaska mreza">
                <path
                  className="cls-fbv-14"
                  d="M190.33,63.33c0,.15-.29.27-.65.27s-.65-.12-.65-.27.29-.27.65-.27S190.33,63.18,190.33,63.33Z"
                />
                <rect
                  className="cls-fbv-14"
                  x="189.03"
                  y="43.37"
                  width="1.31"
                  height="19.96"
                />
                <path
                  className="cls-fbv-15"
                  d="M190.33,43.37c0,.15-.29.27-.65.27s-.65-.12-.65-.27.29-.27.65-.27S190.33,43.22,190.33,43.37Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M257.16,71a.22.22,0,0,1-.11,0L190,44.22c-.1,0-.13-.13-.07-.19a.24.24,0,0,1,.27-.05l67.08,26.73c.09,0,.12.12.07.19A.25.25,0,0,1,257.16,71Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M251.23,69.38l-.1,0L195.06,47c-.1,0-.13-.12-.08-.18a.25.25,0,0,1,.28-.05l56.07,22.36c.1,0,.13.12.07.19A.22.22,0,0,1,251.23,69.38Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M251.23,70.19l-.1,0L195.06,47.8c-.1,0-.13-.12-.08-.19a.3.3,0,0,1,.28,0l56.07,22.37c.1,0,.13.12.07.19A.22.22,0,0,1,251.23,70.19Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M251.23,70.94l-.1,0L195.06,48.55c-.1,0-.13-.12-.08-.19a.27.27,0,0,1,.28,0l56.07,22.36c.1,0,.13.12.07.19A.22.22,0,0,1,251.23,70.94Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M251.23,71.74h-.1L195.06,49.36c-.1,0-.13-.12-.08-.19a.27.27,0,0,1,.28-.05l56.07,22.37c.1,0,.13.12.07.19A.22.22,0,0,1,251.23,71.74Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M256.7,77h-.1L190,50.45c-.1,0-.13-.12-.07-.18a.24.24,0,0,1,.27-.05L256.8,76.79c.1,0,.13.12.07.19A.22.22,0,0,1,256.7,77Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M251.23,72.5l-.1,0L195.06,50.11c-.1,0-.13-.12-.08-.19a.3.3,0,0,1,.28-.05l56.07,22.37c.1,0,.13.12.07.19A.22.22,0,0,1,251.23,72.5Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M251.23,73.3a.18.18,0,0,1-.1,0L195.06,50.92c-.1,0-.13-.12-.08-.19a.25.25,0,0,1,.28,0l56.07,22.37c.1,0,.13.12.07.19A.22.22,0,0,1,251.23,73.3Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M251.23,74.05h-.1L195.06,51.67c-.1,0-.13-.12-.08-.19a.27.27,0,0,1,.28,0L251.33,73.8c.1,0,.13.12.07.19A.22.22,0,0,1,251.23,74.05Z"
                />
                <path
                  className="cls-fbv-14"
                  d="M257.81,90c0,.15-.29.27-.65.27s-.66-.12-.66-.27.29-.27.66-.27S257.81,89.89,257.81,90Z"
                />
                <rect
                  className="cls-fbv-14"
                  x="256.5"
                  y="70.17"
                  width="1.31"
                  height="19.87"
                />
                <path
                  className="cls-fbv-15"
                  d="M257.81,70.17c0,.15-.29.27-.65.27s-.66-.12-.66-.27.29-.27.66-.27S257.81,70,257.81,70.17Z"
                />
                <polygon
                  className="cls-fbv-16"
                  points="197.19 53.11 196.79 53.01 196.79 46.77 197.19 46.87 197.19 53.11"
                />
                <polygon
                  className="cls-fbv-16"
                  points="199.12 53.9 198.72 53.8 198.72 47.56 199.12 47.66 199.12 53.9"
                />
                <polygon
                  className="cls-fbv-16"
                  points="200.95 54.62 200.55 54.52 200.55 48.28 200.95 48.38 200.95 54.62"
                />
                <polygon
                  className="cls-fbv-16"
                  points="202.88 55.41 202.48 55.3 202.48 49.07 202.88 49.17 202.88 55.41"
                />
                <polygon
                  className="cls-fbv-16"
                  points="204.84 56.18 204.44 56.08 204.44 49.84 204.84 49.94 204.84 56.18"
                />
                <polygon
                  className="cls-fbv-16"
                  points="206.77 56.97 206.37 56.86 206.37 50.63 206.77 50.73 206.77 56.97"
                />
                <polygon
                  className="cls-fbv-16"
                  points="208.6 57.69 208.2 57.58 208.2 51.34 208.6 51.45 208.6 57.69"
                />
                <polygon
                  className="cls-fbv-16"
                  points="210.54 58.47 210.13 58.37 210.13 52.13 210.54 52.23 210.54 58.47"
                />
                <polygon
                  className="cls-fbv-16"
                  points="212.47 59.26 212.07 59.16 212.07 52.92 212.47 53.02 212.47 59.26"
                />
                <polygon
                  className="cls-fbv-16"
                  points="214.3 59.98 213.9 59.88 213.9 53.64 214.3 53.74 214.3 59.98"
                />
                <polygon
                  className="cls-fbv-16"
                  points="216.23 60.77 215.83 60.67 215.83 54.43 216.23 54.53 216.23 60.77"
                />
                <polygon
                  className="cls-fbv-16"
                  points="218.19 61.54 217.79 61.44 217.79 55.2 218.19 55.3 218.19 61.54"
                />
                <polygon
                  className="cls-fbv-16"
                  points="220.12 62.33 219.72 62.23 219.72 55.99 220.12 56.09 220.12 62.33"
                />
                <polygon
                  className="cls-fbv-16"
                  points="221.95 63.05 221.55 62.95 221.55 56.71 221.95 56.81 221.95 63.05"
                />
                <polygon
                  className="cls-fbv-16"
                  points="223.88 63.84 223.48 63.73 223.48 57.49 223.88 57.6 223.88 63.84"
                />
                <polygon
                  className="cls-fbv-16"
                  points="225.72 64.56 225.31 64.45 225.31 58.21 225.72 58.32 225.72 64.56"
                />
                <polygon
                  className="cls-fbv-16"
                  points="227.65 65.34 227.25 65.24 227.25 59 227.65 59.1 227.65 65.34"
                />
                <polygon
                  className="cls-fbv-16"
                  points="229.6 66.11 229.2 66.01 229.2 59.77 229.6 59.88 229.6 66.11"
                />
                <polygon
                  className="cls-fbv-16"
                  points="231.53 66.9 231.13 66.8 231.13 60.56 231.53 60.66 231.53 66.9"
                />
                <polygon
                  className="cls-fbv-16"
                  points="233.37 67.62 232.97 67.52 232.97 61.28 233.37 61.38 233.37 67.62"
                />
                <polygon
                  className="cls-fbv-16"
                  points="235.3 68.41 234.9 68.31 234.9 62.07 235.3 62.17 235.3 68.41"
                />
                <polygon
                  className="cls-fbv-16"
                  points="237.23 69.11 236.83 69.01 236.83 62.77 237.23 62.87 237.23 69.11"
                />
                <polygon
                  className="cls-fbv-16"
                  points="239.19 69.88 238.79 69.78 238.79 63.54 239.19 63.65 239.19 69.88"
                />
                <polygon
                  className="cls-fbv-16"
                  points="241.32 70.67 240.92 70.57 240.92 64.33 241.32 64.43 241.32 70.67"
                />
                <polygon
                  className="cls-fbv-16"
                  points="243.25 71.58 242.85 71.48 242.85 65.24 243.25 65.34 243.25 71.58"
                />
                <polygon
                  className="cls-fbv-16"
                  points="245.38 72.36 244.98 72.26 244.98 66.02 245.38 66.12 245.38 72.36"
                />
                <polygon
                  className="cls-fbv-16"
                  points="247.42 73.16 247.01 73.05 247.01 66.82 247.42 66.92 247.42 73.16"
                />
                <polygon
                  className="cls-fbv-16"
                  points="249.35 73.94 248.95 73.84 248.95 67.6 249.35 67.71 249.35 73.94"
                />
                <rect
                  className="cls-fbv-16"
                  x="251.03"
                  y="68.46"
                  width="0.4"
                  height="6.24"
                />
                <rect
                  className="cls-fbv-16"
                  x="194.96"
                  y="46.12"
                  width="0.4"
                  height="6.24"
                />
              </g>
            )}
            {baskets && (
              <g id="Kosevi">
                <path
                  className="cls-fbv-14"
                  d="M89.52,74.42V44.51s5.76-2.47,6.76-2.82v1.54c-1.54.6-3.52,1.5-5.38,2.32V74.42"
                />
                <polygon
                  className="cls-fbv-17"
                  points="101.1 45.97 90.43 48.56 90.43 41.23 101.1 35.83 101.1 45.97"
                />
                <polygon
                  className="cls-fbv-18"
                  points="101.76 46.28 91.09 49.35 91.09 38.46 101.76 35.39 101.76 46.28"
                />
                <polygon
                  className="cls-fbv-17"
                  points="91.09 49.35 90.43 49.05 90.43 38.08 91.09 38.46 91.09 49.35"
                />
                <polygon
                  className="cls-fbv-19"
                  points="101.76 35.39 101.1 35.01 90.43 38.08 91.09 38.46 101.76 35.39"
                />
                <path
                  className="cls-fbv-25"
                  d="M93.69,40.7l5.54-1.55v5.68l-5.54,1.56ZM99,39.45,93.9,40.9v5.22L99,44.71Z"
                />
                <path
                  className="cls-fbv-25"
                  d="M91.6,39.11l9.64-2.81v9.33L91.6,48.44Zm9.43-2.5L91.81,39.3v8.78L101,45.51Z"
                />
                <rect
                  className="cls-fbv-16"
                  x="98"
                  y="45.32"
                  width="0.35"
                  height="3.77"
                />
                <rect
                  className="cls-fbv-16"
                  x="97.93"
                  y="47.4"
                  width="3.82"
                  height="0.35"
                  transform="translate(38.33 139.49) rotate(-81.68)"
                />
                <rect
                  className="cls-fbv-16"
                  x="96.33"
                  y="45.79"
                  width="0.35"
                  height="3.82"
                  transform="matrix(0.99, -0.14, 0.14, 0.99, -5.88, 14.46)"
                />
                <path
                  className="cls-fbv-16"
                  d="M95.47,50.85c0-1,1.19-1.76,2.71-1.76s2.71.77,2.71,1.76S99.7,52.6,98.18,52.6,95.47,51.83,95.47,50.85Zm.36,0c0,.78,1,1.4,2.35,1.4s2.35-.62,2.35-1.4-1-1.41-2.35-1.41S95.83,50.06,95.83,50.85Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M95.23,49.49c0-1.07,1.3-1.91,3-1.91s3,.84,3,1.91-1.3,1.92-3,1.92S95.23,50.57,95.23,49.49Zm.39,0c0,.86,1.13,1.53,2.57,1.53s2.56-.67,2.56-1.53S99.62,48,98.19,48,95.62,48.64,95.62,49.49Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M95.06,48.24c0-1.14,1.37-2,3.13-2s3.12.89,3.12,2-1.37,2-3.12,2S95.06,49.37,95.06,48.24Zm.41,0c0,.9,1.19,1.61,2.72,1.61s2.71-.71,2.71-1.61-1.19-1.62-2.71-1.62S95.47,47.33,95.47,48.24Z"
                />
                <rect
                  className="cls-fbv-16"
                  x="99.3"
                  y="48.97"
                  width="3.45"
                  height="0.35"
                  transform="translate(34.28 139.52) rotate(-79.49)"
                />
                <path
                  className="cls-fbv-16"
                  d="M95.47,50.88l.35-.07c-.25-1.36-.64-3.43-.65-3.56h-.35C94.82,47.35,95.17,49.28,95.47,50.88Z"
                />
                <polygon
                  className="cls-fbv-16"
                  points="99.72 52.15 99.95 48.93 99.6 48.91 99.37 52.12 99.72 52.15"
                />
                <rect
                  className="cls-fbv-16"
                  x="96.45"
                  y="48.83"
                  width="0.35"
                  height="3.22"
                  transform="translate(-3.4 7.14) rotate(-4.16)"
                />
                <rect
                  className="cls-fbv-16"
                  x="98"
                  y="49.27"
                  width="0.35"
                  height="3.16"
                />
                <path
                  className="cls-fbv-20"
                  d="M94.78,47.26c0-1.19,1.5-2.12,3.4-2.12s3.39.93,3.39,2.12-1.49,2.12-3.39,2.12S94.78,48.45,94.78,47.26Zm.42,0c0,.92,1.36,1.71,3,1.71s3-.79,3-1.71-1.36-1.7-3-1.7S95.2,46.34,95.2,47.26Z"
                />
                <path
                  className="cls-fbv-14"
                  d="M233,33.6V8.1s4.91-2.1,5.76-2.41V7c-1.32.51-3,1.28-4.59,2V33.6"
                />
                <polygon
                  className="cls-fbv-17"
                  points="242.87 9.35 233.77 11.55 233.77 5.3 242.87 0.7 242.87 9.35"
                />
                <polygon
                  className="cls-fbv-18"
                  points="243.43 9.61 234.33 12.23 234.33 2.95 243.43 0.33 243.43 9.61"
                />
                <polygon
                  className="cls-fbv-17"
                  points="234.33 12.23 233.77 11.97 233.77 2.62 234.33 2.95 234.33 12.23"
                />
                <polygon
                  className="cls-fbv-19"
                  points="243.43 0.33 242.87 0 233.77 2.62 234.33 2.95 243.43 0.33"
                />
                <path
                  className="cls-fbv-25"
                  d="M236.55,4.86l4.72-1.33V8.38L236.55,9.7Zm4.55-1.07L236.73,5V9.48l4.37-1.21Z"
                />
                <path
                  className="cls-fbv-25"
                  d="M234.77,3.49,243,1.1v8l-8.22,2.39Zm8-2.13L235,3.66v7.48L242.81,9Z"
                />
                <rect
                  className="cls-fbv-16"
                  x="240.22"
                  y="8.79"
                  width="0.3"
                  height="3.21"
                />
                <rect
                  className="cls-fbv-16"
                  x="240.17"
                  y="10.57"
                  width="3.26"
                  height="0.3"
                  transform="translate(196.22 248.42) rotate(-81.68)"
                />
                <rect
                  className="cls-fbv-16"
                  x="238.8"
                  y="9.19"
                  width="0.3"
                  height="3.26"
                  transform="translate(0.95 34.67) rotate(-8.32)"
                />
                <path
                  className="cls-fbv-16"
                  d="M238.07,13.5c0-.84,1-1.49,2.31-1.49s2.3.65,2.3,1.49-1,1.5-2.3,1.5S238.07,14.34,238.07,13.5Zm.3,0c0,.67.88,1.2,2,1.2s2-.53,2-1.2-.88-1.19-2-1.19S238.37,12.83,238.37,13.5Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M237.86,12.35c0-.92,1.11-1.63,2.52-1.63s2.52.71,2.52,1.63S241.79,14,240.38,14,237.86,13.27,237.86,12.35Zm.33,0c0,.73,1,1.3,2.19,1.3s2.19-.57,2.19-1.3-1-1.3-2.19-1.3S238.19,11.62,238.19,12.35Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M237.71,11.28c0-1,1.18-1.73,2.67-1.73s2.67.76,2.67,1.73S241.88,13,240.38,13,237.71,12.25,237.71,11.28Zm.35,0c0,.77,1,1.38,2.32,1.38s2.32-.61,2.32-1.38-1-1.38-2.32-1.38S238.06,10.5,238.06,11.28Z"
                />
                <rect
                  className="cls-fbv-16"
                  x="241.33"
                  y="11.91"
                  width="2.94"
                  height="0.3"
                  transform="translate(186.67 248.59) rotate(-79.49)"
                />
                <path
                  className="cls-fbv-16"
                  d="M238.07,13.53l.3,0c-.22-1.16-.55-2.93-.56-3h-.3C237.51,10.53,237.81,12.17,238.07,13.53Z"
                />
                <polygon
                  className="cls-fbv-16"
                  points="241.69 14.61 241.89 11.87 241.59 11.85 241.39 14.59 241.69 14.61"
                />
                <rect
                  className="cls-fbv-16"
                  x="238.9"
                  y="11.78"
                  width="0.3"
                  height="2.75"
                  transform="translate(-0.32 17.36) rotate(-4.16)"
                />
                <rect
                  className="cls-fbv-16"
                  x="240.22"
                  y="12.16"
                  width="0.3"
                  height="2.69"
                />
                <path
                  className="cls-fbv-20"
                  d="M237.48,10.45c0-1,1.27-1.81,2.9-1.81s2.89.79,2.89,1.81-1.27,1.8-2.89,1.8S237.48,11.46,237.48,10.45Zm.35,0c0,.78,1.17,1.45,2.55,1.45s2.54-.67,2.54-1.45S241.75,9,240.38,9,237.83,9.66,237.83,10.45Z"
                />
                <rect
                  className="cls-fbv-16"
                  x="185.66"
                  y="95.39"
                  width="0.48"
                  height="5.06"
                />
                <rect
                  className="cls-fbv-16"
                  x="185.56"
                  y="98.18"
                  width="5.14"
                  height="0.47"
                  transform="translate(63.54 270.34) rotate(-81.68)"
                />
                <rect
                  className="cls-fbv-16"
                  x="183.41"
                  y="96.01"
                  width="0.48"
                  height="5.14"
                  transform="translate(-12.33 27.6) rotate(-8.32)"
                />
                <path
                  className="cls-fbv-16"
                  d="M182.25,102.81c0-1.32,1.6-2.36,3.64-2.36s3.64,1,3.64,2.36-1.6,2.36-3.64,2.36S182.25,104.13,182.25,102.81Zm.48,0c0,1.06,1.39,1.89,3.16,1.89s3.17-.83,3.17-1.89-1.39-1.88-3.17-1.88S182.73,101.75,182.73,102.81Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M181.93,101c0-1.44,1.75-2.57,4-2.57s4,1.13,4,2.57-1.74,2.58-4,2.58S181.93,102.44,181.93,101Zm.52,0c0,1.15,1.52,2.06,3.45,2.06s3.45-.91,3.45-2.06-1.51-2-3.45-2S182.45,99.84,182.45,101Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M181.7,99.3c0-1.53,1.85-2.73,4.2-2.73s4.2,1.2,4.2,2.73-1.84,2.73-4.2,2.73S181.7,100.83,181.7,99.3Zm.55,0c0,1.22,1.6,2.18,3.65,2.18s3.66-1,3.66-2.18-1.61-2.18-3.66-2.18S182.25,98.08,182.25,99.3Z"
                />
                <rect
                  className="cls-fbv-16"
                  x="187.4"
                  y="100.29"
                  width="4.64"
                  height="0.48"
                  transform="translate(56.28 268.74) rotate(-79.49)"
                />
                <path
                  className="cls-fbv-16"
                  d="M182.26,102.85l.47-.08c-.35-1.83-.87-4.61-.88-4.79h-.48C181.37,98.12,181.85,100.7,182.26,102.85Z"
                />
                <polygon
                  className="cls-fbv-16"
                  points="187.97 104.56 188.28 100.24 187.81 100.21 187.5 104.52 187.97 104.56"
                />
                <rect
                  className="cls-fbv-16"
                  x="183.57"
                  y="100.1"
                  width="0.47"
                  height="4.33"
                  transform="translate(-6.93 13.6) rotate(-4.16)"
                />
                <rect
                  className="cls-fbv-16"
                  x="185.66"
                  y="100.69"
                  width="0.48"
                  height="4.25"
                />
                <path
                  className="cls-fbv-20"
                  d="M181.33,98c0-1.6,2-2.85,4.56-2.85s4.57,1.25,4.57,2.85-2,2.85-4.57,2.85S181.33,99.59,181.33,98Zm.56,0c0,1.24,1.83,2.29,4,2.29s4-1,4-2.29-1.83-2.29-4-2.29S181.89,96.75,181.89,98Z"
                />
                <polygon
                  className="cls-fbv-17"
                  points="199.14 101.79 184.79 108.93 184.79 94.17 199.14 88.16 199.14 101.79"
                />
                <polygon
                  className="cls-fbv-18"
                  points="200.02 102.2 185.68 109.34 185.68 94.69 200.02 87.56 200.02 102.2"
                />
                <polygon
                  className="cls-fbv-17"
                  points="185.68 109.34 184.79 108.93 184.79 94.17 185.68 94.69 185.68 109.34"
                />
                <polygon
                  className="cls-fbv-19"
                  points="200.02 87.56 199.14 87.05 184.79 94.17 185.68 94.69 200.02 87.56"
                />
                <polygon
                  className="cls-fbv-14"
                  points="193.23 97.94 198.5 106.82 198.5 148.21 196.62 148.21 196.62 107.68 192.03 99.7 193.23 97.94"
                />
                <path
                  className="cls-fbv-14"
                  d="M193.35,98.36a1.74,1.74,0,0,1-.73,1.39c-.41.24-.73,0-.73-.55a1.73,1.73,0,0,1,.73-1.39C193,97.58,193.35,97.82,193.35,98.36Z"
                />
                <path
                  className="cls-fbv-14"
                  d="M196.66,148.21c0,.31.41.55.92.55s.92-.24.92-.55-.41-.55-.92-.55S196.66,147.9,196.66,148.21Z"
                />
                <rect
                  className="cls-fbv-16"
                  x="336.12"
                  y="33.89"
                  width="0.37"
                  height="3.93"
                />
                <rect
                  className="cls-fbv-16"
                  x="336.05"
                  y="36.05"
                  width="3.98"
                  height="0.37"
                  transform="translate(253.29 365.48) rotate(-81.68)"
                />
                <rect
                  className="cls-fbv-16"
                  x="334.38"
                  y="34.37"
                  width="0.37"
                  height="3.98"
                  transform="translate(-1.74 48.77) rotate(-8.32)"
                />
                <path
                  className="cls-fbv-16"
                  d="M333.48,39.64c0-1,1.24-1.83,2.82-1.83s2.82.81,2.82,1.83-1.23,1.83-2.82,1.83S333.48,40.67,333.48,39.64Zm.37,0c0,.82,1.08,1.46,2.45,1.46s2.46-.64,2.46-1.46-1.08-1.46-2.46-1.46S333.85,38.82,333.85,39.64Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M333.23,38.23c0-1.12,1.36-2,3.08-2s3.08.87,3.08,2-1.35,2-3.08,2S333.23,39.35,333.23,38.23Zm.41,0c0,.9,1.17,1.6,2.67,1.6s2.67-.7,2.67-1.6-1.17-1.59-2.67-1.59S333.64,37.34,333.64,38.23Z"
                />
                <path
                  className="cls-fbv-16"
                  d="M333.05,36.92c0-1.18,1.43-2.11,3.26-2.11s3.26.93,3.26,2.11S338.14,39,336.31,39,333.05,38.11,333.05,36.92Zm.43,0c0,.95,1.24,1.69,2.83,1.69s2.83-.74,2.83-1.69-1.24-1.69-2.83-1.69S333.48,36,333.48,36.92Z"
                />
                <rect
                  className="cls-fbv-16"
                  x="337.47"
                  y="37.69"
                  width="3.59"
                  height="0.37"
                  transform="translate(240.17 364.55) rotate(-79.49)"
                />
                <path
                  className="cls-fbv-16"
                  d="M333.49,39.68l.36-.07c-.27-1.42-.67-3.58-.68-3.72l-.37,0C332.8,36,333.17,38,333.49,39.68Z"
                />
                <polygon
                  className="cls-fbv-16"
                  points="337.91 41 338.15 37.65 337.79 37.62 337.55 40.97 337.91 41"
                />
                <rect
                  className="cls-fbv-16"
                  x="334.51"
                  y="37.54"
                  width="0.37"
                  height="3.36"
                  transform="translate(-1.96 24.37) rotate(-4.16)"
                />
                <rect
                  className="cls-fbv-16"
                  x="336.12"
                  y="38"
                  width="0.37"
                  height="3.29"
                />
                <path
                  className="cls-fbv-20"
                  d="M332.77,35.91c0-1.24,1.55-2.21,3.53-2.21s3.54,1,3.54,2.21-1.55,2.2-3.54,2.2S332.77,37.14,332.77,35.91Zm.43,0c0,1,1.42,1.77,3.1,1.77s3.11-.81,3.11-1.77-1.42-1.78-3.11-1.78S333.2,34.94,333.2,35.91Z"
                />
                <polygon
                  className="cls-fbv-17"
                  points="346.57 38.85 335.45 44.26 335.45 32.84 346.57 28.29 346.57 38.85"
                />
                <polygon
                  className="cls-fbv-18"
                  points="347.25 39.17 336.13 44.58 336.13 33.24 347.25 27.82 347.25 39.17"
                />
                <polygon
                  className="cls-fbv-17"
                  points="336.13 44.58 335.45 44.26 335.45 32.84 336.13 33.24 336.13 44.58"
                />
                <polygon
                  className="cls-fbv-19"
                  points="347.25 27.82 346.57 27.43 335.45 32.84 336.13 33.24 347.25 27.82"
                />
                <polygon
                  className="cls-fbv-14"
                  points="341.99 35.87 346.08 42.75 346.08 74.83 344.62 74.83 344.62 43.42 341.06 37.23 341.99 35.87"
                />
                <path
                  className="cls-fbv-14"
                  d="M342.08,36.19a1.33,1.33,0,0,1-.57,1.08c-.31.18-.56,0-.56-.42a1.35,1.35,0,0,1,.56-1.08C341.83,35.59,342.08,35.78,342.08,36.19Z"
                />
                <path
                  className="cls-fbv-14"
                  d="M344.65,74.83c0,.23.32.43.71.43s.72-.2.72-.43-.32-.43-.72-.43S344.65,74.59,344.65,74.83Z"
                />
              </g>
            )}
            {goals && (
              <g id="Golovi">
                <polygon
                  className="cls-goal-2"
                  points="47.22 126.18 60.68 139.15 76.73 133.19 72.81 114.05 58.25 102.58 48.06 106.06 47.22 126.18"
                />
                <line
                  className="cls-goal-3"
                  x1="71.32"
                  y1="114.59"
                  x2="56.87"
                  y2="103.05"
                />
                <line
                  className="cls-goal-3"
                  x1="69.85"
                  y1="115.21"
                  x2="55.34"
                  y2="103.57"
                />
                <line
                  className="cls-goal-3"
                  x1="68.34"
                  y1="115.67"
                  x2="53.96"
                  y2="104.04"
                />
                <line
                  className="cls-goal-3"
                  x1="66.73"
                  y1="116.25"
                  x2="52.5"
                  y2="104.46"
                />
                <line
                  className="cls-goal-3"
                  x1="65.22"
                  y1="116.8"
                  x2="51.13"
                  y2="105.01"
                />
                <line
                  className="cls-goal-3"
                  x1="63.5"
                  y1="117.42"
                  x2="49.66"
                  y2="105.51"
                />
                <line
                  className="cls-goal-3"
                  x1="59.61"
                  y1="103.64"
                  x2="49.38"
                  y2="107.22"
                />
                <line
                  className="cls-goal-3"
                  x1="61.07"
                  y1="104.88"
                  x2="50.88"
                  y2="108.36"
                />
                <line
                  className="cls-goal-3"
                  x1="62.66"
                  y1="106.05"
                  x2="52.15"
                  y2="109.65"
                />
                <line
                  className="cls-goal-3"
                  x1="64.15"
                  y1="107.23"
                  x2="53.53"
                  y2="110.86"
                />
                <line
                  className="cls-goal-3"
                  x1="65.68"
                  y1="108.43"
                  x2="54.91"
                  y2="112.21"
                />
                <line
                  className="cls-goal-3"
                  x1="67.19"
                  y1="109.62"
                  x2="56.46"
                  y2="113.44"
                />
                <line
                  className="cls-goal-3"
                  x1="68.59"
                  y1="110.72"
                  x2="57.8"
                  y2="114.61"
                />
                <line
                  className="cls-goal-3"
                  x1="70.02"
                  y1="111.85"
                  x2="59.19"
                  y2="115.83"
                />
                <line
                  className="cls-goal-3"
                  x1="71.51"
                  y1="113.03"
                  x2="60.62"
                  y2="117.08"
                />
                <polyline
                  className="cls-goal-2"
                  points="48.06 106.06 61.73 118.06 72.81 114.05"
                />
                <line
                  className="cls-goal-2"
                  x1="60.68"
                  y1="139.15"
                  x2="61.73"
                  y2="118.06"
                />
                <polyline
                  className="cls-goal-2"
                  points="58.25 102.58 62.75 120.86 47.22 126.18"
                />
                <line
                  className="cls-goal-4"
                  x1="47.35"
                  y1="124.04"
                  x2="60.8"
                  y2="137.01"
                />
                <line
                  className="cls-goal-4"
                  x1="47.4"
                  y1="121.95"
                  x2="60.9"
                  y2="134.97"
                />
                <line
                  className="cls-goal-4"
                  x1="47.55"
                  y1="119.95"
                  x2="61"
                  y2="132.75"
                />
                <line
                  className="cls-goal-4"
                  x1="47.65"
                  y1="117.91"
                  x2="61.11"
                  y2="130.68"
                />
                <line
                  className="cls-goal-4"
                  x1="47.66"
                  y1="115.77"
                  x2="61.11"
                  y2="128.11"
                />
                <line
                  className="cls-goal-4"
                  x1="47.75"
                  y1="113.48"
                  x2="61.33"
                  y2="126.12"
                />
                <line
                  className="cls-goal-4"
                  x1="47.83"
                  y1="111.55"
                  x2="61.42"
                  y2="123.7"
                />
                <line
                  className="cls-goal-4"
                  x1="48.06"
                  y1="109.62"
                  x2="61.56"
                  y2="121.71"
                />
                <line
                  className="cls-goal-4"
                  x1="48.06"
                  y1="107.76"
                  x2="61.73"
                  y2="119.95"
                />
                <line
                  className="cls-goal-5"
                  x1="73.21"
                  y1="115.77"
                  x2="61.63"
                  y2="120.07"
                />
                <line
                  className="cls-goal-5"
                  x1="73.59"
                  y1="117.69"
                  x2="61.63"
                  y2="122.13"
                />
                <line
                  className="cls-goal-5"
                  x1="73.97"
                  y1="119.6"
                  x2="61.42"
                  y2="124.26"
                />
                <line
                  className="cls-goal-5"
                  x1="74.36"
                  y1="121.52"
                  x2="61.42"
                  y2="126.32"
                />
                <line
                  className="cls-goal-5"
                  x1="74.76"
                  y1="123.51"
                  x2="61.21"
                  y2="128.55"
                />
                <line
                  className="cls-goal-5"
                  x1="75.14"
                  y1="125.43"
                  x2="61.11"
                  y2="130.64"
                />
                <line
                  className="cls-goal-5"
                  x1="75.52"
                  y1="127.34"
                  x2="61.01"
                  y2="132.73"
                />
                <line
                  className="cls-goal-5"
                  x1="75.91"
                  y1="129.26"
                  x2="60.9"
                  y2="134.83"
                />
                <line
                  className="cls-goal-5"
                  x1="76.29"
                  y1="131.17"
                  x2="60.8"
                  y2="136.92"
                />
                <line
                  className="cls-goal-3"
                  x1="48.37"
                  y1="127.29"
                  x2="49.38"
                  y2="107.22"
                />
                <line
                  className="cls-goal-3"
                  x1="55.26"
                  y1="133.93"
                  x2="56.3"
                  y2="113.29"
                />
                <line
                  className="cls-goal-3"
                  x1="53.88"
                  y1="132.6"
                  x2="54.91"
                  y2="112.07"
                />
                <line
                  className="cls-goal-3"
                  x1="49.75"
                  y1="128.62"
                  x2="50.76"
                  y2="108.43"
                />
                <line
                  className="cls-goal-3"
                  x1="51.13"
                  y1="129.94"
                  x2="52.15"
                  y2="109.65"
                />
                <line
                  className="cls-goal-3"
                  x1="52.5"
                  y1="131.27"
                  x2="53.53"
                  y2="110.86"
                />
                <line
                  className="cls-goal-3"
                  x1="56.64"
                  y1="135.26"
                  x2="57.69"
                  y2="114.38"
                />
                <line
                  className="cls-goal-3"
                  x1="58.02"
                  y1="136.58"
                  x2="59.07"
                  y2="115.67"
                />
                <line
                  className="cls-goal-3"
                  x1="59.39"
                  y1="137.91"
                  x2="60.45"
                  y2="116.93"
                />
                <line
                  className="cls-goal-5"
                  x1="62.45"
                  y1="138.49"
                  x2="63.08"
                  y2="117.57"
                />
                <line
                  className="cls-goal-5"
                  x1="64.29"
                  y1="137.81"
                  x2="64.44"
                  y2="117.08"
                />
                <line
                  className="cls-goal-5"
                  x1="66.15"
                  y1="137.12"
                  x2="65.77"
                  y2="116.6"
                />
                <line
                  className="cls-goal-5"
                  x1="67.95"
                  y1="136.45"
                  x2="66.98"
                  y2="116.14"
                />
                <line
                  className="cls-goal-5"
                  x1="69.72"
                  y1="135.8"
                  x2="68.2"
                  y2="115.74"
                />
                <line
                  className="cls-goal-5"
                  x1="71.42"
                  y1="135.17"
                  x2="69.3"
                  y2="115.32"
                />
                <line
                  className="cls-goal-5"
                  x1="73.36"
                  y1="134.44"
                  x2="70.43"
                  y2="114.91"
                />
                <line
                  className="cls-goal-5"
                  x1="75.14"
                  y1="133.78"
                  x2="71.58"
                  y2="114.49"
                />
                <polygon
                  className="cls-goal-2"
                  points="299.51 38.71 301.39 26.47 305.04 25.59 322.1 29.59 321.69 41.53 316.33 43.35 316.88 39.93 304.98 37 299.51 38.71"
                />
                <line
                  className="cls-goal-3"
                  x1="316.93"
                  y1="38.46"
                  x2="305.04"
                  y2="35.54"
                />
                <line
                  className="cls-goal-3"
                  x1="317.43"
                  y1="37.14"
                  x2="305.04"
                  y2="34.09"
                />
                <line
                  className="cls-goal-3"
                  x1="317.67"
                  y1="35.73"
                  x2="305.04"
                  y2="32.62"
                />
                <line
                  className="cls-goal-3"
                  x1="317.85"
                  y1="34.31"
                  x2="305.04"
                  y2="31.16"
                />
                <line
                  className="cls-goal-3"
                  x1="318.17"
                  y1="32.93"
                  x2="305.04"
                  y2="29.69"
                />
                <line
                  className="cls-goal-3"
                  x1="318.29"
                  y1="31.64"
                  x2="305.11"
                  y2="28.4"
                />
                <polyline
                  className="cls-goal-2"
                  points="316.33 43.35 318.57 30.65 322.1 29.59"
                />
                <line
                  className="cls-goal-2"
                  x1="301.39"
                  y1="26.47"
                  x2="318.57"
                  y2="30.65"
                />
                <line
                  className="cls-goal-2"
                  x1="304.98"
                  y1="37"
                  x2="305.04"
                  y2="25.59"
                />
                <line
                  className="cls-goal-3"
                  x1="299.75"
                  y1="37.14"
                  x2="304.98"
                  y2="35.6"
                />
                <line
                  className="cls-goal-3"
                  x1="300"
                  y1="35.55"
                  x2="304.98"
                  y2="34.08"
                />
                <line
                  className="cls-goal-3"
                  x1="300.24"
                  y1="33.98"
                  x2="305.04"
                  y2="32.57"
                />
                <line
                  className="cls-goal-3"
                  x1="300.48"
                  y1="32.4"
                  x2="304.98"
                  y2="31.08"
                />
                <line
                  className="cls-goal-3"
                  x1="300.72"
                  y1="30.87"
                  x2="304.98"
                  y2="29.61"
                />
                <line
                  className="cls-goal-3"
                  x1="300.95"
                  y1="29.36"
                  x2="304.87"
                  y2="28.21"
                />
                <line
                  className="cls-goal-3"
                  x1="301.17"
                  y1="27.95"
                  x2="304.87"
                  y2="26.86"
                />
                <line
                  className="cls-goal-3"
                  x1="316.66"
                  y1="41.79"
                  x2="321.89"
                  y2="40.03"
                />
                <line
                  className="cls-goal-3"
                  x1="316.91"
                  y1="40.08"
                  x2="321.89"
                  y2="38.51"
                />
                <line
                  className="cls-goal-3"
                  x1="317.19"
                  y1="38.51"
                  x2="321.95"
                  y2="37"
                />
                <line
                  className="cls-goal-3"
                  x1="317.46"
                  y1="36.93"
                  x2="321.89"
                  y2="35.51"
                />
                <line
                  className="cls-goal-3"
                  x1="317.75"
                  y1="35.29"
                  x2="321.95"
                  y2="34.02"
                />
                <line
                  className="cls-goal-3"
                  x1="318.02"
                  y1="33.74"
                  x2="322"
                  y2="32.57"
                />
                <line
                  className="cls-goal-3"
                  x1="318.29"
                  y1="32.23"
                  x2="322.05"
                  y2="31.11"
                />
                <line
                  className="cls-goal-3"
                  x1="303.19"
                  y1="26.04"
                  x2="320.38"
                  y2="30.12"
                />
                <line
                  className="cls-goal-3"
                  x1="321.21"
                  y1="29.86"
                  x2="320.5"
                  y2="41.79"
                />
                <line
                  className="cls-goal-3"
                  x1="320.27"
                  y1="30.24"
                  x2="319.28"
                  y2="42.31"
                />
                <line
                  className="cls-goal-3"
                  x1="317.85"
                  y1="42.74"
                  x2="319.4"
                  y2="30.58"
                />
                <line
                  className="cls-goal-3"
                  x1="304.18"
                  y1="25.8"
                  x2="303.72"
                  y2="37.39"
                />
                <line
                  className="cls-goal-3"
                  x1="303.19"
                  y1="26.04"
                  x2="302.39"
                  y2="37.91"
                />
                <line
                  className="cls-goal-3"
                  x1="300.96"
                  y1="38.35"
                  x2="302.37"
                  y2="26.33"
                />
                <line
                  className="cls-goal-3"
                  x1="306.25"
                  y1="37.31"
                  x2="306.42"
                  y2="27.69"
                />
                <line
                  className="cls-goal-3"
                  x1="307.56"
                  y1="37.76"
                  x2="307.77"
                  y2="28.08"
                />
                <line
                  className="cls-goal-3"
                  x1="308.89"
                  y1="38.11"
                  x2="309.12"
                  y2="28.4"
                />
                <line
                  className="cls-goal-3"
                  x1="310.21"
                  y1="38.57"
                  x2="310.5"
                  y2="28.69"
                />
                <line
                  className="cls-goal-3"
                  x1="311.6"
                  y1="38.63"
                  x2="311.96"
                  y2="29.04"
                />
                <line
                  className="cls-goal-3"
                  x1="313.08"
                  y1="38.99"
                  x2="313.45"
                  y2="29.4"
                />
                <line
                  className="cls-goal-3"
                  x1="314.4"
                  y1="39.32"
                  x2="314.92"
                  y2="29.76"
                />
                <line
                  className="cls-goal-3"
                  x1="315.86"
                  y1="39.68"
                  x2="316.48"
                  y2="30.14"
                />
                <line
                  className="cls-goal-3"
                  x1="316.85"
                  y1="39.9"
                  x2="317.85"
                  y2="30.47"
                />
                <line
                  className="cls-goal-3"
                  x1="306.33"
                  y1="25.89"
                  x2="303.13"
                  y2="26.89"
                />
                <line
                  className="cls-goal-3"
                  x1="308.13"
                  y1="26.31"
                  x2="304.98"
                  y2="27.42"
                />
                <line
                  className="cls-goal-3"
                  x1="309.98"
                  y1="26.75"
                  x2="307.17"
                  y2="27.88"
                />
                <line
                  className="cls-goal-3"
                  x1="311.78"
                  y1="27.17"
                  x2="309.19"
                  y2="28.37"
                />
                <line
                  className="cls-goal-3"
                  x1="313.45"
                  y1="27.56"
                  x2="311.07"
                  y2="28.82"
                />
                <line
                  className="cls-goal-3"
                  x1="315.07"
                  y1="27.94"
                  x2="312.75"
                  y2="29.23"
                />
                <line
                  className="cls-goal-3"
                  x1="316.63"
                  y1="28.3"
                  x2="314.53"
                  y2="29.67"
                />
                <line
                  className="cls-goal-3"
                  x1="318.46"
                  y1="28.78"
                  x2="316.08"
                  y2="30.02"
                />
                <line
                  className="cls-goal-3"
                  x1="320.5"
                  y1="29.21"
                  x2="317.35"
                  y2="30.37"
                />
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FootballBasketballVolleyball;
