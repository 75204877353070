import { backendUrl } from "../constants/constants";

export const handleSendEmail = async (
  message: string,
  userName: string,
  userEmail: string,
  screenshot: string
) => {
  try {
    const response = await fetch(`${backendUrl}/send-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message,
        clientName: userName,
        emailAddress: userEmail,
        screenshot,
      }),
    });

    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};
