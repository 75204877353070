import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ChooseCourt from "../pages/ChooseCourt";
import EditCourt from "../pages/EditCourt";

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ChooseCourt />} />
        <Route path="/EditCourt/:id" element={<EditCourt />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
