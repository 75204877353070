import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "./locales/config";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <SnackbarProvider maxSnack={3} hideIconVariant>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ThemeProvider>
    </I18nextProvider>
  </SnackbarProvider>
);
