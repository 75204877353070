import { ColorValues } from "../constants/constants";

interface Props {
  lineColor?: ColorValues;
  fieldColor?: ColorValues;
  attackZone?: ColorValues;
  aroundColor?: ColorValues;
  keyColor?: ColorValues;
  tennisColor?: ColorValues;
  tennisNet?: boolean;
  baskets?: boolean;
}

const BasketballAndTennis = ({
  lineColor = ColorValues.RAL9016,
  aroundColor = ColorValues.RAL6002,
  fieldColor = ColorValues.RAL1018,
  tennisColor = ColorValues.RAL6018,
  keyColor = ColorValues.RAL6002,
  tennisNet = true,
  baskets = true,
}: Props) => {
  return (
    <svg
      id="basketball-tennis"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 360.15 175.53"
      style={{
        width: "100%", // Make the SVG responsive
        height: "auto", // Let the height adjust proportionally
      }}
    >
      <defs>
        <style>
          {`
         .cls-basketball-tennis-1{fill:#6b6b6b;}
         .cls-basketball-tennis-2{fill:#7f7f7f;}
         .cls-basketball-tennis-3{fill:${aroundColor};}
         .cls-basketball-tennis-4{fill:${fieldColor};}
         .cls-basketball-tennis-5{fill:${tennisColor};}
         .cls-basketball-tennis-6{fill:${lineColor};}
         .cls-basketball-tennis-7{fill:#0f0f0f;}
         .cls-basketball-tennis-8{fill:#353535;}
         .cls-basketball-tennis-9{fill:#e2e2e2;}
         .cls-basketball-tennis-10{fill:#8e8e8e;}
         .cls-basketball-tennis-11{fill:#b2b2b2;}
         .cls-basketball-tennis-12{fill:#dbdbdb;}
         .cls-basketball-tennis-13{fill:#d6403c;}
         .cls-basketball-tennis-14{fill:${keyColor}}
         .cls-basketball-tennis-15{fill:#ffffff;}
          `}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Kosarka_Tenis" data-name="Kosarka &amp; Tenis">
            <g id="Sivo">
              <polygon
                className="cls-basketball-tennis-1"
                points="21.32 169.28 21.32 175.53 360.15 75.41 360.15 69.15 21.32 169.28"
              />
              <polygon
                className="cls-basketball-tennis-2"
                points="0 57.45 0 63.71 21.32 175.53 21.32 169.28 0 57.45"
              />
            </g>
            <polygon
              id="Obod"
              className="cls-basketball-tennis-3"
              points="0 57.45 21.32 169.28 360.15 69.15 240 16.92 0 57.45"
            />
            <polygon
              id="Podloga"
              className="cls-basketball-tennis-4"
              points="22.38 60.3 49.01 143.85 330.45 66.25 235.88 22.74 22.38 60.3"
            />
            <polygon
              id="Teniska_podloga"
              data-name="Teniska podloga"
              className="cls-basketball-tennis-5"
              points="57.72 59.72 88.01 120.77 294.54 66.65 218.03 31.55 57.72 59.72"
            />
            <g id="Reket">
              <polygon
                className="cls-basketball-tennis-14"
                points="243.44 56.51 223.15 44.95 265.35 36.2 287.71 46.59 243.44 56.51"
              />
              <polygon
                className="cls-basketball-tennis-14"
                points="36.24 103.23 30.07 84 96.95 70.58 109.63 86.79 36.24 103.23"
              />
            </g>
            <g id="Linija">
              <path
                className="cls-basketball-tennis-6"
                d="M48.64,144.68,22,60.13,236.08,22.28l95.66,44.08ZM23.07,60.51,49.56,143,329.35,66.24l-93.43-43Z"
              />
              <path
                className="cls-basketball-tennis-6"
                d="M174.17,71.48c-10.47.27-19.08-3.07-19.2-7.43s8.32-8.14,18.79-8.41S192.84,58.7,193,63.07,184.64,71.21,174.17,71.48Zm-.39-15C164,56.7,156.06,60.1,156.16,64s8.17,6.9,18,6.65S191.86,67,191.76,63.1,183.59,56.19,173.78,56.45Z"
              />
              <polygon
                className="cls-basketball-tennis-6"
                points="216.22 97.74 142.62 39.3 143.92 38.96 217.35 97.27 216.22 97.74"
              />
              <path
                className="cls-basketball-tennis-6"
                d="M110.08,87.1l-.76-.71c9.16-2,16.48-8.31,12.83-12.13s-15-6-24.78-3.29l-.5-.75C108.26,67,119.24,69.7,123,73.9S120.61,84.87,110.08,87.1Z"
              />
              <polygon
                className="cls-basketball-tennis-6"
                points="110.44 87.03 109.46 87.23 96.65 70.97 97.23 70.15 110.44 87.03"
              />
              <polygon
                className="cls-basketball-tennis-6"
                points="97.81 70.88 30.59 84.4 30.22 83.62 97.23 70.15 97.81 70.88"
              />
              <polygon
                className="cls-basketball-tennis-6"
                points="110.44 87.01 36.68 103.6 36 102.88 109.76 86.3 110.44 87.01"
              />
              <path
                className="cls-basketball-tennis-6"
                d="M46.39,135.13,46,133.84c33.94-6.29,101.07-33.82,75.12-60.72-18-18.71-73.15-14-96.84-8.22l-.52-.75c24.23-5.89,80.49-10.7,98.33,8.73C146.38,99.35,91.53,125.18,46.39,135.13Z"
              />
              <path
                className="cls-basketball-tennis-6"
                d="M222.79,44.72l.76.52c-6.55,1.53-10.34,4.84-6.36,7.62s18.39,5.65,25.64,3.47l.61.55c-8.3,2.77-22.63-.69-26.8-3.75S215.08,46.28,222.79,44.72Z"
              />
              <polygon
                className="cls-basketball-tennis-6"
                points="222.39 44.96 223.1 44.8 243.93 56.55 243.23 56.88 222.39 44.96"
              />
              <polygon
                className="cls-basketball-tennis-6"
                points="242.54 56.41 286.67 46.37 287.19 46.95 243.22 56.95 242.54 56.41"
              />
              <polygon
                className="cls-basketball-tennis-6"
                points="222.53 44.77 264.62 36.14 265.37 36.66 223.28 45.3 222.53 44.77"
              />
              <path
                className="cls-basketball-tennis-6"
                d="M240.57,25.23l.85.38c-12.55,1.83-50.72,9.65-21.93,28.66C239.91,67.75,297,71.39,320,61.61l.81.51c-22.06,10.24-81,6.75-102.14-7.41C190.49,35.88,226,27.13,240.57,25.23Z"
              />
              <polygon
                className="cls-basketball-tennis-6"
                points="284.75 62.12 85.03 112.94 84.58 111.96 283.37 61.37 284.75 62.12"
              />
              <polygon
                className="cls-basketball-tennis-6"
                points="212.92 54.28 127.06 72.44 126.61 71.46 211.54 53.53 212.92 54.28"
              />
              <polygon
                className="cls-basketball-tennis-6"
                points="72.63 85.2 70.73 85.61 70.38 84.82 72.21 84.4 72.63 85.2"
              />
              <polygon
                className="cls-basketball-tennis-6"
                points="250.25 46.19 247.61 46.78 246.58 46.31 249.13 45.75 250.25 46.19"
              />
              <polygon
                className="cls-basketball-tennis-6"
                points="223.39 34.09 60.03 64.3 59.61 63.47 222.24 33.4 223.39 34.09"
              />
              <path
                className="cls-basketball-tennis-6"
                d="M87.6,121.71,57.07,59.42,218.25,31.11,295.7,66.63ZM58.36,59.92l30.16,60.13L293.31,66.52,218.09,32.1Z"
              />
              <polygon
                className="cls-basketball-tennis-6"
                points="146.8 96.79 111.82 54.24 112.87 53.96 147.93 96.49 146.8 96.79"
              />
              <polygon
                className="cls-basketball-tennis-6"
                points="242.16 72.7 188.25 40.12 189.72 39.81 243.24 72.39 242.16 72.7"
              />
            </g>
            {tennisNet && (
              <g id="Teniska_mreza" data-name="Teniska mreza">
                <path
                  className="cls-basketball-tennis-7"
                  d="M149.35,43.72a.42.42,0,0,1-.46.38.39.39,0,1,1,0-.76A.42.42,0,0,1,149.35,43.72Z"
                />
                <rect
                  className="cls-basketball-tennis-7"
                  x="148.43"
                  y="29.09"
                  width="0.92"
                  height="14.61"
                />
                <path
                  className="cls-basketball-tennis-8"
                  d="M149.35,29.09a.42.42,0,0,1-.46.38.39.39,0,1,1,0-.76A.42.42,0,0,1,149.35,29.09Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M207,75.51l-.08,0L149.1,29.6a.24.24,0,0,1,0-.26.13.13,0,0,1,.2-.07l57.82,45.88a.26.26,0,0,1,.05.27A.15.15,0,0,1,207,75.51Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M207,76.58a.11.11,0,0,1-.07,0L149.1,30.66a.26.26,0,0,1,0-.27.12.12,0,0,1,.2-.06L207,76.22a.22.22,0,0,1,.05.26A.14.14,0,0,1,207,76.58Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M207,77.73a.11.11,0,0,1-.07,0L149.1,31.79a.24.24,0,0,1,0-.26c0-.1.13-.13.2-.07l57.82,45.92a.24.24,0,0,1,.05.26A.15.15,0,0,1,207,77.73Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M207,78.79l-.07,0L149.1,32.84a.24.24,0,0,1,0-.26.13.13,0,0,1,.2-.07l57.82,45.92a.24.24,0,0,1,.05.26A.16.16,0,0,1,207,78.79Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M206.68,79.67a.13.13,0,0,1-.08,0L149.1,34a.24.24,0,0,1,0-.26.13.13,0,0,1,.2-.07l57.5,45.67a.24.24,0,0,1,.05.26A.16.16,0,0,1,206.68,79.67Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M206.68,84a.13.13,0,0,1-.08,0L149.1,38.35a.26.26,0,0,1,0-.27.13.13,0,0,1,.2-.07l57.5,45.67a.24.24,0,0,1,.05.26A.16.16,0,0,1,206.68,84Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M206.83,85.21l-.07,0L149.1,39.4a.24.24,0,0,1,0-.26c0-.1.13-.13.2-.07L206.9,84.85a.24.24,0,0,1,0,.27A.14.14,0,0,1,206.83,85.21Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M206.83,86.34a.08.08,0,0,1-.07,0L149.1,40.53a.24.24,0,0,1,0-.26c0-.09.13-.13.2-.07L206.9,86a.22.22,0,0,1,0,.26A.14.14,0,0,1,206.83,86.34Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M206.83,87.4a.09.09,0,0,1-.07,0L149.1,41.58a.24.24,0,0,1,0-.26.13.13,0,0,1,.2-.07L206.9,87a.22.22,0,0,1,0,.26C206.92,87.36,206.88,87.4,206.83,87.4Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M207,88.66l-.08,0L149.1,42.71a.24.24,0,0,1,0-.26.13.13,0,0,1,.2-.07L207.07,88.3a.26.26,0,0,1,.05.27A.15.15,0,0,1,207,88.66Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M207,81a.11.11,0,0,1-.07,0L149.1,35a.24.24,0,0,1,0-.26c0-.1.13-.13.2-.07l57.82,45.92a.24.24,0,0,1,.05.26A.15.15,0,0,1,207,81Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M207,82.11a.13.13,0,0,1-.08,0L149.1,36.16a.24.24,0,0,1,0-.26c0-.1.13-.13.2-.07l57.82,45.92a.24.24,0,0,1,.05.26A.16.16,0,0,1,207,82.11Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M206.83,83a.09.09,0,0,1-.07,0L149.1,37.21a.24.24,0,0,1,0-.26.13.13,0,0,1,.2-.07L206.9,82.67a.22.22,0,0,1,0,.26C206.92,83,206.88,83,206.83,83Z"
                />
                <path
                  className="cls-basketball-tennis-7"
                  d="M207.33,89.62a.42.42,0,0,1-.46.38.39.39,0,1,1,0-.76A.42.42,0,0,1,207.33,89.62Z"
                />
                <rect
                  className="cls-basketball-tennis-7"
                  x="206.41"
                  y="74.99"
                  width="0.92"
                  height="14.61"
                />
                <path
                  className="cls-basketball-tennis-8"
                  d="M207.33,75a.42.42,0,0,1-.46.38.39.39,0,1,1,0-.76A.42.42,0,0,1,207.33,75Z"
                />
                <polygon
                  className="cls-basketball-tennis-15"
                  points="172.29 61.09 172.88 61.56 172.88 48.03 172.29 47.59 172.29 61.09"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M150.22,43.48c-.08,0-.14-.08-.14-.19v-13c0-.11.06-.19.14-.19s.14.08.14.19v13C150.36,43.4,150.3,43.48,150.22,43.48Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M151.46,44.51c-.08,0-.14-.09-.14-.19v-13c0-.1.06-.19.14-.19s.14.09.14.19v13C151.6,44.42,151.54,44.51,151.46,44.51Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M152.69,45.53c-.08,0-.14-.08-.14-.19v-13c0-.11.06-.19.14-.19s.14.08.14.19v13C152.83,45.45,152.77,45.53,152.69,45.53Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M153.93,46.37c-.08,0-.15-.09-.15-.2V33.41c0-.11.07-.2.15-.2s.14.09.14.2V46.17C154.07,46.28,154,46.37,153.93,46.37Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M155.16,47.39c-.08,0-.14-.09-.14-.19v-13c0-.1.06-.19.14-.19s.14.09.14.19v13C155.3,47.3,155.24,47.39,155.16,47.39Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M156.4,48.33c-.08,0-.15-.09-.15-.2v-13a.18.18,0,0,1,.15-.19c.07,0,.14.09.14.19v13C156.54,48.24,156.47,48.33,156.4,48.33Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M157.63,49.35c-.08,0-.14-.09-.14-.19v-13c0-.1.06-.19.14-.19s.14.09.14.19v13C157.77,49.26,157.71,49.35,157.63,49.35Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M158.86,50.43a.17.17,0,0,1-.14-.19v-13c0-.1.07-.19.14-.19a.18.18,0,0,1,.15.19v13A.17.17,0,0,1,158.86,50.43Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M160.1,51.41c-.08,0-.14-.08-.14-.19v-13c0-.11.06-.19.14-.19s.14.08.14.19v13C160.24,51.33,160.18,51.41,160.1,51.41Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M161.33,52.39c-.08,0-.14-.08-.14-.19V39.26c0-.1.06-.19.14-.19s.14.09.14.19V52.2C161.47,52.31,161.41,52.39,161.33,52.39Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M162.57,53.37c-.08,0-.14-.08-.14-.19V40.29c0-.11.06-.19.14-.19s.14.08.14.19V53.18C162.71,53.29,162.65,53.37,162.57,53.37Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M163.8,54.34c-.08,0-.14-.09-.14-.19V41c0-.11.06-.19.14-.19s.14.08.14.19V54.15C163.94,54.25,163.88,54.34,163.8,54.34Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M165,55.36c-.08,0-.14-.08-.14-.19v-13c0-.11.06-.2.14-.2s.14.09.14.2v13A.17.17,0,0,1,165,55.36Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M166.27,56.3c-.08,0-.14-.09-.14-.2v-13c0-.11.06-.2.14-.2s.14.09.14.2v13C166.41,56.21,166.35,56.3,166.27,56.3Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M167.51,57.16a.17.17,0,0,1-.15-.19V44.12c0-.11.07-.2.15-.2s.14.09.14.2V57A.17.17,0,0,1,167.51,57.16Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M168.74,58.28c-.08,0-.14-.09-.14-.2V45.19c0-.1.06-.19.14-.19s.14.09.14.19V58.08C168.88,58.19,168.82,58.28,168.74,58.28Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M170,59.24c-.07,0-.14-.09-.14-.19V46.15a.15.15,0,1,1,.29,0v12.9A.18.18,0,0,1,170,59.24Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M171.12,60a.18.18,0,0,1-.15-.19V46.91a.15.15,0,1,1,.29,0v12.9C171.26,59.91,171.19,60,171.12,60Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M175.18,63.22c-.08,0-.14-.08-.14-.19v-13c0-.11.06-.2.14-.2s.14.09.14.2V63C175.32,63.14,175.26,63.22,175.18,63.22Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M174,62.41c-.08,0-.14-.09-.14-.2v-13c0-.11.06-.19.14-.19s.14.08.14.19v13C174.14,62.32,174.08,62.41,174,62.41Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M176.42,64.25a.18.18,0,0,1-.15-.19v-13c0-.11.07-.2.15-.2s.14.09.14.2v13C176.56,64.16,176.49,64.25,176.42,64.25Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M177.65,65.27c-.08,0-.14-.08-.14-.19v-13c0-.11.06-.2.14-.2s.14.09.14.2v13C177.79,65.19,177.73,65.27,177.65,65.27Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M178.88,66.1a.17.17,0,0,1-.14-.19V53.14c0-.1.07-.19.14-.19a.18.18,0,0,1,.15.19V65.91A.17.17,0,0,1,178.88,66.1Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M180.12,67.13c-.08,0-.14-.09-.14-.19V54c0-.1.06-.19.14-.19s.14.09.14.19v13C180.26,67,180.2,67.13,180.12,67.13Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M181.35,68.06a.17.17,0,0,1-.14-.19V55c0-.1.07-.19.14-.19s.14.09.14.19V67.87C181.49,68,181.43,68.06,181.35,68.06Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M182.59,69.09c-.08,0-.14-.09-.14-.19v-13c0-.1.06-.19.14-.19s.14.09.14.19v13C182.73,69,182.67,69.09,182.59,69.09Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M183.82,70.17c-.08,0-.14-.08-.14-.19V57c0-.11.06-.19.14-.19s.14.08.14.19V70C184,70.09,183.9,70.17,183.82,70.17Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M185.06,71.15c-.08,0-.14-.08-.14-.19v-13c0-.11.06-.19.14-.19a.17.17,0,0,1,.14.19V71A.17.17,0,0,1,185.06,71.15Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M186.29,72.13c-.08,0-.14-.08-.14-.19V59c0-.1.06-.19.14-.19s.14.09.14.19V71.94C186.43,72.05,186.37,72.13,186.29,72.13Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M187.53,73.11a.17.17,0,0,1-.15-.19V60a.15.15,0,1,1,.29,0V72.92A.17.17,0,0,1,187.53,73.11Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M188.76,74.08c-.08,0-.14-.09-.14-.2v-13c0-.11.06-.19.14-.19s.14.08.14.19v13C188.9,74,188.84,74.08,188.76,74.08Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M190,75.1c-.07,0-.14-.09-.14-.19v-13c0-.1.07-.19.14-.19a.18.18,0,0,1,.15.19v13A.18.18,0,0,1,190,75.1Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M191.23,76c-.08,0-.14-.08-.14-.19v-13c0-.1.06-.19.14-.19s.14.09.14.19v13C191.37,76,191.31,76,191.23,76Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M192.46,76.9a.17.17,0,0,1-.14-.19V63.85c0-.1.07-.19.14-.19a.18.18,0,0,1,.15.19V76.71A.17.17,0,0,1,192.46,76.9Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M193.7,78c-.08,0-.14-.08-.14-.19V64.93c0-.1.06-.19.14-.19s.14.09.14.19V77.82C193.84,77.93,193.78,78,193.7,78Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M194.93,79c-.08,0-.14-.09-.14-.19V65.89c0-.11.06-.19.14-.19s.14.08.14.19v12.9C195.07,78.89,195,79,194.93,79Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M196.17,79.91c-.08,0-.14-.09-.14-.2V66.82c0-.11.06-.2.14-.2s.14.09.14.2V79.71C196.31,79.82,196.25,79.91,196.17,79.91Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M197.4,81c-.08,0-.14-.09-.14-.19V67.91c0-.11.06-.19.14-.19s.14.08.14.19V80.8C197.54,80.9,197.48,81,197.4,81Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M198.64,82c-.08,0-.15-.09-.15-.2V68.86a.18.18,0,0,1,.15-.19c.07,0,.14.09.14.19v12.9C198.78,81.87,198.71,82,198.64,82Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M199.87,82.88c-.08,0-.14-.08-.14-.19V69.79c0-.1.06-.19.14-.19s.14.09.14.19v12.9C200,82.8,200,82.88,199.87,82.88Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M201.11,83.82a.18.18,0,0,1-.15-.19V70.73a.15.15,0,1,1,.29,0v12.9C201.25,83.73,201.18,83.82,201.11,83.82Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M202.34,84.9c-.08,0-.14-.08-.14-.19V71.82c0-.1.06-.19.14-.19s.14.09.14.19V84.71C202.48,84.82,202.42,84.9,202.34,84.9Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M203.57,85.87c-.07,0-.14-.09-.14-.19V72.78a.15.15,0,1,1,.29,0v12.9A.18.18,0,0,1,203.57,85.87Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M204.81,86.8c-.08,0-.14-.09-.14-.2V73.7c0-.1.06-.19.14-.19s.14.09.14.19V86.6C205,86.71,204.89,86.8,204.81,86.8Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M206,87.8c-.07,0-.14-.09-.14-.2V74.7c0-.1.07-.19.14-.19s.14.09.14.19V87.6C206.18,87.71,206.12,87.8,206,87.8Z"
                />
              </g>
            )}
            {baskets && (
              <g id="Kosevi">
                <path
                  className="cls-basketball-tennis-7"
                  d="M277.18,38.31h1.33V9.23s-5.59-2.4-6.57-2.74V8c1.5.58,3.42,1.46,5.24,2.25Z"
                />
                <path
                  className="cls-basketball-tennis-7"
                  d="M278.51,38.38c0,.22-.3.4-.66.4s-.67-.18-.67-.4.3-.4.67-.4S278.51,38.16,278.51,38.38Z"
                />
                <polygon
                  className="cls-basketball-tennis-10"
                  points="267.26 10.65 277.63 16.63 277.63 6.04 267.26 0.8 267.26 10.65"
                />
                <polygon
                  className="cls-basketball-tennis-11"
                  points="266.62 10.95 276.99 16.93 276.99 6.34 266.62 0.37 266.62 10.95"
                />
                <polygon
                  className="cls-basketball-tennis-10"
                  points="276.99 16.93 277.63 16.63 277.63 5.97 276.99 6.34 276.99 16.93"
                />
                <polygon
                  className="cls-basketball-tennis-12"
                  points="266.62 0.37 267.26 0 277.63 5.97 276.99 6.34 266.62 0.37"
                />
                <path
                  className="cls-basketball-tennis-15"
                  d="M274.5,13l-5.39-3.1V4.33l5.39,3.11Zm-5.19-3.22,5,2.87v-5l-5-2.87Z"
                />
                <path
                  className="cls-basketball-tennis-15"
                  d="M276.53,15.89l-9.37-5.4V1.41l9.37,5.4Zm-9.17-5.52,9,5.16V6.93l-9-5.16Z"
                />
                <rect
                  className="cls-basketball-tennis-9"
                  x="269.28"
                  y="10.47"
                  width="0.34"
                  height="3.66"
                />
                <rect
                  className="cls-basketball-tennis-9"
                  x="267.66"
                  y="10.8"
                  width="0.34"
                  height="3.71"
                  transform="matrix(0.99, -0.14, 0.14, 0.99, 0.98, 38.87)"
                />
                <rect
                  className="cls-basketball-tennis-9"
                  x="269.21"
                  y="12.61"
                  width="3.72"
                  height="0.34"
                  transform="translate(219.22 279.15) rotate(-81.68)"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M269.45,17.54c-1.48,0-2.63-.75-2.63-1.7s1.15-1.71,2.63-1.71,2.63.75,2.63,1.71S270.92,17.54,269.45,17.54Zm0-3.07c-1.29,0-2.29.6-2.29,1.37s1,1.36,2.29,1.36,2.29-.6,2.29-1.36S270.73,14.47,269.45,14.47Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M269.44,16.38c-1.61,0-2.87-.81-2.87-1.86s1.26-1.86,2.87-1.86,2.87.82,2.87,1.86S271.05,16.38,269.44,16.38Zm0-3.34c-1.4,0-2.49.65-2.49,1.48S268,16,269.44,16s2.5-.65,2.5-1.49S270.84,13,269.44,13Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M269.44,15.27c-1.7,0-3-.86-3-2s1.34-2,3-2,3,.86,3,2S271.15,15.27,269.44,15.27Zm0-3.54c-1.48,0-2.64.69-2.64,1.57s1.16,1.57,2.64,1.57,2.64-.69,2.64-1.57S270.92,11.73,269.44,11.73Z"
                />
                <rect
                  className="cls-basketball-tennis-9"
                  x="266.51"
                  y="12.51"
                  width="0.34"
                  height="3.35"
                  transform="translate(1.88 48.86) rotate(-10.51)"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M272.08,15.87l-.34-.06c.25-1.33.62-3.34.63-3.47h.35C272.72,12.44,272.37,14.32,272.08,15.87Z"
                />
                <polygon
                  className="cls-basketball-tennis-9"
                  points="267.95 17.1 267.72 13.98 268.06 13.96 268.29 17.08 267.95 17.1"
                />
                <rect
                  className="cls-basketball-tennis-9"
                  x="269.39"
                  y="15.27"
                  width="3.13"
                  height="0.34"
                  transform="translate(235.92 284.56) rotate(-85.84)"
                />
                <rect
                  className="cls-basketball-tennis-9"
                  x="269.28"
                  y="14.3"
                  width="0.34"
                  height="3.07"
                />
                <path
                  className="cls-basketball-tennis-13"
                  d="M269.45,14.41c-1.85,0-3.3-.9-3.3-2.06s1.45-2.06,3.3-2.06,3.3.91,3.3,2.06S271.3,14.41,269.45,14.41Zm0-3.71c-1.57,0-2.9.75-2.9,1.65s1.33,1.66,2.9,1.66,2.9-.76,2.9-1.66S271,10.7,269.45,10.7Z"
                />
                <rect
                  className="cls-basketball-tennis-9"
                  x="33.98"
                  y="47.39"
                  width="0.45"
                  height="4.76"
                />
                <rect
                  className="cls-basketball-tennis-9"
                  x="31.88"
                  y="47.83"
                  width="0.45"
                  height="4.83"
                  transform="translate(-6.93 5.17) rotate(-8.32)"
                />
                <rect
                  className="cls-basketball-tennis-9"
                  x="33.9"
                  y="50.17"
                  width="4.83"
                  height="0.45"
                  transform="translate(-18.81 79.04) rotate(-81.68)"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M34.2,56.59c-1.91,0-3.42-1-3.42-2.22s1.51-2.22,3.42-2.22,3.42,1,3.42,2.22S36.12,56.59,34.2,56.59Zm0-4c-1.66,0-3,.77-3,1.77s1.31,1.77,3,1.77,3-.78,3-1.77S35.87,52.6,34.2,52.6Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M34.19,55.08c-2.09,0-3.72-1.06-3.72-2.42s1.63-2.42,3.72-2.42,3.73,1.06,3.73,2.42S36.28,55.08,34.19,55.08Zm0-4.35c-1.81,0-3.24.85-3.24,1.93s1.43,1.93,3.24,1.93,3.24-.85,3.24-1.93S36,50.73,34.19,50.73Z"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M34.19,53.63c-2.21,0-3.94-1.12-3.94-2.56s1.73-2.56,3.94-2.56,4,1.13,4,2.56S36.41,53.63,34.19,53.63Zm0-4.6c-1.92,0-3.43.89-3.43,2s1.51,2,3.43,2,3.44-.9,3.44-2S36.12,49,34.19,49Z"
                />
                <rect
                  className="cls-basketball-tennis-9"
                  x="30.39"
                  y="50.05"
                  width="0.45"
                  height="4.35"
                  transform="translate(-9.01 6.46) rotate(-10.51)"
                />
                <path
                  className="cls-basketball-tennis-9"
                  d="M37.62,54.41l-.44-.08c.32-1.72.81-4.33.82-4.5h.45C38.45,50,38,52.39,37.62,54.41Z"
                />
                <polygon
                  className="cls-basketball-tennis-9"
                  points="32.25 56.01 31.96 51.95 32.41 51.92 32.7 55.98 32.25 56.01"
                />
                <rect
                  className="cls-basketball-tennis-9"
                  x="34.13"
                  y="53.63"
                  width="4.07"
                  height="0.45"
                  transform="translate(-20.17 86.01) rotate(-85.84)"
                />
                <rect
                  className="cls-basketball-tennis-9"
                  x="33.98"
                  y="52.37"
                  width="0.45"
                  height="3.99"
                />
                <path
                  className="cls-basketball-tennis-13"
                  d="M34.2,52.51c-2.4,0-4.28-1.17-4.28-2.67s1.88-2.67,4.28-2.67,4.29,1.17,4.29,2.67S36.61,52.51,34.2,52.51Zm0-4.82c-2,0-3.76,1-3.76,2.15S32.16,52,34.2,52,38,51,38,49.84,36.24,47.69,34.2,47.69Z"
                />
                <polygon
                  className="cls-basketball-tennis-10"
                  points="25.89 49.9 32.56 61.97 32.56 48.22 25.89 37.1 25.89 49.9"
                />
                <polygon
                  className="cls-basketball-tennis-11"
                  points="25.89 50.85 31.73 62.36 31.73 48.61 25.89 37.1 25.89 50.85"
                />
                <polygon
                  className="cls-basketball-tennis-10"
                  points="31.73 62.36 32.56 61.97 32.56 48.13 31.73 48.61 31.73 62.36"
                />
                <polygon
                  className="cls-basketball-tennis-12"
                  points="25.89 37.1 26.72 36.62 32.56 48.13 31.73 48.61 25.89 37.1"
                />
                <polygon
                  className="cls-basketball-tennis-7"
                  points="27.96 48.81 23.01 54.17 23.01 96.03 24.78 96.03 24.78 54.98 29.09 50.46 27.96 48.81"
                />
                <path
                  className="cls-basketball-tennis-7"
                  d="M27.85,49.21a1.65,1.65,0,0,0,.69,1.31c.38.22.69,0,.69-.52a1.65,1.65,0,0,0-.69-1.31C28.16,48.47,27.85,48.7,27.85,49.21Z"
                />
                <path
                  className="cls-basketball-tennis-7"
                  d="M24.74,96c0,.29-.38.52-.86.52S23,96.32,23,96s.39-.52.87-.52S24.74,95.74,24.74,96Z"
                />
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BasketballAndTennis;
